import { questionCircle, saveAs } from "../components/icons";
import { ProjectName } from "../components/ProjectName";
import { ToolButton } from "../components/ToolButton";
import { Tooltip } from "../components/Tooltip";
import { DarkModeToggle } from "../components/DarkModeToggle";
import { loadFromJSON, saveAsJSON } from "src/excalidraw/data/";
import { resaveAsImageWithScene } from "src/excalidraw/data/resave";
import { t } from "src/excalidraw/i18n";
import { useDevice } from "../components/App";
import { KEYS } from "src/excalidraw/keys";
import { register } from "./register";
import { CheckboxItem } from "../components/CheckboxItem";
import { getExportSize } from "src/excalidraw/scene/export";
import { DEFAULT_EXPORT_PADDING, EXPORT_SCALES, GRID_SIZE, THEME } from "src/excalidraw/constants";
import { getSelectedElements, isSomeElementSelected } from "src/excalidraw/scene";
import { duplicateElement, getNonDeletedElements } from "../element";
import { isImageFileHandle } from "src/excalidraw/data/blob";
import { fileSave, nativeFileSystemSupported } from "src/excalidraw/data/filesystem";
import { Theme } from "../element/types";
// CHANGED:ADD 2022-12-12 #214
import CriticalPath from "src/excalidraw/extensions/criticalPath"; // from extensions
import Job from "src/excalidraw/extensions/job"; // from extensions
import Milestone from "src/excalidraw/extensions/milestone"; // from extensions

import "../components/ToolIcon.scss";
import { generateBackgroundElements } from "src/excalidraw/extensions/app/data/background";
import { resetPriority } from "src/excalidraw/extensions/element/priority";
import { mutateElement } from "../element/mutateElement";
import { isJobTextElement, isTaskElement } from "../extensions/element/typeChecks";
import ColorsEx from "../extensions/constants/ColorsEx";
import { MIME_TYPES, serializeAsJSON } from "../packages/utils";
import { bindTextToShapeAfterDuplication, getContainerElement } from "../element/textElement";
import { fixBindingsAfterDuplication } from "../element/binding";
import { fixBindingsAfterDuplicationEx } from "../extensions/element/binding";
import { ExcalidrawAssignResources, ExcalidrawAssignUsers, ExcalidrawChecklist, ExcalidrawTags } from "../types";
import { getProjectTaskChildren, savePasteLibraryElements } from "../extensions/library";
import { arrayToMap } from "../utils";

export const actionChangeProjectName = register({
  name: "changeProjectName",
  trackEvent: false,
  perform: (_elements, appState, value) => {
    return { appState: { ...appState, name: value }, commitToHistory: false };
  },
  PanelComponent: ({ appState, updateData, appProps }) => (
    <ProjectName
      label={t("labels.fileTitle")}
      value={appState.name || "Unnamed"}
      onChange={(name: string) => updateData(name)}
      isNameEditable={
        typeof appProps.name === "undefined" && !appState.viewModeEnabled
      }
    />
  ),
});

export const actionChangeExportScale = register({
  name: "changeExportScale",
  trackEvent: { category: "export", action: "scale" },
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportScale: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ elements: allElements, appState, updateData }) => {
    const elements = getNonDeletedElements(allElements);
    const exportSelected = isSomeElementSelected(elements, appState);
    const exportedElements = exportSelected
      ? getSelectedElements(elements, appState)
      : elements;

    return (
      <>
        {EXPORT_SCALES.map((s) => {
          const [width, height] = getExportSize(
            exportedElements,
            DEFAULT_EXPORT_PADDING,
            s,
          );

          const scaleButtonTitle = `${t(
            "buttons.scale",
          )} ${s}x (${width}x${height})`;

          return (
            <ToolButton
              key={s}
              size="small"
              type="radio"
              icon={`${s}x`}
              name="export-canvas-scale"
              title={scaleButtonTitle}
              aria-label={scaleButtonTitle}
              id="export-canvas-scale"
              checked={s === appState.exportScale}
              onChange={() => updateData(s)}
            />
          );
        })}
      </>
    );
  },
});

export const actionChangeExportBackground = register({
  name: "changeExportBackground",
  trackEvent: { category: "export", action: "toggleBackground" },
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportBackground: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <CheckboxItem
      checked={appState.exportBackground}
      onChange={(checked) => updateData(checked)}
    >
      {t("labels.withBackground")}
    </CheckboxItem>
  ),
});

export const actionChangeExportEmbedScene = register({
  name: "changeExportEmbedScene",
  trackEvent: { category: "export", action: "embedScene" },
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportEmbedScene: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <CheckboxItem
      checked={appState.exportEmbedScene}
      onChange={(checked) => updateData(checked)}
    >
      {t("labels.exportEmbedScene")}
      <Tooltip label={t("labels.exportEmbedScene_details")} long={true}>
        <div className="conpath-tooltip-icon">{questionCircle}</div>
      </Tooltip>
    </CheckboxItem>
  ),
});

export const actionSaveToActiveFile = register({
  name: "saveToActiveFile",
  trackEvent: { category: "export" },
  predicate: (elements, appState, props, app) => {
    return (
      !!app.props.UIOptions.canvasActions.saveToActiveFile &&
      !!appState.fileHandle &&
      !appState.viewModeEnabled
    );
  },
  perform: async (elements, appState, value, app) => {
    const fileHandleExists = !!appState.fileHandle;

    try {
      const { fileHandle } = isImageFileHandle(appState.fileHandle)
        ? await resaveAsImageWithScene(elements, appState, app.files)
        : await saveAsJSON(elements, appState, app.files);

      return {
        commitToHistory: false,
        appState: {
          ...appState,
          fileHandle,
          toast: fileHandleExists
            ? {
                message: fileHandle?.name
                  ? t("toast.fileSavedToFilename").replace(
                      "{filename}",
                      `"${fileHandle.name}"`,
                    )
                  : t("toast.fileSaved"),
              }
            : null,
        },
      };
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event[KEYS.CTRL_OR_CMD] && !event.shiftKey,
});

export const actionSaveFileToDisk = register({
  name: "saveFileToDisk",
  viewMode: true,
  trackEvent: { category: "export" },
  perform: async (elements, appState, value, app) => {
    // CHANGED:UPDATE 2024-02-21 #1691
    // try {
    //   const { fileHandle } = await saveAsJSON(
    //     elements,
    //     {
    //       ...appState,
    //       fileHandle: null,
    //     },
    //     app.files,
    //   );
    //   return { commitToHistory: false, appState: { ...appState, fileHandle } };
    // } catch (error: any) {
    //   if (error?.name !== "AbortError") {
    //     console.error(error);
    //   } else {
    //     console.warn(error);
    //   }
    //   return { commitToHistory: false };
    // }
    try {
      const blob = getProjectTaskChildren(appState, true, true)
        .then((result) => {
          const serialized = serializeAsJSON(
            elements,
            appState,
            app.files,
            "local",
            result,
          );

          return new Blob([serialized], {
            type: MIME_TYPES.conpath,
          });
        });

      const fileHandle = await fileSave(blob, {
        name: appState.name,
        extension: "conpath",
        description: "ConPath file",
        fileHandle: null,
      });

      return {
        commitToHistory: false,
        appState: {
          ...appState,
          openDialog: null,
          // fileHandle,
          toast: { message: t("toast.fileSaved") },
        },
      };
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      icon={saveAs}
      title={t("buttons.saveAs")}
      aria-label={t("buttons.saveAs")}
      showAriaLabel={useDevice().isMobile}
      hidden={!nativeFileSystemSupported}
      onClick={() => updateData(null)}
      data-testid="save-as-button"
    />
  ),
});

export const actionLoadScene = register({
  name: "loadScene",
  trackEvent: { category: "export" },
  predicate: (elements, appState, props, app) => {
    return (
      !!app.props.UIOptions.canvasActions.loadScene && !appState.viewModeEnabled
    );
  },
  perform: async (elements, appState, _, app) => {
    try {
      const {
        elements: loadedElements,
        appState: loadedAppState,
        files,
        taskChildren, // CHANGED:ADD 2024-02-13 #1614
      } = await loadFromJSON(appState, elements);
      // CHANGED:ADD 2023-1-18 #427
      const projectStartDate = new Date(loadedAppState.projectStartDate);
      const projectEndDate = new Date(loadedAppState.projectEndDate);

      // CHANGED:ADD 2024-02-13 #1614
      const groupIdMap = new Map();
      const oldIdToDuplicatedId = new Map();
      elements.forEach((element) => {
        mutateElement(element, { isDeleted: true })
      });
      const newElements = loadedElements.map((element) => {
        const newElement = duplicateElement(
          "",
          groupIdMap,
          element,
        );
        oldIdToDuplicatedId.set(element.id, newElement.id);
        return newElement;
      });
      bindTextToShapeAfterDuplication(newElements, loadedElements, oldIdToDuplicatedId);
      const nextElements = [
        ...elements,
        ...newElements,
      ];
      fixBindingsAfterDuplication(nextElements, loadedElements, oldIdToDuplicatedId);
      fixBindingsAfterDuplicationEx(nextElements, loadedElements, oldIdToDuplicatedId);

      // CHANGED:ADD 2023-2-14 #704
      resetPriority(nextElements);

      // CHANGED: ADD 2023-01-21 #391
      const jobElements = Job.getJobElements(nextElements);

      // CHANGED:ADD 2023/09/05 #1009
      if (jobElements.find((el) => el.y === 96)) {
        nextElements.forEach((el) => mutateElement(el, { y: el.y + 32 }));
      }

      // CHANGED: ADD 2023-2-11 #671
      const milestoneElements = Milestone.getMilestoneElements(nextElements);

      const {
        jobBackgroundElements,
        jobPanelElements,
        jobLineElements,
        jobsHeight,
        milestoneLineElements,
        calendarWidth,
      } = generateBackgroundElements(
        projectStartDate,
        projectEndDate,
        jobElements,
        milestoneElements,
        loadedAppState.viewBackgroundColor,
        loadedAppState.gridSize ? loadedAppState.gridSize : GRID_SIZE,
        loadedAppState.holidays,
      );

      // CHANGED:ADD 2024-02-13 #1614
      const newAssignUsers: ExcalidrawAssignUsers[] =
        taskChildren?.assignUsers?.map((assignUser) => ({
          ...assignUser,
          taskId: oldIdToDuplicatedId.get(assignUser.taskId)
        })) || [];

      const newAssignResources: ExcalidrawAssignResources[] =
        taskChildren?.assignResources?.map((assignResource) => ({
          ...assignResource,
          taskId: oldIdToDuplicatedId.get(assignResource.taskId)
        })) || [];

      const newTags: ExcalidrawTags[] =
        taskChildren?.tags?.map((tag) => ({
          ...tag,
          taskId: oldIdToDuplicatedId.get(tag.taskId)
        })) || [];

      const newChecklists: ExcalidrawChecklist[] =
        taskChildren?.checklists?.map((checklist) => ({
          ...checklist,
          taskId: oldIdToDuplicatedId.get(checklist.taskId)
        })) || [];

      await savePasteLibraryElements(
        nextElements,
        arrayToMap(nextElements),
        appState,
        {
          assignUsers: newAssignUsers,
          assignResources: newAssignResources,
          tags: newTags,
          checklists: newChecklists,
        },
      );

      return {
        elements: CriticalPath.calcCriticalPath(
          nextElements,
          app.scene.getNonDeletedElementsMap(),
          appState,
        ), // CHANGED:ADD 2023-1-23 #455
        jobBackgroundElements, // CHANGED:ADD 2023-2-10 #634
        jobPanelElements, // CHANGED:ADD 2023-1-18 #427
        jobLineElements, // CHANGED:ADD 2023-03-01 #726
        milestoneLineElements, // CHANGED:ADD 2023-2-11 #671
        appState: {
          ...loadedAppState,
          projectStartDate,
          projectEndDate,
          calendarWidth, // CHANGED:ADD 2023-1-18 #427
          jobsHeight,
        },
        files,
        commitToHistory: false,
        resetHistory: true,
      };
    } catch (error: any) {
      if (error?.name === "AbortError") {
        console.warn(error);
        return false;
      }
      return {
        elements,
        appState: { ...appState, errorMessage: error.message },
        files: app.files,
        commitToHistory: false,
      };
    }
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
});

export const actionExportWithDarkMode = register({
  name: "exportWithDarkMode",
  trackEvent: { category: "export", action: "toggleTheme" },
  perform: (_elements, appState, value) => {
    return {
      appState: { ...appState, exportWithDarkMode: value },
      commitToHistory: false,
    };
  },
  PanelComponent: ({ appState, updateData }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "-45px",
        marginBottom: "10px",
      }}
    >
      <DarkModeToggle
        value={appState.exportWithDarkMode ? THEME.DARK : THEME.LIGHT}
        onChange={(theme: Theme) => {
          updateData(theme === THEME.DARK);
        }}
        title={t("labels.toggleExportColorScheme")}
      />
    </div>
  ),
});

export const actionPrintImage = register({
  name: "printImage",
  trackEvent: { category: "export", action: "printImage" },
  perform: async (_elements, appState, value, { files, scene }) => {
    // PrintExportDialog.tsxに移行

    // const handlePrint = (canvas: HTMLCanvasElement) => {
    //   const [, , width, height] = getCanvasSize(_elements, 10);
    //   const dataUrl = canvas.toDataURL();

    //   const windowContent = `<!DOCTYPE html>
    //     <html>
    //       <head>
    //         <title>${appState.projectName} - ${APP_NAME}</title>
    //       </head>
    //       <body>
    //         <img style="width:100%" src="${dataUrl}">
    //       </body>
    //     </html>`;

    //   const printWin = window.open(location.href, "", `width=${width},height=${height}`);

    //   if (printWin) {
    //     printWin.document.open();
    //     printWin.document.write(windowContent);

    //     printWin.document.addEventListener(
    //       "load",
    //       () => {
    //         printWin.focus();
    //         printWin.print();
    //         printWin.document.close();
    //         printWin.close();
    //       },
    //       true,
    //     );
    //   }
    // };
    // const canvas = await exportToCanvas({
    //   elements: scene.getRenderingElements(),
    //   appState,
    //   files,
    //   exportPadding: 0,
    // });
    // handlePrint(canvas);

    return {
      appState,
      commitToHistory: false,
    };
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.P,
});
