import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import _ from "lodash";
//components
import SettingsLayout from "src/conpath/components/settings/SettingLayout";
import SearchBar from "src/conpath/components/SearchBar";
import { Button } from "src/conpath/components/Button";
import { Menu, Transition } from "@headlessui/react";
import { RotatingLines } from "react-loader-spinner";
import { Dialog } from "@headlessui/react";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { RxDragHandleDots2 } from "react-icons/rx";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "../../hooks/useStore";

//constants
import { Paths } from "../../constants/Routes";
import "./ResourcesSettings.scss";
import Colors from "src/conpath/constants/Colors";
import { Image, ResourceForm } from "src/conpath/interfaces/Resource";
import Checkbox from "src/conpath/components/CheckBox";
import Badge from "src/conpath/components/Badge";
import clsx from "clsx";

//models
import ResourceModel from "src/conpath/models/ResourceModel";
import LoginUserModel from "src/conpath/models/LoginUserModel";
import { isUploadedImage } from "src/conpath/models/OrganizationModel";

import {
  DndContext,
  closestCenter,
  DragEndEvent
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

//utils

//types & interfaces
const defaultResourceFile = "01resource.svg";

const ResourcesSettings: React.FC = observer(() => {
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [resource, setResource] = useState<ResourceForm>({
    iconImageFile: { url: "", file: null },
    id: "",
    index: -1,
    name: "",
    tags: {},
    memo: "",
    isActive: true,
    isDeleted: false,
    createdBy: "",
    createdAt: new Date(),
  });
  const [resources, setResources] = useState<ResourceModel[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  function closeEditModal() {
    setModalErrorMessage("");
    setIsEditModalOpen(false);
  }

  function createResource() {
    setIsCreating(true);
    setResource({
      iconImageFile: { url: `/resource-icon/${defaultResourceFile}`, file: null },
      id: "",
      index: Math.max(...resources.map((el) => (el.index))) + 1,
      name: "",
      tags: {},
      memo: "",
      isActive: true,
      isDeleted: false,
      createdBy: "",
      createdAt: new Date(),
    });
    setIsEditModalOpen(true);
  }

  function editResource(resource: ResourceForm) {
    setIsCreating(false);
    setResource(resource);
    setIsEditModalOpen(true);
  }

  function closeDeleteModal() {
    setModalErrorMessage("");
    setIsDeleteModalOpen(false);
  }

  function deleteResource(resource: ResourceForm) {
    setResource(resource);
    setIsDeleteModalOpen(true);
  }

  useEffect(() => {
    selectedOrganization?.subscribeResourcesCollection();

    return () => {
      selectedOrganization?.unsubscribeResourcesCollection();
    };
  }, []);

  useEffect(() => {
    setResources(selectedOrganization?.getNonDeletedResources() || []);
  }, [selectedOrganization?.resources]);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  const onChangeState = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      if (name) {
        setResource((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
      setErrorMessage("");
    },
    [setResource],
  );

  const onChangeTagsState = useCallback(
    (tags: string[]) => {
      setResource((prev) => ({
        ...prev,
        tags: tags.reduce(
          (tags, tag) => ({
            ...tags,
            [tag]: true,
          }),
          {},
        ),
      }));
    },
    [setResource],
  );

  const onUpdateResource = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;

    const result = resource.id
      ? await selectedOrganization.updateResource(resource)
      : await selectedOrganization.createResource(resource, loginUser);

    if (result.error) {
      setModalErrorMessage(result.error);
      return;
    }

    closeEditModal();
  }, [resource, setErrorMessage, selectedOrganization, loginUser]);

  const onDeleteResource = useCallback(async () => {
    if (!selectedOrganization || !loginUser) return;

    const _resource = selectedOrganization.resources.find(
      (v) => v.id === resource.id,
    );
    if (!_resource) return;

    const result = await _resource?.delete();

    if (result.error) {
      setModalErrorMessage(result.error);
      return;
    }

    // アイコン画像を削除
    selectedOrganization.deleteResourceIconImage(resource.iconImageFile.url);

    setSuccessMessage("リソースの削除が完了しました。");
    closeDeleteModal();
  }, [resource, setErrorMessage, selectedOrganization, loginUser]);

  const toggleIsActive = useCallback(
    async (resource: ResourceModel) => {
      if (!selectedOrganization || !loginUser) return;

      const result = await resource.updateIsActive(!resource.isActive);

      if (result.error) {
        setModalErrorMessage(result.error);
        return;
      }
    },
    [setErrorMessage, selectedOrganization, loginUser],
  );

  /**
   * リソースアイコンの登録
   */
  // プリセットのSVGから登録
  const onSetIconUrl = useCallback(
    (url: string) => {
      setResource((prev) => ({
        ...prev,
        iconImageFile: { ...prev.iconImageFile, url },
      }));
    },
    [setResource],
  );

  // pngデータをアップロード
  const onChangeImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;

      const file = event.target.files[0];
      const { size } = file;
      if (size > 12 * 1000000) return;

      const imageURL: string = URL.createObjectURL(file); //preview
      const image: Image = {
        url: imageURL,
        file,
      };
      setResource((prev) => ({
        ...prev,
        iconImageFile: image,
      }));
    },
    [setResource],
  );

  // リソースアイコンの登録
  const onChangeImageButtonPressed = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // リソースアイコンのリセット
  const onRemoveImageButtonPressed = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const iconImageFile: Image = {
      url: "",
      file: null,
    };
    setResource((prev) => ({
      ...prev,
      iconImageFile,
    }));
  };

  const handleDragEnd = async (e: DragEndEvent) => {
    const { active, over } = e;
    if (over == null || active.id === over.id) return;

    setResources((items) => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const updatedItems = arrayMove(items, oldIndex, newIndex);

      selectedOrganization?.updateResourceIndexes(updatedItems);

      return updatedItems;
    });
  };

  return (
    <>
      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle="リソース管理"
        currentPath={Paths.resources}
      >
        {(loginUser?.isOrganizationOwner() || loginUser?.isOrganizationMember()) && (
          <Button
            style={{ width: "140px", fontSize: "14px" }}
            theme="secondary"
            onClick={createResource}
          >
            + 新規追加
          </Button>
        )}
        <div className="setting-body">
          <div className="section resource-section">
            <div className="section-header">
              <h4>リソース一覧</h4>
            </div>
            <div className="section resource-list-section">
              {selectedOrganization && loginUser ? (
                <div className="table-wrapper">
                  <SearchBar
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onEnterKeyPressed={() => null}
                    placeholder="リソース検索"
                  />
                  <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={resources.map(resource => resource.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th className="th handle"> </th>
                            <th className="th icon"> </th>
                            <th className="th name">名称</th>
                            <th className="th tag">タグ</th>
                            <th className="th memo">メモ</th>
                            <th className="th isActive">アクティブ</th>
                            <th className="th edit"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {resources
                            .filter((v) => !_.isEmpty(searchText)
                              ? (
                                v.name.toLowerCase().includes(searchText.toLowerCase()) ||
                                Object.keys(v.tags).some((value => value.toLowerCase().includes(searchText.toLowerCase()))) ||
                                v.memo.toLowerCase().includes(searchText.toLowerCase())
                              )
                              : true)
                            .map((resource, index) => (
                              <SortableItem
                                key={resource.id}
                                resource={resource}
                                index={index}
                                loginUser={loginUser}
                                editResource={editResource}
                                deleteResource={deleteResource}
                                toggleIsActive={toggleIsActive}
                              />
                            ))}
                        </tbody>
                      </table>

                    </SortableContext>
                  </DndContext>
                  <ResourceEditModal
                    isOpen={isEditModalOpen}
                    isCreating={isCreating}
                    resource={resource}
                    onClose={closeEditModal}
                    onChange={onChangeState}
                    onChangeTags={onChangeTagsState}
                    onSetIconUrl={onSetIconUrl}
                    inputRef={inputRef}
                    onChangeImageButtonPressed={onChangeImageButtonPressed}
                    onRemoveImageButtonPressed={onRemoveImageButtonPressed}
                    onChangeImage={onChangeImage}
                    onSubmit={onUpdateResource}
                    errorMessage={modalErrorMessage}
                  />
                  <ResourceDeleteModal
                    isOpen={isDeleteModalOpen}
                    onClose={closeDeleteModal}
                    onSubmit={onDeleteResource}
                    errorMessage={modalErrorMessage}
                  />
                </div>
              ) : (
                <div className="loader-wrapper">
                  <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="42"
                    visible={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
});

interface SortableItemProps {
  resource: ResourceModel;
  index: number;
  loginUser: LoginUserModel | null;
  toggleIsActive: (resource: ResourceModel) => Promise<void>;
  editResource: (resource: ResourceForm) => void;
  deleteResource: (resource: ResourceForm) => void;
}

const SortableItem = ({
  resource,
  index,
  loginUser,
  toggleIsActive,
  editResource,
  deleteResource,
}: SortableItemProps) => {
  const { iconImageUrl, ..._resource } = resource;
  const resourceForm = {
    ..._resource,
    iconImageFile: {
      url: iconImageUrl ? iconImageUrl : "",
      file: null,
    },
  } as ResourceForm;
  const {
    isDragging,
    // 並び替えのつまみ部分に設定するプロパティ
    setActivatorNodeRef,
    attributes,
    listeners,
    // DOM全体に対して設定するプロパティ
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: resource.id });

  return (
    <tr
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition
      }}
      className={index % 2 === 0 ? "td even" : "td"}
    >
      <td
        className="td handle"
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
      >
        <RxDragHandleDots2 />
      </td>
      <td className="td icon">
        {resource.iconImageUrl ? (
          <div className="icon__wrapper">
            <img src={resource.iconImageUrl} />
          </div>
        ) : (
          <div className="icon__charat0">
            {resource.name.charAt(0)}
          </div>
        )}
      </td>
      <td className="td name">{resource.name}</td>
      <td className="td tag">
        {Object.keys(resource.tags).map(
          (tag) => `${tag} `,
        )}
      </td>
      <td className="td memo">{resource.memo}</td>
      <td className="td isActive">
        {(loginUser?.isOrganizationOwner() || loginUser?.isOrganizationMember()) && (
          <Checkbox
            props={{
              onClick: () => toggleIsActive(resource),
              checked: resource.isActive,
            }}
          />
        )}
      </td>
      <td className="td edit">
        {(loginUser?.isOrganizationOwner() || loginUser?.isOrganizationMember()) && (
          <Menu
            as="div"
            className="relative flex items-center text-left"
          >
            <Menu.Button>
              <PiDotsThreeCircleLight />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute top-[-8px] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active
                        ? "bg-[#57AEDF] text-white"
                        : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() =>
                        editResource(resourceForm)
                      }
                    >
                      編集する
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active
                        ? "bg-[#57AEDF] text-white"
                        : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() =>
                        deleteResource(resourceForm)
                      }
                    >
                      削除する
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        )}
      </td>
    </tr>
  );
}

interface ResourceEditModalProps {
  isOpen: boolean;
  isCreating: boolean;
  resource: ResourceForm;
  onClose: () => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeTags: (tags: string[]) => void;
  onSetIconUrl: (url: string) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  onChangeImageButtonPressed: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onRemoveImageButtonPressed: (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => void;
  onChangeImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
  errorMessage: string;
}

const ResourceEditModal = ({
  isOpen,
  isCreating,
  resource,
  onClose,
  onChange,
  onChangeTags,
  onSetIconUrl,
  inputRef,
  onChangeImageButtonPressed,
  onRemoveImageButtonPressed,
  onChangeImage,
  onSubmit,
  errorMessage,
}: ResourceEditModalProps) => {
  const onCloseTags = (i: number) => {
    const newTags = [...Object.keys(resource.tags)];
    newTags.splice(i, 1);
    onChangeTags && onChangeTags(newTags);
  };

  function handleTagsKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.nativeEvent.isComposing) return;

    const tagCount = Object.keys(resource.tags)?.length || 0;

    const value = e.currentTarget.value;
    if (!value.length && e.key == "Backspace" && tagCount > 0) {
      onCloseTags(tagCount - 1);
      return;
    }

    if (e.key !== "Enter" || !value.trim()) return;
    const newTags = [...Object.keys(resource.tags), value];
    onChangeTags && onChangeTags(newTags);
    e.currentTarget.value = "";
    e.preventDefault();
  }

  function handleOnBlur(e: React.FocusEvent<HTMLInputElement>) {
    const value = e.target.value;

    if (!value.trim()) return;
    const newTags = [...Object.keys(resource.tags), value];
    onChangeTags && onChangeTags(newTags);
    e.currentTarget.value = "";
    e.preventDefault();
  }

  const SvgComponent: React.FC<{ iconName: string; activeSvgPath: string }> = ({
    iconName,
    activeSvgPath,
  }) => {
    const svgPath = `/resource-icon/${iconName}.svg`;
    return (
      <div
        className={clsx(
          "w-[40px] h-[40px] object-cover flex overflow-hidden items-center justify-center rounded-[10px] cursor-pointe hover:opacity-70 transition-all duration-[0.2s] ease-[ease]",
          svgPath === activeSvgPath ? "bg-[#ddd]" : "bg-[white]",
        )}
        onClick={() => {
          onSetIconUrl(svgPath);
        }}
      >
        <img
          className="object-cover object-center w-[30px] h-[30px] cursor-pointer rounded-full"
          src={svgPath}
          alt={iconName}
        />
      </div>
    );
  };

  const SvgLisComponent: React.FC = React.memo(() => {
    const isFileImage = isUploadedImage(resource.iconImageFile.url);
    const svgFileNames: string[] = Object.keys(
      import.meta.glob("/public/resource-icon/*.svg"),
    ).map((fileName) => fileName.replace(/^.*[\\/]/, "").replace(/\.svg$/, ""));
    return (
      <Menu.Items className="p-[10px] absolute mt-2 w-[300px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
        <div className="px-1 py-1 flex items-center flex-row flex-wrap gap-1.5">
          <div className="flex w-full gap-2.5 flex-row justify-start mb-2.5">
            <label
              className={clsx(
                "py-[2px] px-[10px] rounded-none border-b-[transparent] border-b-2 border-solid",
                !isFileImage ? "border-b-[var(--color-primary)]" : "",
              )}
            >
              アイコン
            </label>
            <button
              className={clsx(
                "py-[2px] px-[10px] transition-[border] duration-[0.2s] ease-[ease] border-b-[transparent] border-b-2 border-solid rounded-none",
                isFileImage
                  ? "border-b-[var(--color-primary)]"
                  : "bg-[white] hover:border-b-[var(--color-primary)]",
              )}
              onClick={onChangeImageButtonPressed}
            >
              カスタム
            </button>
            <button
              className={
                "ml-auto transition-[border] duration-[0.2s] ease-[ease] border-b-[transparent] border-b-2 border-solid hover:border-b-[var(--color-primary)]"
              }
              onClick={onRemoveImageButtonPressed}
            >
              削除
            </button>
            <input
              name="file"
              type="file"
              accept="image/*"
              hidden
              ref={inputRef}
              onChange={onChangeImage}
            />
          </div>
          <div className="flex gap-[10px]">
            {svgFileNames.map((fileName) => (
              <Menu.Item>
                <SvgComponent
                  key={fileName}
                  iconName={fileName}
                  activeSvgPath={resource.iconImageFile.url}
                />
              </Menu.Item>
            ))}
          </div>
        </div>
      </Menu.Items>
    );
  });

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="conpath-headlessUI relative z-10"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mb-4">
                  <Menu as="div" className="relative w-[45px] h-[45px]">
                    <Menu.Button className="cursor-pointer inline-flex w-full h-full justify-center rounded-full p-0 text-sm font-medium text-white bg-[none] outline-none hover:opacity-70 transition-all duration-[0.2s] ease-[ease]">
                      {resource.iconImageFile.url ? (
                        <img
                          className="w-full h-full object-cover object-center rounded-full"
                          src={resource.iconImageFile.url}
                        />
                      ) : (
                        <div className="w-full h-full object-cover object-center rounded-full bg-[#ADD65B] flex items-center justify-center text-[20px]">
                          {resource.name.charAt(0) ? resource.name.charAt(0) : "+"}
                        </div>
                      )}
                    </Menu.Button>
                    <Transition
                      enter-active-className="transition duration-100 ease-out"
                      enter-from-className="transform scale-95 opacity-0"
                      enter-to-className="transform scale-100 opacity-100"
                      leave-active-className="transition duration-75 ease-in"
                      leave-from-className="transform scale-100 opacity-100"
                      leave-to-className="transform scale-95 opacity-0"
                    >
                      <SvgLisComponent />
                    </Transition>
                  </Menu>
                </div>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-[14px]">
                    名称
                  </label>
                  <input
                    name="name"
                    className="input rounded-[4px]"
                    value={resource.name}
                    onChange={onChange}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-[14px]">
                    タグ
                  </label>
                  <div
                    className={
                      "input rounded-[4px] flex flex-nowrap items-center leading-tight px-2 bg-white h-[45px] max-w-[550px] overflow-x-auto overflow-y-hidden"
                    }
                  >
                    {Object.keys(resource.tags).map((tag, i) => {
                      return (
                        <Badge
                          key={i}
                          onClose={() => onCloseTags(i)}
                          className={""}
                        >
                          {tag}
                        </Badge>
                      );
                    })}
                    <input
                      type="text"
                      className={"border-0 outline-none flex-grow"}
                      onKeyDown={handleTagsKeyDown}
                      onBlur={handleOnBlur}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="current_password"
                    className="block text-[14px]"
                  >
                    メモ
                  </label>
                  <input
                    name="memo"
                    className="input rounded-[4px]"
                    value={resource.memo}
                    onChange={onChange}
                  />
                </div>
                {errorMessage && (
                  <span className="text-sm text-red-500 mt-[2px]">
                    {errorMessage}
                  </span>
                )}
                <div className="account-setting--buttonRow p-0 mt-8">
                  <button onClick={onClose} className="button solid">
                    キャンセル
                  </button>
                  <button onClick={onSubmit} className="button main">
                    {isCreating ? "作成する" : "変更する"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

interface ResourceDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  errorMessage: string;
}

const ResourceDeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
  errorMessage,
}: ResourceDeleteModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  リソースを削除しますか？
                </Dialog.Title>
                {errorMessage && (
                  <span className="text-sm text-red-500 mt-[2px]">
                    {errorMessage}
                  </span>
                )}
                <div className="account-setting--buttonRow p-0 mt-8">
                  <button onClick={onClose} className="button solid">
                    キャンセル
                  </button>
                  <button onClick={onSubmit} className="button info">
                    削除する
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResourcesSettings;
