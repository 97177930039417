import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
// import { nanoid } from "nanoid";
import { t } from "src/excalidraw/i18n";
import "./EditTaskElement.scss";
import "./ConfirmDialogEx.scss";
import { DateObject } from "react-multi-date-picker";
import Calendar from "src/conpath/components/Calendar";
import type { Value } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/teal.css";
import ConfirmDialogEx from "./ConfirmDialogEx";
import { ExcalidrawCommentElement, ExcalidrawCommentableElement, ExcalidrawTaskElement } from "../element/types"; // UPDATED: REMOVE #1225
import { AppState } from "src/excalidraw/types";
import CalcCalendar from "../calendar";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import {
  getFontFamilyString,
  sceneCoordsToViewportCoords,
} from "src/excalidraw/utils"; // CHANGED:UPDATE 2023/02/06 #518
import { ActionManager } from "src/excalidraw/actions/manager"; // CHANGED:ADD 2023/02/06 #518
import { Point } from "points-on-curve"; // CHANGED:ADD 2023/02/06 #518
import { getElementAbsoluteCoords } from "src/excalidraw/element"; // CHANGED:ADD 2023/02/06 #518
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ja from "date-fns/locale/ja";
import { ToolButton } from "src/excalidraw/components/ToolButton";
import { EditIcon, ZoomInIcon, ZoomOutIcon } from "src/excalidraw/components/icons";
import { actionToggleEditTask } from "src/excalidraw/actions";
import { KEYS } from "src/excalidraw/keys";
import EditTaskCheckList from "./EditTaskCheckList";
import EditTaskTags from "./EditTaskTags";
import { observer } from "mobx-react-lite"; //CHANGED: ADD #1225
import { useStore } from "src/conpath/hooks/useStore";
import TaskModel from "src/conpath/models/TaskModel";
import Checkbox from "src/conpath/components/CheckBox";
import { GoCheckCircle } from "react-icons/go";
import { GoIssueReopened } from "react-icons/go";
import SelectAssignUsers from "./SelectAssignUsers";
import SelectAssignResources from "./SelectAssignResources";
import { Tags } from "src/conpath/interfaces/Tag";
import { CalenderIcon } from "src/conpath/components/icons";
import CommentInTaskEditDialog from "./CommentInTaskEditDialog";
import { Flip, ToastContainer } from 'react-toastify';
import { ElementsMap } from "../../element/types";

export const UpdateDataType = {
  UPDATE: "UPDATE",
  CLOSE_STATUS: "CLOSE_STATUS",
  CREATE_COMMENT_ELEMENT: "CREATE_COMMENT_ELEMENT",
  DELETE_COMMENT_ELEMENT: "DELETE_COMMENT_ELEMENT"
} as const;
export type UpdateDataType = typeof UpdateDataType[keyof typeof UpdateDataType];

const EditTaskElement = React.forwardRef(
  (
    {
      element,
      commentElement,
      title,
      appState,
      updateData,
    }: {
      element: ExcalidrawTaskElement;
      commentElement?: ExcalidrawCommentElement;
      title: string | undefined | null;
      appState: AppState;
      updateData: (formData?: {
        type: UpdateDataType;
        startDate?: Date;
        endDate?: Date;
        holidays?: string[] | null;
        title?: string | undefined | null;
        memo?: string;
        isClosed?: Boolean;
        commentId?: string;
        commentableElement?: ExcalidrawCommentableElement;
      }) => void;
    },
    ref,
  ) => {
    registerLocale("ja", ja);
    // CHANGED: ADD #1225
    const { organizationStore, userStore } = useStore();
    const { selectedOrganization } = organizationStore;
    const { loginUser } = userStore;

    const setAppState = useExcalidrawSetAppState();
    const [error, setError] = useState(false);
    const inputTitleRef = React.useRef<HTMLTextAreaElement>(null);
    const inputMemoRef = React.useRef<HTMLTextAreaElement>(null);
    const inputDurationRef = React.useRef<HTMLInputElement>(null);
    const [duration, setDuration] = useState<number>(element.duration);
    const [holidays, setHolidays] = useState<string[] | null>(element.holidays);
    const [isHolidaysEnabled, setIsHolidaysEnabled] = useState(!!holidays);
    const [isClosed, setIsClosed] = useState<boolean>(element.isClosed);
    const [isModified, setIsModified] = useState<boolean>(false);
    const [dates, setDates] = useState<Value>(
      holidays ? holidays : appState.holidays,
    );
    const [taskModel, setTaskModel] = useState<TaskModel | null>(null);
    const [beforeAssignUsers, setBeforeAssignUsers] = useState<string[]>([]);
    const [beforeAssignResources, setBeforeAssignResources] = useState<string[]>([]);
    const [beforeTags, setBeforeTags] = useState<Tags>({});

    const maxDate = new Date(appState.projectEndDate);

    const calendar = React.useMemo(() => {
      return new CalcCalendar(
        appState.gridSize,
        appState.projectStartDate,
        appState.holidays,
      );
    }, [appState]);

    React.useImperativeHandle(ref, () => inputTitleRef.current);
    React.useImperativeHandle(ref, () => inputMemoRef.current);
    React.useImperativeHandle(ref, () => inputDurationRef.current);

    useEffect(() => {
      const getTaskModel = async () => {
        if (selectedOrganization) {
          const task = await selectedOrganization.getSelectedTaskOnCanvas(
            element,
            appState.projectId,
            title,
          );
          task.setOrganizationId(selectedOrganization.id);
          await task.getChecklists();
          setTaskModel(task);
          setBeforeAssignUsers(task.assignUserIds);
          setBeforeAssignResources(task.assignResourceIds);
          setBeforeTags({ ...task.tags });
        }
      };

      getTaskModel();
    }, [selectedOrganization]);

    useEffect(() => {
      const current = inputMemoRef.current;
      if (!current) return;

      current.style.height = "auto";
      current.style.height = `${current.scrollHeight}px`;
    }, [inputMemoRef.current]);

    const onClose = useCallback(async () => {
      if (!taskModel || !loginUser) return;
      if (error) return;

      if (taskModel.isUpdatedChecklists) {
        const result = await taskModel.saveChecklist();
        if (result.error) {
          setAppState({ errorMessage: result.error });
          return;
        }
      }

      //CHANGED:ADD 2023-12-14 #1386
      if (!isEqual(beforeAssignUsers, taskModel.assignUserIds)) {
        selectedOrganization?.updateTask(taskModel);
        const result = await taskModel.saveAssignUsers(loginUser);
        if (result.error) {
          setAppState({ errorMessage: result.error });
          return;
        }
      }

      if (!isEqual(beforeAssignResources, taskModel.assignResourceIds)) {
        selectedOrganization?.updateTask(taskModel);
        const result = await taskModel.saveAssignResources();
        if (result.error) {
          setAppState({ errorMessage: result.error });
          return;
        }
      }

      if (!isEqual(beforeTags, taskModel.tags)) {
        selectedOrganization?.updateTask(taskModel);
        const result = await taskModel.saveTags();
        if (result.error) {
          setAppState({ errorMessage: result.error });
          return;
        }
      }

      if (isModified) {
        selectedOrganization?.updateTask(taskModel);
        updateData({
          type: UpdateDataType.UPDATE,
          startDate: taskModel.startDate,
          endDate: taskModel.endDate,
          holidays,
          title: taskModel.text,
          memo: taskModel.memo,
        });
      }

      setAppState({ openDialog: null });
    }, [taskModel, loginUser, holidays, isClosed, error, isModified]);

    const onStartDateChange = (startDate: Date) => {
      if (!taskModel) return;
      taskModel.setState({ startDate: startDate });

      const date = new Date(startDate);

      const target = duration <= 0 ? 1 : duration;
      let newDuration = 1;
      for (
        ;
        date.getTime() < maxDate.getTime();
        date.setDate(date.getDate() + 1)
      ) {
        const isHoliday = (holidays ? holidays : appState.holidays).some(
          (holiday) => new Date(holiday).toDateString() === date.toDateString(),
        );

        if (newDuration < target && !isHoliday) {
          newDuration++;
        } else if (newDuration === target && !isHoliday) {
          break;
        }
      }

      date.setDate(date.getDate() + 1);
      taskModel.setState({ endDate: date });

      setDuration(newDuration);
      setError(startDate.getTime() >= date.getTime());
      setIsModified(true);
    };

    const onEndDateChange = (endDate: Date) => {
      if (!taskModel) return;
      endDate.setDate(endDate.getDate() + 1);
      taskModel.setState({ endDate: endDate });

      const newDuration = calendar.getDuration(
        taskModel.startDate,
        endDate,
        holidays,
      );
      setDuration(newDuration);
      setError(taskModel.startDate.getTime() >= endDate.getTime());
      setIsModified(true);
    };

    const onDurationChange = (value: string) => {
      if (!taskModel) return;
      const inputValue = parseInt(value);

      if (!Number.isNaN(inputValue)) {
        const date = new Date(taskModel.startDate);
        let newDuration = 0;

        if (inputValue > 0) {
          newDuration = 1;

          for (
            ;
            date.getTime() < maxDate.getTime();
            date.setDate(date.getDate() + 1)
          ) {
            const isHoliday = (holidays ? holidays : appState.holidays).some(
              (holiday) =>
                new Date(holiday).toDateString() === date.toDateString(),
            );

            if (newDuration < inputValue && !isHoliday) {
              newDuration++;
            } else if (newDuration === inputValue && !isHoliday) {
              break;
            }
          }
        }

        date.setDate(date.getDate() + 1);
        taskModel.setState({ endDate: date });
        setDuration(newDuration);
        setIsModified(true);
      } else {
        setDuration(0);
      }
    };

    const onDurationKeyDown = (key: string) => {
      if (key === KEYS.ARROW_UP) {
        onDurationChange(`${duration + 1}`);
      } else if (key === KEYS.ARROW_DOWN) {
        onDurationChange(`${duration - 1}`);
      }
    };

    const onCheckedChange = () => {
      const check = !isHolidaysEnabled;
      setIsHolidaysEnabled(check);

      if (check) {
        const holidays = Array.from(dates as DateObject[])
          .sort()
          .map((v) => v.toString());

        setHolidays(holidays);
      } else {
        setHolidays(null);
        setDates(appState.holidays);
      }

      setIsModified(true);
    };

    const onDateChange = (dates: DateObject[]) => {
      if (!taskModel) return;

      const holidays = Array.from(dates)
        .sort()
        .map((v) => v.toString());

      setHolidays(holidays);
      setDates(dates);

      const newDuration = calendar.getDuration(
        taskModel.startDate,
        taskModel.endDate,
        holidays,
      );
      setDuration(newDuration);
      setIsModified(true);
    };

    const getBindTextareaHeight = (e: any) => {
      const { name, value, style, scrollHeight } = e.target;
      if (!taskModel) return;
      taskModel.setState({ [name]: value }); ///CHANGED: ADD #1225
      style.height = "auto";
      style.height = `${scrollHeight}px`;
    };

    const handleClick = useCallback(async () => {
      setTimeout(() => {
        setIsClosed(!isClosed);
        updateData({
          type: UpdateDataType.CLOSE_STATUS,
          isClosed: !isClosed,
        });
      }, 500);
    }, [isClosed]);

    const onConfirm = useCallback(async () => {
      // if (!taskModel) return;

      // const result = await taskModel.saveChecklist();
      // if (result.error) {
      //   setAppState({ errorMessage: result.error });
      //   return;
      // }

      // selectedOrganization?.updateTask(taskModel);

      // updateData({
      //   startDate: taskModel.startDate,
      //   endDate: taskModel.endDate,
      //   holidays,
      //   title: taskModel.text,
      //   isClosed,
      // });
      // onClose();
    }, [taskModel, holidays, isClosed]);

    const onUpdateCommentData = useCallback((
      { type, commentableElement, commentId }: 
      { type: UpdateDataType, 
        commentableElement: ExcalidrawCommentableElement, 
        commentId?: string 
      }) => {
        if (type === UpdateDataType.CREATE_COMMENT_ELEMENT) {
          updateData({
            type: type,
            commentableElement: commentableElement,
            commentId: commentId!,
          });
        } else if (type === UpdateDataType.DELETE_COMMENT_ELEMENT) {
          updateData({
            type: type,
            commentableElement: commentableElement
          });
        }
    }, [updateData]);

    if (!taskModel) return <></>;
    return (
      <>
        <ConfirmDialogEx
          onConfirm={onConfirm}
          onCancel={onClose}
          title={""}
          className={"EditTaskElement"}
          disabled={error}
          isHideDialogButtons={true}
        >
          <ToastContainer
            position="top-center"
            transition={Flip}
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="task-content__wrapper">
            <div className="task-content__wrapper__left-box">
              {/* CHANGED:UPDATE 2023/02/10 #611 */}
              <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content">
                  <label className="task-content__label">
                    {t("labels.editTask.taskTitle")}
                  </label>
                  <label className="task-content__input-box">
                    <textarea
                      name="text"
                      rows={1}
                      className="task-content__input-box__textarea"
                      style={{
                        fontFamily: getFontFamilyString({
                          fontFamily: appState.currentItemFontFamily,
                        }),
                      }}
                      placeholder={t("labels.editTask.taskTitle")}
                      aria-label={t("labels.editTask.taskTitle")}
                      onChange={(event) => {
                        getBindTextareaHeight(event);
                        setIsModified(true);
                      }}
                      onFocus={(event) => getBindTextareaHeight(event)}
                      value={taskModel.text || ""} // CHANGED:UPDATE 2023/11/24 #1225
                      ref={inputTitleRef}
                    />
                  </label>
                </div>
              </div>
              {/* CHANGED:UPDATE #1225 */}
              <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content">
                  {/* tags */}
                  <div className="task-content__label-wrapper">
                    <label className="task-content__label">タグ</label>
                  </div>
                  <EditTaskTags
                    taskModel={taskModel}
                  />
                </div>
              </div>
              {/* CHANGED:ADD #819 */}
              <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content">
                  {/* checklists */}
                  <div className="task-content__label-wrapper">
                    <label className="task-content__label">
                      {t("labels.editTask.checkListTitle")}
                    </label>
                    {/* CHANGED:REMOVE 2024-02-09 #1629 */}
                    {/* {taskModel.emptyChecklistTitleExists && (
                      <label className="task-content__label error">
                        {t("errors.checkListTitleMissing")}
                      </label>
                    )} */}
                  </div>
                  <EditTaskCheckList
                    isClosed={isClosed}
                    taskModel={taskModel}
                    setIsClosed={setIsClosed}
                    titleRef={inputTitleRef}
                  />
                  {/* CHANGED:ADD #1991 */}
                  <div className="confirm-dialog__island">
                    <div className="confirm-dialog__island-content">
                      <label className="task-content__label">
                        {t("labels.editTask.taskMemo")}
                      </label>
                      <label className="task-content__input-box">
                        <textarea
                          name="memo"
                          rows={1}
                          className="task-content__input-box__textarea"
                          style={{
                            fontFamily: getFontFamilyString({
                              fontFamily: appState.currentItemFontFamily,
                            }),
                          }}
                          aria-label={t("labels.editTask.taskMemo")}
                          onChange={(event) => {
                            getBindTextareaHeight(event);
                            setIsModified(true);
                          }}
                          value={taskModel.memo || ""}
                          ref={inputMemoRef}
                        />
                      </label>
                    </div>
                  </div>
                  {/* comment */}
                  <div className="task-content__label-wrapper">
                    <label className="task-content__label">コメント</label>
                  </div>
                  <div
                    style={{
                      height: "220px",
                      padding: "12px",
                      background: `var(--sidebar-bg-color)`,
                    }}
                  >
                    <CommentInTaskEditDialog
                      appState={appState}
                      commentElement={commentElement}
                      commentableElement={element}
                      updateData={onUpdateCommentData}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="task-content__wrapper__right-box">
              {/* CHANGED:UPDATE 2023/02/10 #611 */}
              <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content task-content">
                  <SelectAssignUsers
                    taskModel={taskModel}
                    organization={selectedOrganization}
                  />
                </div>
                <div className="confirm-dialog__island-content task-content">
                  <SelectAssignResources
                    taskModel={taskModel}
                    organization={selectedOrganization}
                  />
                </div>
              </div>
              <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content task-content">
                  <div className="task-content__range">
                    <div>
                      <label className="task-content__label">
                        {t("labels.editTask.taskStartDate")}
                      </label>
                      <label className="task-content__input-box">
                        <DatePicker
                          dateFormat="yyyy/MM/dd"
                          locale="ja"
                          dateFormatCalendar={t(
                            "labels.editTask.rangeCalendarHeader",
                          )} // CHANGED:UPDATE 2023/02/15 #706
                          selected={taskModel.startDate}
                          onChange={(date: Date) => onStartDateChange(date)}
                        />
                        <CalenderIcon
                          className="task-content__input-box__icon"
                        />
                      </label>
                    </div>
                    <span>〜</span>
                    <div>
                      <label className="task-content__label">
                        {t("labels.editTask.taskEndDate")}
                      </label>
                      <label className="task-content__input-box">
                        <DatePicker
                          dateFormat="yyyy/MM/dd"
                          locale="ja"
                          dateFormatCalendar={t(
                            "labels.editTask.rangeCalendarHeader",
                          )} // CHANGED:UPDATE 2023/02/15 #706
                          selected={taskModel.getDisplayEndDate} //CHANGED:UPDATE 2023/11/24 #1225
                          onChange={(date: Date) => onEndDateChange(date)}
                        />
                        <CalenderIcon
                          className="task-content__input-box__icon"
                        />
                      </label>
                    </div>
                  </div>
                  <div>
                    <label className="task-content__label">
                      {t("labels.editTask.taskDuration")}
                    </label>

                    <label className="task-content__input-box">
                      <ToolButton
                        type="button"
                        className="zoom-out-button zoom-button"
                        icon={ZoomOutIcon}
                        aria-label={t("buttons.zoomIn")}
                        onClick={() => {
                          onDurationChange(`${duration - 1}`);
                        }}
                      />
                      <input
                        type="text"
                        className="task-duration-picker"
                        style={{
                          fontFamily: getFontFamilyString({
                            fontFamily: appState.currentItemFontFamily,
                          }),
                        }}
                        aria-label={t("labels.editTask.taskDuration")}
                        onChange={(event) => {
                          onDurationChange(event.target.value);
                        }}
                        onKeyDown={(event) => {
                          onDurationKeyDown(event.key);
                        }}
                        value={duration || ""}
                        ref={inputDurationRef}
                      />
                      <ToolButton
                        type="button"
                        className="zoom-in-button zoom-button"
                        icon={ZoomInIcon}
                        aria-label={t("buttons.zoomIn")}
                        onClick={() => {
                          onDurationChange(`${duration + 1}`);
                        }}
                      />
                    </label>
                  </div>

                  <div className="holiday-calendar__wrapper">
                    <div className="mt-4 mb-2">
                      <Checkbox
                        props={{
                          onClick: () => onCheckedChange(),
                          checked: isHolidaysEnabled,
                          id: "holiday-check",
                          className: "rmdp-holiday-check",
                        }}
                      />
                      <label htmlFor="holiday-check">
                        {t("labels.editTask.checkTaskHolidays")}
                      </label>
                    </div>
                    {/* <Disclosure
                      as="div"
                      defaultOpen={isHolidaysEnabled}
                      className={`mb-2`}
                      key={"holidaysCalendar"}
                    >
                      <Transition
                        show={isHolidaysEnabled}
                        className="overflow-hidden"
                        enter="transition transition-[max-height] duration-800 ease-in"
                        enterFrom="transform max-h-0"
                        enterTo="transform max-h-screen"
                        leave="transition transition-[max-height] duration-800 ease-out"
                        leaveFrom="transform max-h-screen"
                        leaveTo="transform max-h-0"
                      > */}
                    <div className="rmdp-holiday-check__calendar">
                      <Calendar
                        props={{
                          headerOrder: ["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"],
                          multiple: true,
                          shadow: false,
                          hideYear: true,
                          currentDate: new DateObject({
                            year: taskModel.startDate.getFullYear(),
                            month: taskModel.startDate.getMonth() + 1,
                            day: taskModel.startDate.getDate(),
                          }),
                          minDate: taskModel.startDate,
                          maxDate: taskModel.getDisplayEndDate, //CHANGED:UPDATE 2023/11/24 #1225
                          value: dates,
                          onChange: onDateChange,
                          disabled: !isHolidaysEnabled,
                          className: "test",
                        }}
                      />
                      <span className="Dialog__error_message">
                        {error && t("errors.invalidTaskDate")}
                      </span>
                    </div>
                    {/* </Transition>
                    </Disclosure> */}
                  </div>
                </div>
              </div>
              {/* CHANGED:UPDATE 2023/02/10 #611 */}
              {/* <div className="confirm-dialog__island">
                <div className="confirm-dialog__island-content">

                </div>
              </div> */}
            </div>
            {/* CHANGED:UPDATE 2023/12/06 #1272 */}
            <div className="task-content__wrapper__close-button-box">
              <button
                className={`task-content__btn-closed ${!isClosed ? "closed" : ""}`}
                onClick={handleClick}
              >
                {isClosed ? (
                  <>
                    <GoIssueReopened />
                    {t("labels.editTask.reopenTask")}
                  </>
                ) : (
                  <>
                    <GoCheckCircle />
                    {t("labels.editTask.closeTask")}
                  </>
                )}
              </button>
            </div>
          </div>
        </ConfirmDialogEx>
      </>
    );
  },
);

// CHANGED:ADD 2023/02/06 #518
const getViewportCoordsEditTaskBtn = (
  element: ExcalidrawTaskElement,
  appState: AppState,
  elementsMap: ElementsMap,
): Point => {
  const [x1, y1] = getElementAbsoluteCoords(element, elementsMap);
  const { x: viewportX, y: viewportY } = sceneCoordsToViewportCoords(
    {
      sceneX: x1 + element.width,
      sceneY: y1,
    },
    {
      ...appState,
    },
  );
  const x = viewportX - appState.offsetLeft;
  const y = viewportY - appState.offsetTop;
  return [x, y - 50];
};

// CHANGED:ADD 2023/02/06 #518
export const EditTaskBtn = ({
  element,
  actionManager,
  appState,
  elementsMap,
}: {
  element: ExcalidrawTaskElement;
  actionManager: ActionManager;
  appState: AppState;
  elementsMap: ElementsMap,
}) => {
  const [left, top] = getViewportCoordsEditTaskBtn(element, appState, elementsMap);

  return (
    <>
      {/* CHANGED:ADD 2023-3-6 #738 */}
      {!appState.viewModeEnabled && (
        <div className="editTaskBtn__wrapper" style={{ top, left }}>
          <ToolButton
            type="button"
            icon={EditIcon}
            title={t("buttons.edit")}
            aria-label={t("buttons.edit")}
            onClick={() => actionManager.executeAction(actionToggleEditTask)}
          />
        </div>
      )}
    </>
  );
};

export default observer(EditTaskElement);
