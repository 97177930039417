import React from "react";
import { ActionManager } from "../actions/manager";
import { getNonDeletedElements } from "../element";
import {
  ExcalidrawElementType,
  NonDeletedElementsMap,
  NonDeletedSceneElementsMap,
} from "../element/types";
import { t } from "../i18n";
import { useDevice } from "./App";
import {
  canChangeRoundness,
  canHaveArrowheads,
  getSelectedElements,
  getTargetElements,
  hasBackground,
  hasStrokeStyle,
  hasStrokeWidth,
  hasText,
} from "../scene";
import { SHAPES } from "../shapes";
import { AppClassProperties, AppProps, UIAppState, Zoom } from "../types";
import {
  capitalizeString,
  isTransparent,
} from "../utils";
import Stack from "./Stack";
import { ToolButton } from "./ToolButton";
import { hasStrokeColor, hasStrokeWidthEx } from "../scene/comparisons"; // CHANGED:UPDATE 2023/2/9 #601
import { trackEvent } from "../analytics";
import { hasBoundTextElement } from "../element/typeChecks";
import {
  hasBoundTextElementEx,
  isJobElement,
} from "../extensions/element/typeChecks"; // from extensions
import clsx from "clsx";
import { actionToggleZenMode } from "../actions";
import "./Actions.scss";
import { Tooltip } from "./Tooltip";
import {
  shouldAllowVerticalAlign,
  suppportsHorizontalAlign,
} from "../element/textElement";
//CHHANGED:ADD 2022-12-01 #223
import {
  isGraphElement,
  isNodeElement,
  isTaskElement,
} from "../extensions/element/typeChecks"; // from extensions
import { isTextElement } from "../element"; //CHANGED:ADD 2022/12/08 #225
import { ExcalidrawTextElement } from "../element/types"; //CHANGED:ADD 2022/12/08 #225
import { TaskProperties } from "src/excalidraw/extensions/components/TaskProperties";

export const SelectedShapeActions = ({
  appState,
  elementsMap,
  renderAction,
}: {
  appState: UIAppState;
  elementsMap: NonDeletedElementsMap | NonDeletedSceneElementsMap;
  renderAction: ActionManager["renderAction"];
}) => {
  const targetElements = getTargetElements(elementsMap, appState);

  const nonIncludeBoundTextElements = getSelectedElements(
    getNonDeletedElements(targetElements), appState, {
    includeBoundTextElement: false,
  });

  let isSingleElementBoundContainer = false;
  if (
    targetElements.length === 2 &&
    (hasBoundTextElement(targetElements[0]) ||
      hasBoundTextElement(targetElements[1]) ||
      hasBoundTextElementEx(targetElements[0]) || // CHANGED:ADD 2023-1-28 #540
      hasBoundTextElementEx(targetElements[1])) // CHANGED:ADD 2023-1-28 #540
  ) {
    isSingleElementBoundContainer = true;
  }
  const isEditing = Boolean(appState.editingElement);
  const device = useDevice();
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  //CHHANGED:ADD 2022-12-01 #223
  let hasGraphElement = targetElements.some((element) =>
    isGraphElement(element),
  );

  //CHHANGED:ADD 2023-01-03 #570 jobElement選択時のpanel内ボタンの取捨選択のため
  const hasJobElement = targetElements.some((element) => isJobElement(element));

  //CHANGED:ADD 2022/12/08 #225
  if (
    targetElements.length === 1 &&
    isEditing &&
    isTextElement(targetElements[0]) &&
    !!targetElements[0].containerId
  ) {
    const containerElement = targetElements[0] as ExcalidrawTextElement;
    hasGraphElement = targetElements.some(
      (element) =>
        element.id === containerElement.containerId && isNodeElement(element),
    );
  }

  const showFillIcons =
    (hasBackground(appState.activeTool.type) ||
      targetElements.some(
        (element) =>
          hasBackground(element.type) &&
          !isTransparent(element.backgroundColor),
      )) &&
    !hasGraphElement; //CHHANGED:ADD 2022-12-01 #223

  const showChangeBackgroundIcons =
    (hasBackground(appState.activeTool.type) ||
      targetElements.some((element) => hasBackground(element.type))) &&
    !hasGraphElement; //CHHANGED:ADD 2022-12-01 #223

  // CHANGED:UPDATE 2022-10-20 #12
  // const showLinkIcon =
  //   targetElements.length === 1 || isSingleElementBoundContainer;

  let commonSelectedType: string | null = targetElements[0]?.type || null;

  for (const element of targetElements) {
    if (element.type !== commonSelectedType) {
      commonSelectedType = null;
      break;
    }
  }

  const hasTaskElement = targetElements.some((element) =>
    isTaskElement(element),
  );

  return (
    <div className="panelColumn">
      {/* CHANGED:ADD 2023-1-26 #506 */}
      {hasTaskElement && !isEditing && targetElements.length > 0 && (
        <fieldset>
          <TaskProperties
            targetElements={targetElements}
            elementsMap={elementsMap}
          />
        </fieldset>
      )}
      <div>
        {((hasStrokeColor(appState.activeTool.type) &&
          appState.activeTool.type !== "image" &&
          commonSelectedType !== "image") ||
          targetElements.some((element) => hasStrokeColor(element.type))) &&
          // CHANGED:REMOVE 2023-1-23 #455
          // (!hasGraphElement ||
          //   (hasGraphElement &&
          //     targetElements.length === 1 &&
          //     isTextElement(targetElements[0]))) &&  //CHANGED:UPDATE 2022-12-13 #306
          renderAction("changeStrokeColor")}
      </div>
      {showChangeBackgroundIcons && (
        <div>{renderAction("changeBackgroundColor")}</div>
      )}
      {showFillIcons && renderAction("changeFillStyle")}

      {(hasStrokeWidth(appState.activeTool.type) ||
        (targetElements.some((element) => hasStrokeWidth(element.type)) &&
          !targetElements.some((element) => hasStrokeWidthEx(element.type)))) &&
        //CHHANGED:REMOVE 2023-2-9 #601
        //!hasGraphElement && //CHHANGED:ADD 2022-12-01 #223
        renderAction("changeStrokeWidth")}
      {/* CHANGED:ADD 2023/2/9 #601 */}
      {(hasStrokeWidthEx(appState.activeTool.type) ||
        (nonIncludeBoundTextElements.length > 0 &&
          nonIncludeBoundTextElements.every((element) =>
            hasStrokeWidthEx(element.type),
          ))) &&
        renderAction("changeStrokeWidthEx")}
      {(appState.activeTool.type === "freedraw" ||
        targetElements.some((element) => element.type === "freedraw")) &&
        !hasGraphElement && //CHHANGED:ADD 2022-12-01 #223
        renderAction("changeStrokeShape")}

      {(hasStrokeStyle(appState.activeTool.type) ||
        targetElements.some((element) => hasStrokeStyle(element.type))) &&
        !hasGraphElement && ( //CHHANGED:ADD 2022-12-01 #223
          <>
            {renderAction("changeStrokeStyle")}
            {/* CHANGED:UPDATE 2022-10-20 #10 */}
            {/* {renderAction("changeSloppiness")} */}
          </>
        )}

      {(canChangeRoundness(appState.activeTool.type) ||
        (!hasGraphElement /*CHHANGED:ADD 2022-12-01 #223*/ &&
          targetElements.some((element) =>
            canChangeRoundness(element.type),
          ))) && <>{renderAction("changeRoundness")}</>}

      {(hasText(appState.activeTool.type) ||
        targetElements.some(
          (element) =>
            isTextElement(element) && !element.isCompressed) // CHAGED:UPDATE 2023-03-15 #740
      ) && (
          <>
            {renderAction("changeFontSize")}

            {/* CHANGED:UPDATE 2022-10-20 #33 */}
            {/* {renderAction("changeFontFamily")} */}

            {suppportsHorizontalAlign(targetElements, elementsMap) &&
              renderAction("changeTextAlign")}
          </>
        )}

      {shouldAllowVerticalAlign(targetElements, elementsMap) &&
        renderAction("changeVerticalAlign")}
      {(canHaveArrowheads(appState.activeTool.type) ||
        (!hasGraphElement /*CHHANGED:ADD 2022-12-01 #223 */ &&
          targetElements.some((element) =>
            canHaveArrowheads(element.type),
          ))) && <>{renderAction("changeArrowhead")}</>}

      {/*CHHANGED:UPDATE 2023-2-7 #570 */}
      {/* {renderAction("changeOpacity")} */}
      {/* CHANGED:REMOVE 2024-02-16 #1666 */}
      {/* {!hasGraphElement && !hasJobElement && renderAction("changeOpacity")} */}

      {/*CHHANGED:ADD 2022-12-01 #223 */}
      {/*CHHANGED:ADD 2023-02-03 #570 jobElementにレイヤーのボタンは不必要なので、!hasJobElement追加 */}
      {!hasGraphElement && !hasJobElement && (
        <fieldset>
          <legend>{t("labels.layers")}</legend>
          <div className="buttonList">
            {renderAction("sendToBack")}
            {renderAction("sendBackward")}
            {renderAction("bringToFront")}
            {renderAction("bringForward")}
          </div>
        </fieldset>
      )}

      {/*CHHANGED:UPDATE 2023-2-7 #570 */}
      {!hasGraphElement &&
        !hasJobElement &&
        targetElements.length > 1 &&
        !isSingleElementBoundContainer && (
          <fieldset>
            <legend>{t("labels.align")}</legend>
            <div className="buttonList">
              {
                // swap this order for RTL so the button positions always match their action
                // (i.e. the leftmost button aligns left)
              }
              {isRTL ? (
                <>
                  {renderAction("alignRight")}
                  {renderAction("alignHorizontallyCentered")}
                  {renderAction("alignLeft")}
                </>
              ) : (
                <>
                  {renderAction("alignLeft")}
                  {renderAction("alignHorizontallyCentered")}
                  {renderAction("alignRight")}
                </>
              )}
              {targetElements.length > 2 &&
                renderAction("distributeHorizontally")}
              {/* breaks the row ˇˇ */}
              <div style={{ flexBasis: "100%", height: 0 }} />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: ".5rem",
                  marginTop: "-0.5rem",
                }}
              >
                {renderAction("alignTop")}
                {renderAction("alignVerticallyCentered")}
                {renderAction("alignBottom")}
                {targetElements.length > 2 &&
                  renderAction("distributeVertically")}
              </div>
            </div>
          </fieldset>
        )}
      {!isEditing && targetElements.length > 0 && (
        <fieldset>
          <legend>{t("labels.actions")}</legend>
          <div className="buttonList">
            {/* CHANGED:ADD 2023-1-26 #506 */}
            {hasTaskElement &&
              isSingleElementBoundContainer &&
              renderAction("toggleEditTask")}
            {hasTaskElement &&
              isSingleElementBoundContainer &&
              renderAction("alignLeftTask")}
            {/* CHANGED:ADD 2023-02-03 #570 jobElementにduplicateのボタンは不必要なので、!hasJobElement追加 */}
            {!device.isMobile &&
              !hasJobElement &&
              renderAction("duplicateSelection")}
            {/*CHHANGED:UPDATE 2023-2-7 #570 */}
            {!device.isMobile && !hasJobElement && renderAction("toggleClose")}
            {!device.isMobile && renderAction("deleteSelectedElements")}
            {/*CHHANGED:UPDATE 2023-2-7 #570 */}
            {/* {renderAction("group")} */}
            {/* {renderAction("ungroup")} */}
            {!hasGraphElement && !hasJobElement && renderAction("group")}
            {!hasGraphElement && !hasJobElement && renderAction("ungroup")}
            {/* CHANGED:UPDATE 2022-10-20 #12 */}
            {/* {showLinkIcon && renderAction("hyperlink")} */}
          </div>
        </fieldset>
      )}
    </div>
  );
};

export const ShapesSwitcher = ({
  activeTool,
  appState,
  app,
  UIOptions,
}: {
  activeTool: UIAppState["activeTool"];
  appState: UIAppState;
  app: AppClassProperties;
  UIOptions: AppProps["UIOptions"];
}) => {

  return (
    <>
      {SHAPES.map(({ value, icon, key, numericKey, fillable }, index) => {
        const label = t(`toolBar.${value}`);
        const letter =
          key && capitalizeString(typeof key === "string" ? key : key[0]);
        const shortcut = letter
          ? `${letter} ${t("helpDialog.or")} ${numericKey}`
          : `${numericKey}`;
        return (
          <ToolButton
            className={clsx("Shape", { fillable })}
            key={value}
            type="radio"
            icon={icon}
            checked={activeTool.type === value}
            name="editor-current-shape"
            title={`${capitalizeString(label)} — ${shortcut}`}
            // keyBindingLabel={numericKey || letter} アイコンの添え字のショートカットのための番号
            aria-label={capitalizeString(label)}
            aria-keyshortcuts={shortcut}
            data-testid={`toolbar-${value}`}
            onPointerDown={({ pointerType }) => {
              if (!appState.penDetected && pointerType === "pen") {
                app.togglePenMode(true);
              }
            }}
            onChange={({ pointerType }) => {
              if (appState.activeTool.type !== value) {
                trackEvent("toolbar", value, "ui");
              }
              if (value === "image") {
                app.setActiveTool({
                  type: value,
                  insertOnCanvasDirectly: pointerType !== "mouse",
                });
              } else {
                app.setActiveTool({ type: value });
              }
            }}
          />
        );
      },
      )}
    </>
  );
};

export const ZoomActions = ({
  renderAction,
  zoom,
}: {
  renderAction: ActionManager["renderAction"];
  zoom: Zoom;
}) => (
  <Stack.Col gap={1} className="zoom-actions">
    <Stack.Row align="center">
      {renderAction("zoomIn")}
      {renderAction("resetZoom")}
      {renderAction("zoomOut")}
    </Stack.Row>
  </Stack.Col>
);

export const UndoRedoActions = ({
  renderAction,
  className,
}: {
  renderAction: ActionManager["renderAction"];
  className?: string;
}) => (
  <div className={`undo-redo-buttons ${className}`}>
    <div className="undo-button-container">
      <Tooltip label={t("buttons.undo")}>{renderAction("undo")}</Tooltip>
    </div>
    <div className="redo-button-container">
      <Tooltip label={t("buttons.redo")}> {renderAction("redo")}</Tooltip>
    </div>
  </div>
);

export const ExitZenModeAction = ({
  actionManager,
  showExitZenModeBtn,
}: {
  actionManager: ActionManager;
  showExitZenModeBtn: boolean;
}) => (
  <button
    className={clsx("disable-zen-mode", {
      "disable-zen-mode--visible": showExitZenModeBtn,
    })}
    onClick={() => actionManager.executeAction(actionToggleZenMode)}
  >
    {t("buttons.exitZenMode")}
  </button>
);

export const FinalizeAction = ({
  renderAction,
  className,
}: {
  renderAction: ActionManager["renderAction"];
  className?: string;
}) => (
  <div className={`finalize-button ${className}`}>
    {renderAction("finalize", { size: "small" })}
  </div>
);
