import React, { useMemo } from "react";
import Select, { Options, GroupBase, StylesConfig } from 'react-select';
import "./Selection.scss";

export interface SelectionOption {
  value: string;
  label: string;
};

interface Props {
  id: string;
  value: string;
  options: SelectionOption[];
  onChange: (value: string) => void;
  height?: string;
  inline?: boolean;
};

const Selection: React.FC<Props> = ({
  id,
  value,
  options,
  onChange,
  height,
  inline,
}: Props) => {
  return (
    <>
      <div className="selection-container">
        <select
          style={{
            height: height ? height : "100%",
          }}
          className={inline ? "inline-select" : "select"}
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        >
          {options &&
            options.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            })}
        </select>
        <svg className="select-svg" xmlns="http://www.w3.org/2000/svg" width="7.695" height="4.428" viewBox="0 0 7.695 4.428">
          <path d="M-21926.242-12504.284l3.5,3.373,3.5-3.373" transform="translate(21926.59 12504.645)" fill="none" stroke="#000" strokeWidth="1" />
        </svg>
      </div>
    </>
  );
};

export default React.memo(Selection);
