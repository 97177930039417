import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
//components
import SettingsLayout from "src/conpath/components/settings/SettingLayout";
import SearchBar from "src/conpath/components/SearchBar";
import Selection, { SelectionOption } from "src/conpath/components/Selection";
import Select, { MultiValue } from "react-select";
import { Menu, Transition } from "@headlessui/react";
import { Button } from "src/conpath/components/Button";
import { IoTrashOutline, IoStar } from "react-icons/io5";
import { PiDotsThreeCircleLight } from "react-icons/pi";
import { Tab } from "@headlessui/react";
import { RotatingLines } from "react-loader-spinner";
import { Dialog } from "@headlessui/react";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";

//constants
import { Paths } from "src/conpath/constants/Routes";
import {
  OrganizationRole,
  OrganizationRoleMap,
} from "src/conpath/constants/Role";
import {
  OrganizationUserState,
  OrganizationUserStateMap,
} from "src/conpath/constants/OrganizationUserState";
import "./UsersSettings.scss";
import Colors from "src/conpath/constants/Colors";
import OrganizationUserModel from "src/conpath/models/OrganizationUserModel";

//utils
import { formatDate } from "src/utils/dateUtils";

//types & interfaces
import {
  CreateOrganizationUsersRequest,
  OrganizationUserDocumentFields,
  UpdateOrganizationUserForm,
} from "src/conpath/interfaces/User";
import { isEmailValid } from "src/conpath/helpers/validations/validations";

const organizationRoleSelection: SelectionOption[] = [
  {
    value: OrganizationRole.owner.toString(),
    label: OrganizationRoleMap[OrganizationRole.owner],
  },
  {
    value: OrganizationRole.member.toString(),
    label: OrganizationRoleMap[OrganizationRole.member],
  },
  {
    value: OrganizationRole.guest.toString(),
    label: OrganizationRoleMap[OrganizationRole.guest],
  },
];


const UsersSettings: React.FC = observer(() => {
  const { userStore, organizationStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;

  const [invitationForm, setInvitationForm] =
    useState<CreateOrganizationUsersRequest>({
      email: "",
      role: OrganizationRole.member,
      teamIds: [],
    });
  const [tempUser, setTempUser] = useState<UpdateOrganizationUserForm>({
    username: "",
    role: OrganizationRole.guest,
  });
  const [invitationList, setInvitationList] = useState<CreateOrganizationUsersRequest[]>([]);
  const [selectedInvitee, setSelectedInvitee] = useState<CreateOrganizationUsersRequest | null>(null);

  const [addingUsersErrorMessages, setAddingUsersErrorMessage] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const [modalErrorMessage, setModalErrorMessage] = useState<string>("");
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState<boolean>(false);
  const [editingUser, setEditingUser] = useState<OrganizationUserModel | null>(null);

  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    selectedOrganization?.subscribeOrganizationUsersCollection();

    return () => {
      selectedOrganization?.unsubscribeOrganizationUsersCollection();
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessage("");
    }

    if (errorMessage) {
      setErrorMessage("");
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    if (addingUsersErrorMessages.length) {
      const _addingUsersErrorMessages = [...addingUsersErrorMessages];
      setErrorMessage(_addingUsersErrorMessages.pop()!);
      setAddingUsersErrorMessage(_addingUsersErrorMessages);
    }
  }, [addingUsersErrorMessages]);

  const getNumberOfCandidates = (value: "joined" | "invited"): number => {
    if (!selectedOrganization) return 0;

    switch (value) {
      case "joined":
        return selectedOrganization.getJoinedUsers().length;
      case "invited":
        return selectedOrganization.getInvitedUsers().length;
      default:
        return 0;
    }
  };

  const organizationTeams = useMemo((): SelectionOption[] => {
    return selectedOrganization ? selectedOrganization.teams.map((team): SelectionOption => {
      return {
        value: team.id,
        label: team.name
      };
    }) : [];
  }, [selectedOrganization]);

  const organizationTeamMap = useMemo((): { [key: string]: string } => {
    return organizationTeams.reduce((prev, team) => ({ ...prev, [team.value]: team.label }), {})
  }, [organizationTeams]);

  const onChangeSelectionForInvitee = useCallback((field: "role" | "teamId", value: string, index: number) => {

    const _invitationList = [...invitationList];
    _invitationList[index] = {
      ..._invitationList[index],
      [field]: field === "role" ? Number(value) as OrganizationRole : value
    };
    setInvitationList(_invitationList)

  }, [invitationList]);

  const onChangeSelection = useCallback((value: string) => {
    setInvitationForm((prev) => ({
      ...prev,
      role: Number(value) as OrganizationRole,
    }));
  },
    [setInvitationForm],
  );

  const onChangeEmailField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInvitationForm((prev) => ({
      ...prev,
      email: value.replace(/\s/g, "") // 入力されたEmailからspaceの除去
    }));
    setErrorMessage("");
  };

  const onAddButtonPressed = useCallback(() => {
    const invitee = invitationList.find(
      (i) => i.email === invitationForm.email,
    );
    if (invitee) {
      setErrorMessage("そのEmailアドレスはすでにリストに存在します。");
      return;
    }

    setInvitationList((prev) => [...prev, invitationForm]);
    setInvitationForm({ email: "", role: OrganizationRole.member, teamIds: [] });
  }, [invitationForm, invitationList, setInvitationList]);

  const onRemoveSelectedEmailFromInvitationList = (email: string) => {
    setInvitationList((prev) => prev.filter((i) => i.email !== email));
  };

  /**
   * ユーザーの組織からの退会処理
   */
  const onRemoveButtonPressed = useCallback(async (user: OrganizationUserModel) => {
    if (!loginUser?.isOrganizationOwner()) return;

    const removeUserResult = await user.removeUser();
    if (removeUserResult.error) {
      setErrorMessage(removeUserResult.error);
    } else {
      setSuccessMessage("ユーザーを退会しました。");
    }
  },
    [loginUser],
  );

  /**
   * ユーザーの一括招待処理
   */
  const onSendInvitationButtonPressed = useCallback(async () => {
    if (!selectedOrganization) return;

    const currentPlan = selectedOrganization.getCurrentPlan();
    const addUsers = selectedOrganization.addUsers;
    const joinedAndInvitedUsers = selectedOrganization.getJoinedAndInvitedUsers().length;

    // メンバー、招待中、招待予定の合計が、プランの上限を超えていたら招待できないように
    if ((joinedAndInvitedUsers + invitationList.length) > (currentPlan!.userLimit + addUsers)) {
      setAddingUsersErrorMessage(["プランの上限を超えているため招待することができませんでした。"]);
      return;
    }

    const addUsersResult = await selectedOrganization.inviteUsers(invitationList);
    if (addUsersResult.error.length) {
      setAddingUsersErrorMessage(addUsersResult.error);
    } else {
      setSuccessMessage("ユーザーを招待しました。");
    }
    setInvitationList([]);
  }, [invitationList, selectedOrganization]);

  /**
   * ユーザーの再招待処理
   */
  const onResendInvitationButtonPressed = useCallback(async (user: OrganizationUserModel) => {
    if (!loginUser?.isOrganizationOwner()) return;

    const resendInvitationResult = await user.resendInvitation();
    if (resendInvitationResult.error) {
      setErrorMessage(resendInvitationResult.error);
    } else {
      setSuccessMessage("ユーザーの再招待をリクエストしました。");
    }
  },
    [loginUser],
  );

  /**
   * ユーザー招待のキャンセル処理
   */
  const onCancelInvitationButtonPressed = useCallback(async (user: OrganizationUserModel) => {
    if (!loginUser?.isOrganizationOwner()) return;

    const success = await user.cancelInvitation(OrganizationUserState.invitationCanceled);
    if (!success) {
      setErrorMessage("ユーザーの招待のキャンセルに失敗しました。");
    } else {
      setSuccessMessage("ユーザーの招待をキャンセルしました。");
    }
  },
    [loginUser],
  );

  /**
   * 変更モダルに選択されたユーザーを表示する処理
   */
  const onEditUser = useCallback((user: OrganizationUserModel) => {
    setEditingUser(user);
    setTempUser({
      username: user.username,
      role: user.role,
    });
    setIsEditUserModalOpen(true);
  }, [setEditingUser, setTempUser, setIsEditUserModalOpen]);

  function closeUpdateModal() {
    setModalErrorMessage("");
    setIsEditUserModalOpen(false);
  }

  const onChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name) {
      setTempUser((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }, [setTempUser]);

  /**
   * ユーザー情報更新処理
   */
  const onSubmitChangingUser = useCallback(async () => {
    if (!loginUser?.isOrganizationOwner() || !editingUser) return;

    const success = await editingUser.updateUser(tempUser);
    if (!success) {
      setModalErrorMessage("ユーザー情報の更新に失敗しました。");
      return;
    }

    setSuccessMessage("ユーザー情報を変更しました。");
    closeUpdateModal();
  }, [tempUser, editingUser, loginUser, setModalErrorMessage, setSuccessMessage, setEditingUser]);

  const selectedInviteeTeamIds = useMemo(() => {
    return selectedInvitee ? organizationTeams.filter((team) => selectedInvitee.teamIds.includes(team.value)) : []
  }, [organizationTeams, selectedInvitee]);

  const onChangeInviteesTeamSelection = useCallback((value: MultiValue<SelectionOption>) => {
    if (selectedInvitee) {
      const _selectedInvitee = { ...selectedInvitee };
      _selectedInvitee["teamIds"] = value.map((v) => v.value);
      setSelectedInvitee(_selectedInvitee);
    }
  }, [selectedInvitee]);

  const onUpdateSelectedInvitee = useCallback(() => {
    if (selectedInvitee) {
      const _invitationList = [...invitationList]
      const index = invitationList.findIndex((invitee) => invitee.email === selectedInvitee.email);
      _invitationList[index] = { ...selectedInvitee };
      setInvitationList(_invitationList);
      setSelectedInvitee(null);
    }
  }, [invitationList, selectedInvitee]);

  return (
    <>
      <Transition appear show={isEditUserModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setEditingUser(null);
            setIsEditUserModalOpen(false);
          }}
        >
          {/* background */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {editingUser ? `${editingUser?.email} \tの編集` : ""}
                  </Dialog.Title>
                  <div className="modal-form-wrapper">
                    {editingUser?.isJoined() &&
                      <div className="mb-4">
                        <p className="text-[14px]">
                          ユーザー名
                        </p>
                        <input
                          type="text"
                          name={OrganizationUserDocumentFields.username}
                          className="input rounded-[4px]"
                          value={tempUser.username}
                          onChange={onChangeInput}
                        />
                      </div>
                    }
                    <div className="v-stack">
                      <div className="mb-4">
                        <p className="text-[14px]">
                          権限
                        </p>
                        <Selection
                          id="role-selection"
                          value={tempUser.role.toString()}
                          options={organizationRoleSelection}
                          onChange={(value: string) => {
                            setTempUser((prev) => ({
                              ...prev,
                              role: Number(value) as OrganizationRole,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    {modalErrorMessage && (
                      <span className="text-sm text-red-500 mt-[2px]">{modalErrorMessage}</span>
                    )}
                    <div className="account-setting--buttonRow p-0 mt-8">
                      <button
                        onClick={closeUpdateModal}
                        className="button solid"
                      >
                        キャンセル
                      </button>
                      <button
                        onClick={(onSubmitChangingUser)}
                        className="button main"
                      >
                        更新
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition >

      {/* 招待するユーザーのチーム選択ポップアップ */}
      <Transition appear show={selectedInvitee !== null} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setSelectedInvitee(null)}
        >
          {/* background */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {selectedInvitee ? `${selectedInvitee.email} \tの編集` : ""}
                  </Dialog.Title>
                  <div className="modal-form-wrapper">
                    <div className="v-stack">
                      <div className="mb-[16px]">
                        <p className="text-[14px] mb-[4px]">
                          チーム選択
                        </p>
                        <Select
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              fontSize: "13px",
                              fontWeight: "normal",
                              padding: 4,
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              minHeight: '32px',
                              maxHeight: '128px',
                              height: 'auto',
                              boxShadow: state.isFocused ? "0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 inset!important" : "none",
                              border: "1px solid #C9C9C9",
                              '&:hover': {
                                borderColor: "#C9C9C9",
                              }
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              minHeight: '32px',
                              maxHeight: '128px',
                              height: 'auto',
                              padding: '0 2px'
                            }),
                            indicatorsContainer: (provided, state) => ({
                              ...provided,
                              minHeight: '32px',
                              maxHeight: '128px',
                              height: 'auto',
                            }),
                            singleValue: base => ({
                              ...base,
                              fontSize: "13px",
                              fontWeight: "normal",
                            }),
                            input: base => ({
                              ...base,
                              fontSize: "13px",
                            }),
                            placeholder: base => ({
                              ...base,
                              color: "#9CA3AF",
                            }),
                          }}
                          isMulti
                          placeholder={"チーム選択"}
                          isClearable={true}
                          value={selectedInviteeTeamIds}
                          isSearchable={true}
                          options={organizationTeams}
                          maxMenuHeight={100}
                          onChange={onChangeInviteesTeamSelection}
                        />
                      </div>
                    </div>
                    {modalErrorMessage && (
                      <span className="text-sm text-red-500 mt-[2px]">{modalErrorMessage}</span>
                    )}
                    <div className="account-setting--buttonRow p-0 mt-8">
                      <button
                        onClick={() => setSelectedInvitee(null)}
                        className="button solid"
                      >
                        キャンセル
                      </button>
                      <button
                        onClick={onUpdateSelectedInvitee}
                        className="button main"
                      >
                        更新
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <SettingsLayout
        user={loginUser}
        errorMessage={errorMessage}
        successMessage={successMessage}
        headerTitle="ユーザー管理"
        currentPath={Paths.users}
      >
        <div className="setting-body">
          {loginUser?.isOrganizationOwner() && (
            <div className="section">
              <div className="section-header">
                <h4>メンバーを招待する</h4>
              </div>
              <div className="form__wrapper">
                <div className="form-container">
                  <div className="selection-wrapper">
                    <p>メールアドレス</p>
                    <input
                      value={invitationForm.email}
                      onChange={onChangeEmailField}
                      className="input email-box"
                    />
                  </div>
                  {/* 権限を選択 */}
                  <div className="selection-wrapper">
                    <p>権限</p>
                    <Selection
                      id="role-selection"
                      value={invitationForm.role.toString()}
                      options={organizationRoleSelection}
                      onChange={onChangeSelection}
                      height={"40px"}
                    />
                  </div>
                  <div className="add-button-wrapper">
                    <Button
                      style={{ height: '40px' }}
                      onClick={onAddButtonPressed}
                      disabled={!isEmailValid(invitationForm.email.trim())}
                      theme="main"
                    >
                      追加
                    </Button>
                  </div>
                </div>
                {invitationList.length > 0 && (
                  <ul className="invitation-list-wrapper">
                    {invitationList.map((invitee, index) => {
                      return (
                        <li key={invitee.email}>
                          <p>{invitee.email}</p>
                          <Selection
                            inline
                            id="role-selection"
                            value={invitee.role.toString()}
                            options={organizationRoleSelection}
                            onChange={(value) => {
                              onChangeSelectionForInvitee("role", value, index);
                            }}
                            height={"32px"}
                          />
                          <label>
                            {!invitee.teamIds.length ?
                              "無所属"
                              :
                              `${organizationTeamMap[invitee.teamIds[0]]} ${invitee.teamIds.length > 1 ? `+ ${invitee.teamIds.length - 1}チーム` : ""}`
                            }
                          </label>
                          <Menu
                            as="div"
                            className="relative flex items-center text-left"
                          >
                            <Menu.Button>
                              <PiDotsThreeCircleLight className="w-[24px] h-[24px]" />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className={itemsClassName}>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${active
                                        ? "bg-[#57AEDF] text-white"
                                        : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                      onClick={() => {
                                        setSelectedInvitee(invitee);
                                      }}
                                    >
                                      チームを選択する
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${active
                                        ? "bg-[#57AEDF] text-white"
                                        : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                      onClick={() => {
                                        onRemoveSelectedEmailFromInvitationList(
                                          invitee.email,
                                        )
                                      }}
                                    >
                                      消去する
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {!_.isEmpty(invitationList) && (
                  <div className="button-wrapper">
                    {selectedOrganization?.isNetworkProcessing ? (
                      <div style={{ marginRight: 48 }}>
                        <RotatingLines
                          strokeColor={Colors.primary}
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="32"
                          visible={true}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={onSendInvitationButtonPressed}
                        disabled={invitationList.length === 0}
                        theme="secondary"
                        style={{ width: "130px" }}
                      >
                        招待を送る
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="section member-section">
            <div className="section-header">
              <h4>メンバー一覧</h4>
            </div>
            <div className="section member-list-section">
              <div className="search-bar-wrapper">
                <SearchBar
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onEnterKeyPressed={() => null}
                  placeholder="ユーザー検索"
                />
              </div>
              {selectedOrganization && loginUser ? (
                <Tab.Group>
                  <Tab.List className="tab-list">
                    <Tab className="tab">
                      {({ selected }) => (
                        <div
                          className={selected ? "button selected" : "button"}
                        >
                          <span>メンバー</span>
                          <p>{`${getNumberOfCandidates("joined")}`}</p>
                        </div>
                      )}
                    </Tab>
                    <Tab className="tab">
                      {({ selected }) => (
                        <div
                          className={selected ? "button selected" : "button"}
                        >
                          <span>招待中</span>
                          <p>{`${getNumberOfCandidates("invited")}`}</p>
                        </div>
                      )}
                    </Tab>
                  </Tab.List>
                  <Tab.Panels className="tab-panels">
                    <Tab.Panel className="tab-panel">
                      <JoinedUserList
                        loginUserId={loginUser.id}
                        users={selectedOrganization.getJoinedUsers()}
                        isOrganizationOwner={loginUser.isOrganizationOwner()}
                        onEditUser={onEditUser}
                        onRemoveButtonPressed={onRemoveButtonPressed}
                        searchText={searchText}
                      />
                    </Tab.Panel>
                    <Tab.Panel className="tab-panel">
                      <InvitingUserList
                        users={selectedOrganization.getInvitedUsers()}
                        isOrganizationOwner={loginUser.isOrganizationOwner()}
                        onEditUser={onEditUser}
                        onCancelInvitationButtonPressed={
                          onCancelInvitationButtonPressed
                        }
                        onResendInvitationButtonPressed={
                          onResendInvitationButtonPressed
                        }
                        searchText={searchText}
                      />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              ) : (
                <div className="loader-wrapper">
                  <RotatingLines
                    strokeColor={Colors.primary}
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="42"
                    visible={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </SettingsLayout>
    </>
  );
});

const itemsClassName = "absolute top-[-8px] right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10";
interface JoinedUserListProps {
  loginUserId: string;
  users: OrganizationUserModel[];
  isOrganizationOwner: boolean;
  onEditUser: (user: OrganizationUserModel) => void;
  onRemoveButtonPressed: (user: OrganizationUserModel) => void;
  searchText: string;
};

const JoinedUserList: React.FC<JoinedUserListProps> = React.memo(
  (props: JoinedUserListProps) => {
    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="th username">ユーザー名</th>
              <th className="th email">メールアドレス</th>
              <th className="th role">権限</th>
              <th className="th state">参加日</th>
              <th className="th edit"></th>
            </tr>
          </thead>
          <tbody>
            {props
              .users
              .filter((v) => !_.isEmpty(props.searchText)
                ? v.username.toLowerCase().includes(props.searchText.toLowerCase())
                : true)
              .map((user, index) => {
                if (user.id === props.loginUserId) {
                  return (
                    <tr key={user.email} className={"td dim"}>
                      <td className="td username">{user.username}</td>
                      <td className="td email">{user.email}</td>
                      <td className="td role">
                        {OrganizationRoleMap[user.role]}
                      </td>
                      <td className="td state">{formatDate(user.joinedAt)}</td>
                      <td className="td star">
                        <IoStar />
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr
                    key={user.email}
                    className={index % 2 === 0 ? "td even" : "td"}
                  >
                    <td className="td username">{user.username}</td>
                    <td className="td email">{user.email}</td>
                    <td className="td role">{OrganizationRoleMap[user.role]}</td>
                    <td className="td state">{formatDate(user.joinedAt!)}</td>
                    <td className="td edit">
                      {props.isOrganizationOwner && (
                        <Menu
                          as="div"
                          className="relative flex items-center text-left"
                        >
                          <Menu.Button>
                            <PiDotsThreeCircleLight />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className={itemsClassName}>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active
                                      ? "bg-[#57AEDF] text-white"
                                      : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={() => props.onEditUser(user)}
                                  >
                                    ユーザー情報の編集
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active
                                      ? "bg-[#57AEDF] text-white"
                                      : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={() =>
                                      props.onRemoveButtonPressed(user)
                                    }
                                  >
                                    退会させる
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  },
);

interface InvitingUserListProps {
  users: OrganizationUserModel[];
  isOrganizationOwner: boolean;
  onEditUser: (user: OrganizationUserModel) => void;
  onResendInvitationButtonPressed: (user: OrganizationUserModel) => void;
  onCancelInvitationButtonPressed: (user: OrganizationUserModel) => void;
  searchText: string;
}

const InvitingUserList: React.FC<InvitingUserListProps> = React.memo(
  (props: InvitingUserListProps) => {
    return (
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th className="th email">メールアドレス</th>
              <th className="th role">権限</th>
              <th className="th state">状態</th>
              <th className="th edit"></th>
            </tr>
          </thead>
          <tbody>
            {props
              .users
              .filter((v) => !_.isEmpty(props.searchText)
                ? v.username.toLowerCase().includes(props.searchText.toLowerCase())
                : true)
              .map((user, index) => {
                return (
                  <tr
                    key={user.email}
                    className={index % 2 === 0 ? "td even" : "td"}
                  >
                    <td className="td email">{user.email}</td>
                    <td className="td role">{OrganizationRoleMap[user.role]}</td>
                    <td className="td state">{OrganizationUserStateMap[user.state]}</td>
                    <td className="td edit">
                      {props.isOrganizationOwner && (
                        <Menu as="div" className="relative flex items-center text-left z-10">
                          <Menu.Button className="rounded-full px-2 py-2 text-sm font-medium">
                            <PiDotsThreeCircleLight />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className={itemsClassName}>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active ? "bg-[#57AEDF] text-white" : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={() => props.onEditUser(user)}
                                  >
                                    権限を変更する
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active ? "bg-[#57AEDF] text-white" : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={() => props.onResendInvitationButtonPressed(user)}
                                  >
                                    再度招待する
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    className={`${active ? "bg-[#57AEDF] text-white" : "text-gray-900"
                                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    onClick={() => props.onCancelInvitationButtonPressed(user)}
                                  >
                                    招待をキャンセルする
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  },
);

export default UsersSettings;
