import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Flip, ToastContainer, toast } from 'react-toastify';
import RcGantt, { jaJP } from "src/conpath/components/gantt";

//mobx
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";

//styles
import "./Resources.scss";

//constants
import { TOAST_DEFAULT_DURATION } from "src/conpath/constants/Toast";
import { APP_NAME } from "src/excalidraw/constants";

//components
import { DefaultRecordType, Gantt } from "src/conpath/components/gantt/types";
import SearchListBox, { SearchListOption } from "src/conpath/components/SearchListBox";
import { Button } from "src/conpath/components/Button";
import { ResourcesFilterTags } from "src/conpath/interfaces/ResourcesFilter";
import SearchTagsInput from "src/conpath/components/SearchTagsInput";
import Checkbox from "src/conpath/components/CheckBox";
import Header from "src/conpath/components/layouts/Header";

//models
import TaskModel from "src/conpath/models/TaskModel";
import { OrganizationUserState } from "src/conpath/constants/OrganizationUserState";
import Sidebar from "src/conpath/components/sidebar/Sidebar";

const Resources: React.FC = observer(() => {

  const { organizationStore, userStore } = useStore();
  const { loginUser } = userStore;
  const { selectedOrganization } = organizationStore;
  const resourcesFilter = selectedOrganization?.resourcesFilter;

  const [searchText, setSearchText] = useState<string[]>([]);
  const [assignUsersOption, setAssignUsersOption] = useState<SearchListOption[]>([]);
  const [assignResourcesOption, setAssignResourcesOption] = useState<SearchListOption[]>([]);
  const [projectOptions, setProjectOptions] = useState<SearchListOption[]>([]);
  // const [milestoneOptions, setMilestoneOptions] = useState<SearchListOption[]>([]);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);

  useEffect(() => {
    if (selectedOrganization?.resourcesDataFetchError) {
      toast.error(selectedOrganization.resourcesDataFetchError);
      selectedOrganization.removeResourcesError();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (loginUser && selectedOrganization) {
      selectedOrganization?.subscribeResoucesData(loginUser, selectedOrganization);
    }

    return () => {
      selectedOrganization?.unsubscribeResoucesData();
    }
  }, [selectedOrganization, loginUser]);

  useEffect(() => {
    const options =
      selectedOrganization?.users?.filter((user) => user.state === OrganizationUserState.joined)
        .map((user) =>
        ({ value: user.id, label: user.username } as SearchListOption
        )) || [];

    setAssignUsersOption(options);
  }, [selectedOrganization?.users]);

  useEffect(() => {
    const options =
      selectedOrganization?.resources?.filter((resource) => resource.isActive && !resource.isDeleted)
        .map((resource) =>
        ({ value: resource.id, label: resource.name } as SearchListOption
        )) || [];

    setAssignResourcesOption(options);
  }, [selectedOrganization?.users]);


  useEffect(() => {
    const options = selectedOrganization?.getActiveProjects()
      .map((project) =>
      ({ value: project.id, label: project.name } as SearchListOption
      )) || [];

    setProjectOptions(options);
  }, [selectedOrganization?.projects]);

  // useEffect(() => {
  //   const options: SearchListOption[] = [];

  //   selectedOrganization?.projectTasks?.forEach((item) => {
  //     item.children?.forEach((child) => {
  //       if (child.isMilestone) {
  //         const option: SearchListOption = { value: child.id, label: child.name };
  //         options.push(option);
  //       }
  //     })
  //   });

  //   setMilestoneOptions(options);
  // }, [selectedOrganization?.milestones]);

  useEffect(() => {
    const _searchText = [];
    if (resourcesFilter?.searchAssignUser) {
      _searchText.push(`${ResourcesFilterTags.searchAssignUser}:${assignUsersOption.find((key) => key.value === resourcesFilter?.searchAssignUser)?.label}`);
    }
    if (resourcesFilter?.searchAssignResource) {
      _searchText.push(`${ResourcesFilterTags.searchAssignResource}:${assignResourcesOption.find((key) => key.value === resourcesFilter?.searchAssignResource)?.label}`);
    }
    if (resourcesFilter?.searchProject) {
      _searchText.push(`${ResourcesFilterTags.searchProject}:${projectOptions.find((key) => key.value === resourcesFilter?.searchProject)?.label}`);
    }
    // if (resourcesFilter?.searchMilestone) {
    //   _searchText.push(`${ResourcesFilterTags.searchMilestone}:${milestoneOptions.find((key) => key.value === resourcesFilter?.searchMilestone)?.label}`);
    // }
    if (resourcesFilter?.searchText) {
      _searchText.push(`${ResourcesFilterTags.searchText}:${resourcesFilter?.searchText}`);
    }

    setSearchText(_searchText);
  }, [resourcesFilter]);

  const onChangeText = useCallback((tags: string[]) => {
    if (tags.length > searchText.length) {
      const currentValue = resourcesFilter?.searchText;
      if (currentValue !== tags[tags.length - 1]) {
        selectedOrganization?.setResourcesFilter({ "searchText": tags[tags.length - 1] })
      }
    } else {
      if (
        resourcesFilter?.searchText &&
        tags.filter((v) => v.includes(ResourcesFilterTags.searchText)).length === 0
      ) {
        selectedOrganization?.setResourcesFilter({ "searchText": null })
      }

      if (
        resourcesFilter?.searchAssignUser &&
        tags.filter((v) => v.includes(ResourcesFilterTags.searchAssignUser)).length === 0
      ) {
        selectedOrganization?.setResourcesFilter({ "searchAssignUser": null })
      }

      if (
        resourcesFilter?.searchAssignResource &&
        tags.filter((v) => v.includes(ResourcesFilterTags.searchAssignResource)).length === 0
      ) {
        selectedOrganization?.setResourcesFilter({ "searchAssignResource": null })
      }

      if (
        resourcesFilter?.searchProject &&
        tags.filter((v) => v.includes(ResourcesFilterTags.searchProject)).length === 0
      ) {
        selectedOrganization?.setResourcesFilter({ "searchProject": null })
      }

      // if (
      //   resourcesFilter?.searchMilestone &&
      //   tags.filter((v) => v.includes(ResourcesFilterTags.searchMilestone)).length === 0
      // ) {
      //   selectedOrganization?.setResourcesFilter({ "searchMilestone": null })
      // }
    }
  }, [resourcesFilter, searchText]);

  const updateTaskProperties = async () => {
    if (selectedTask) {
      if (selectedTask.isUpdatedChecklists) {
        await selectedTask.saveChecklist();
      }
    }
  }

  /**
   * TaskBarクリック時にそのタスクに関する情報を表示
   */
  const onBarClick = useCallback(async (record: Gantt.Record<DefaultRecordType>) => {
    if (!record.isTask || !selectedOrganization) {
      onCloseSidebar();
      return;
    };

    await updateTaskProperties();
    const task = selectedOrganization.getTaskById(record.id.split(",")[0]) || null;
    setSelectedTask(task);
  }, [selectedTask, selectedOrganization]);

  const onCloseSidebar = async () => {
    if (!selectedOrganization) return;

    await updateTaskProperties();
    setSelectedTask(null);
  }

  const onExpand = useCallback(async (record: Gantt.Record<DefaultRecordType>, collapsed: boolean) => {
    if (record.isTask) {
      await onBarClick(record);
    } else {
      selectedOrganization?.setRowCollapse(record, collapsed);
    }
  }, [selectedOrganization, onBarClick]);

  const onExpandAll = useCallback((collapsed: boolean) => {
    selectedOrganization?.setAllRowsCollapse(collapsed);
  }, [selectedOrganization]);

  return (
    <>
      <Helmet>
        <title>{`リソース - ${APP_NAME}`}</title>
      </Helmet>
      <Header />
      <div
        className="resources-container">
        <ToastContainer
          position="top-center"
          transition={Flip}
          autoClose={TOAST_DEFAULT_DURATION}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="resources-nav">
          {/* <SearchListBox
          title="担当者"
          options={milestoneOptions}
          selected={resourcesFilter?.searchMilestone || ""}
          onChange={(selectedValue: string) => {
            const currentValue = resourcesFilter?.searchMilestone;
            selectedOrganization?.setResourcesFilter({
              "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
            })
          }}
          customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        />
        <SearchListBox
          title="業者"
          options={milestoneOptions}
          selected={resourcesFilter?.searchMilestone || ""}
          onChange={(selectedValue: string) => {
            const currentValue = resourcesFilter?.searchMilestone;
            selectedOrganization?.setResourcesFilter({
              "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
            })
          }}
          customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        />
        <SearchListBox
          title="部署"
          options={milestoneOptions}
          selected={resourcesFilter?.searchMilestone || ""}
          onChange={(selectedValue: string) => {
            const currentValue = resourcesFilter?.searchMilestone;
            selectedOrganization?.setResourcesFilter({
              "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
            })
          }}
          customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        /> */}
          <SearchListBox
            title="担当者"
            options={assignUsersOption}
            selected={resourcesFilter?.searchAssignUser || ""}
            onChange={(selectedValue: string) => {
              const currentValue = resourcesFilter?.searchAssignUser;
              selectedOrganization?.setResourcesFilter({
                "searchAssignUser": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            disabled={!assignUsersOption.length}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          />
          <SearchListBox
            title="リソース"
            options={assignResourcesOption}
            selected={resourcesFilter?.searchAssignResource || ""}
            onChange={(selectedValue: string) => {
              const currentValue = resourcesFilter?.searchAssignResource;
              selectedOrganization?.setResourcesFilter({
                "searchAssignResource": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            disabled={!assignResourcesOption.length}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          />
          <SearchListBox
            title="プロジェクト"
            options={projectOptions}
            selected={resourcesFilter?.searchProject || ""}
            onChange={(selectedValue: string) => {
              const currentValue = resourcesFilter?.searchProject;
              selectedOrganization?.setResourcesFilter({
                "searchProject": currentValue !== selectedValue ? selectedValue : null,
              })
            }}
            disabled={!projectOptions.length}
            customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
          />
          {/* <SearchListBox
          title="マイルストーン"
          options={milestoneOptions}
          selected={resourcesFilter?.searchMilestone || ""}
          onChange={(selectedValue: string) => {
            const currentValue = resourcesFilter?.searchMilestone;
            selectedOrganization?.setResourcesFilter({
              "searchMilestone": currentValue !== selectedValue ? selectedValue : null,
            })
          }}
          disabled={!milestoneOptions.length}
          customClass="cursor-pointer bg-[#F5F6F7] shadow-none relative w-full cursor-default rounded-lg py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
        /> */}
          <div className="flex flex-row items-center mr-[30px] ml-[20px]">
            <Checkbox
              props={{
                onClick: () => selectedOrganization?.setResourcesFilter({ "showOnlyTaskExists": !selectedOrganization?.resourcesFilter.showOnlyTaskExists }),
                checked: selectedOrganization?.resourcesFilter.showOnlyTaskExists,
                className: "task-checklist__item__checkbox",
                sx: {
                  color: "#DDDDDD",
                  backgroundImage: "linear-gradient(to right, white, white)",
                  borderRadius: 0,
                  height: 18,
                  width: 18,
                },
              }}
            />
            <label className="text-left sm:text-sm relative ml-[7px]">空白の行を非表示</label>
          </div>
          <div className="flex flex-row items-center mr-[30px]">
            <Checkbox
              props={{
                onClick: () => selectedOrganization?.setResourcesFilter({ "showOnlyOverlapTasks": !selectedOrganization?.resourcesFilter.showOnlyOverlapTasks }),
                checked: selectedOrganization?.resourcesFilter.showOnlyOverlapTasks,
                className: "task-checklist__item__checkbox",
                sx: {
                  color: "#DDDDDD",
                  backgroundImage: "linear-gradient(to right, white, white)",
                  borderRadius: 0,
                  height: 18,
                  width: 18,
                },
              }}
            />
            <label className="text-left sm:text-sm relative ml-[7px]">重複タスク</label>
          </div>
          <div className="flex flex-row items-center mr-[40px]">
            <Checkbox
              props={{
                onClick: () => selectedOrganization?.setResourcesFilter({ "showOnlyBlankTasks": !selectedOrganization?.resourcesFilter.showOnlyBlankTasks }),
                checked: selectedOrganization?.resourcesFilter.showOnlyBlankTasks,
                className: "task-checklist__item__checkbox",
                sx: {
                  color: "#DDDDDD",
                  backgroundImage: "linear-gradient(to right, white, white)",
                  borderRadius: 0,
                  height: 18,
                  width: 18,
                },
              }}
            />
            <label className="text-left sm:text-sm relative ml-[7px]">余白</label>
          </div>

          <div className="search-wrapper search-bar mr-5">
            {/* <SearchBar
            value={searchText}
            onChange={onChangeText}
            onEnterKeyPressed={() => {
              selectedOrganization?.setResourcesFilter({ "searchText": searchText })
            }}
            placeholder=""
            style={{ border: "1px solid #F5F6F7", borderRadius: "5px", height: "45px" }}
          /> */}
            <SearchTagsInput
              tags={searchText}
              onChangeTags={(newTags) => { onChangeText(newTags) }}
              className="search-box"
            />
          </div>
          <Button theme="solid" style={{ fontSize: "14px" }} onClick={() => {
            selectedOrganization?.resetResourcesFilters()
            setSearchText([]);
          }}>
            クリア
          </Button>
        </div>
        <div
          className="gantt-chart-container">
          <RcGantt
            data={
              selectedOrganization ?
                selectedOrganization.projectTasks : []
            }
            dependencies={
              selectedOrganization ?
                selectedOrganization.projectDependencies : []
            }
            locale={jaJP}
            columns={[
              {
                name: "name",
                label: "",
                minWidth: 200,
              },
            ]}
            onExpand={onExpand}
            onExpandAll={onExpandAll}
            onUpdate={async () => {
              return false;
            }}
            onRow={{
              onClick: onBarClick,
            }}
            onBarClick={onBarClick}
            storeName="ResourcesStore"
            chartType={Gantt.ChartType.Resources}
          />
          {selectedTask && (
            <Sidebar
              task={selectedTask}
              loginUser={loginUser}
              organization={selectedOrganization}
              onCloseSidebar={onCloseSidebar}
              isResources={true}
            />
          )}
        </div>
      </div>
    </>
  );
});

export default Resources;
