import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

import "./SelectLayer.scss";

import ProjectModel from "src/conpath/models/ProjectModel";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck, FaMinus } from "react-icons/fa";
import { t } from "src/excalidraw/i18n";
import { ExcalidrawElement } from "../../element/types";

interface SelectingLayer {
  id: string;
  index: number;
  name: string;
}
const hasLayerCheck = ({
  list,
  targetLayer,
}: {
  list: SelectingLayer[];
  targetLayer: SelectingLayer;
}): boolean => {
  return list.some((layer) => layer.id === targetLayer.id);
};

const SelectLayer = observer(
  (props: {
    elements: ExcalidrawElement[];
    selectedValue: number;
    project: ProjectModel | null;
    onChange: (value: number) => void;
  }) => {
    const { elements, selectedValue, project, onChange } = props;

    const [assignedLayers, setAssignedLayers] = useState<SelectingLayer[]>([]);
    const [indeterminateLayers, setIndeterminateLayers] = useState<SelectingLayer[]>([]);
    const [projectLayers, setProjectLayers] = useState<SelectingLayer[]>(
      [],
    );

    // 全レイヤー
    useEffect(() => {
      const layers = project?.layers.filter((layer) => !layer.isDeleted)
        .map((layer) => {
          return {
            id: layer.id,
            index: layer.index,
            name: layer.name,
          } as SelectingLayer;
        }) || [];

      setProjectLayers(layers);
    }, [project]);

    useEffect(() => {
      const isAssigned = (layer: SelectingLayer): boolean => {
        return elements.every((task) => task.layer === layer.index);
      };
      setAssignedLayers(projectLayers.filter((layer) => isAssigned(layer)));

      const isIndeterminate = (layer: SelectingLayer): boolean => {
        return elements.some((task) => task.layer === layer.index);
      };
      setIndeterminateLayers(projectLayers.filter((layer) => isIndeterminate(layer)));
    }, [projectLayers, elements]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionLayerList
            selectedValue={selectedValue}
            projectLayers={projectLayers}
            assignedLayers={assignedLayers}
            indeterminateLayers={indeterminateLayers}
            elements={elements}
            onChange={onChange}
          />
        </div>
      </>
    );
  },
);

const SelectionLayerList = (props: {
  selectedValue: number;
  elements: ExcalidrawElement[];
  projectLayers: SelectingLayer[];
  assignedLayers: SelectingLayer[];
  indeterminateLayers: SelectingLayer[];
  onChange: (value: number) => void;
}) => {
  const { selectedValue, projectLayers, assignedLayers, indeterminateLayers, elements, onChange } = props;
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);

  const List = React.useMemo(() => {
    const onSelectLayerButtonPressed = () => {
      setIsOpenAssignList(!isOpenAssignList);
    };

    if (elements.length === 0) {
      return (
        <label className="task-content__label !mb-0">
          {t("labels.editTask.layer")}
        </label>
      )
    }

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSelectLayerButtonPressed();
          }
          return (
            <>
              <Menu.Button
                className={classNames("flex items-center group/assign-button no-select", /* テキスト選択を無効にするために no-select クラスを追加 */
                  projectLayers.length > 0
                    ? "cursor-pointer"
                    : ""
                )}
                as="div"
                disabled={projectLayers.length === 0}
                onClick={() => {
                  onSelectLayerButtonPressed();
                }}
              >
                <label className={classNames("text-[14px] task-content__label !mb-0",
                  projectLayers.length > 0
                    ? "cursor-pointer group-hover/assign-button:text-primary-color"
                    : ""
                )}>
                  {selectedValue === Number.MAX_VALUE
                    ? "複数のレイヤー"
                    : projectLayers.find(
                        (layer) => layer.index === selectedValue,
                      )?.name || "レイヤー1"}
                </label>
                {projectLayers.length > 0 &&
                  <GoGear
                    className="ml-2 text-black cursor-pointer group-hover/assign-button:text-primary-color"
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-[1] absolute left-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {projectLayers.length > 0 && (
                    <>
                      <div className="p-2 text-xs">
                        {t("labels.editTask.layer")}
                      </div>
                      {projectLayers.map((layer) => {
                        const isAssigned = hasLayerCheck({
                          list: assignedLayers,
                          targetLayer: layer,
                        });
                        const isIndeterminate = hasLayerCheck({
                          list: indeterminateLayers,
                          targetLayer: layer,
                        });
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-layer flex items-center hover:bg-primary-color transition ease-out duration-100 no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                            onClick={() => onChange(layer.index)}
                          >
                            {isAssigned ? (
                              <FaCheck
                                className={
                                  "text-black group-hover/select-layer:text-white mr-2"
                                }
                              />
                            ) : (
                              <FaMinus
                                className={`${
                                  isIndeterminate
                                    ? "text-black group-hover/select-layer:text-white"
                                    : "text-transparent"
                                } mr-2`}
                              />
                            )}
                            <span className="text-[14px]  group-hover/select-layer:text-white">
                              {layer.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    projectLayers,
    assignedLayers,
    indeterminateLayers,
  ]);

  return List;
};

export default SelectLayer;
