import {
  ArrowIcon,
  DiamondIcon,
  EllipseIcon,
  EraserIcon,
  FreedrawIcon,
  ImageIcon,
  LineIcon,
  MilestoneIcon, // CHANGED:ADD 2022-12-7 #157
  RectangleIcon,
  SelectionIcon,
  TaskIcon, // CHANGED:ADD 2022-12-1 #231
  TextIcon,
  laserPointerToolIcon,
} from "./components/icons";
import { KEYS } from "./keys";

export const SHAPES = [
  {
    icon: SelectionIcon,
    value: "selection",
    key: KEYS.V,
    numericKey: KEYS["1"],
    fillable: true,
  },
  // CHANGED:ADD 2022-10-24 #56
  {
    icon: TaskIcon,
    value: "task",
    key: null,
    numericKey: KEYS["2"],
    fillable: true,
  },
  // CHANGED:ADD 2022-12-7 #157
  {
    icon: MilestoneIcon,
    value: "milestone",
    key: null,
    numericKey: KEYS["3"],
    fillable: true,
  },
  {
    icon: RectangleIcon,
    value: "rectangle",
    key: KEYS.R,
    numericKey: KEYS["4"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: true,
  },
  {
    icon: DiamondIcon,
    value: "diamond",
    key: KEYS.D,
    numericKey: KEYS["5"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: true,
  },
  {
    icon: EllipseIcon,
    value: "ellipse",
    key: KEYS.O,
    numericKey: KEYS["6"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: true,
  },
  {
    icon: ArrowIcon,
    value: "arrow",
    key: KEYS.A,
    numericKey: KEYS["7"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: true,
  },
  {
    icon: LineIcon,
    value: "line",
    key: KEYS.L,
    numericKey: KEYS["8"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: true,
  },
  {
    icon: FreedrawIcon,
    value: "freedraw",
    key: [KEYS.P, KEYS.X],
    numericKey: KEYS["9"], // CHANGED:UPDATE 2022-12-7 #157
    fillable: false,
  },
  {
    icon: TextIcon,
    value: "text",
    key: KEYS.T,
    numericKey: KEYS["T"], // CHANGED:UPDATE 2023-1-18 #433
    fillable: false,
  },
  {
    icon: ImageIcon,
    value: "image",
    key: null,
    numericKey: KEYS["I"],
    fillable: false,
  },
  {
    icon: laserPointerToolIcon,
    value: "laser",
    key: KEYS.K,
    numericKey: KEYS["K"],
    fillable: false,
  },
  {
    icon: EraserIcon,
    value: "eraser",
    key: null,
    numericKey: KEYS["0"],
    fillable: false,
  },
] as const;

export const findShapeByKey = (key: string) => {
  const shape = SHAPES.find((shape, index) => {
    return (
      (shape.numericKey != null && key === shape.numericKey.toString()) ||
      (shape.key &&
        (typeof shape.key === "string"
          ? shape.key === key
          : (shape.key as readonly string[]).includes(key)))
    );
  });
  return shape?.value || null;
};
