import React, { Fragment, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import "./SelectMultipleTaskAssignUsers.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import { OrganizationRole } from "src/conpath/constants/Role";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck, FaMinus } from "react-icons/fa";
import { t } from "src/excalidraw/i18n";

interface SelectingUser {
  id: string;
  email: string;
  username: string;
  profileImageUrl: string | null;
  isSelected: boolean;
}
const hasUserCheck = ({
  list,
  targetUser,
}: {
  list: SelectingUser[];
  targetUser: SelectingUser;
}): boolean => {
  return list.some((user) => user.id === targetUser.id);
};

const SelectMultipleTaskAssignUsers = observer(
  (props: {
    tasks: TaskModel[];
    organization: OrganizationModel | null;
    projectId: string;
    onSaveAssignUsers: () => void;
  }) => {
    const { tasks, organization, projectId, onSaveAssignUsers } = props;

    const [assignedUsers, setAssignedUsers] = useState<SelectingUser[]>([]);
    const [indeterminateUsers, setIndeterminateUsers] = useState<SelectingUser[]>([]);
    const [projectUsers, setProjectUsers] = useState<SelectingUser[]>(
      [],
    );

    // 全ユーザー
    useEffect(() => {
      const project = organization?.projects.find((project) => project.id === projectId);
      const users = organization?.users.filter((user) =>
        (project?.roles && project.roles[user.id]) ||
        (project?.teams && Object.keys(project.teams).some((key) =>
          organization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
        ))
      ).map((user) => {
        return {
          id: user.id,
          email: user.email,
          username: user.username,
          profileImageUrl: user.profileImageUrl,
          isSelected: false,
        } as SelectingUser;
      }) || [];

      setProjectUsers(users);
    }, [organization]);

    // すでにアサインされたユーザー
    useEffect(() => {
      const isAssigned = (user: SelectingUser): boolean => {
        return tasks.every((task) =>
          task.assignUserIds.some((u) => u === user.id)
        )
      };
      setAssignedUsers(projectUsers.filter((user) => isAssigned(user)));

      const isIndeterminate = (user: SelectingUser): boolean => {
        return tasks.some((task) =>
          task.assignUserIds.some((u) => u === user.id)
        )
      };
      setIndeterminateUsers(projectUsers.filter((user) => isIndeterminate(user)));
    }, [projectUsers, tasks]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionUserList
            projectUsers={projectUsers}
            assignedUsers={assignedUsers}
            indeterminateUsers={indeterminateUsers}
            tasks={tasks}
            onSaveAssignUsers={onSaveAssignUsers}
          />
        </div>
      </>
    );
  },
);

const SelectionUserList = (props: {
  tasks: TaskModel[];
  projectUsers: SelectingUser[];
  assignedUsers: SelectingUser[];
  indeterminateUsers: SelectingUser[];
  onSaveAssignUsers: () => void;
}) => {
  const { projectUsers, assignedUsers, indeterminateUsers, tasks, onSaveAssignUsers } = props;
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);

  // セレクトスクロール量 設定 
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  useEffect(() => {
    const tableWrapper = document.querySelector('.table-wrapper') as HTMLElement;

    if (tableWrapper) {
      setScrollHeight(tableWrapper.offsetHeight - 30);
    }
  }, []);

  const List = React.useMemo(() => {
    const onSave = (targetUser: SelectingUser) => {
      const hasUser = hasUserCheck({ list: assignedUsers, targetUser });
      tasks.forEach((task) => {
        if (!hasUser) {
          task.addUser(targetUser.id);
        } else {
          task.removeUser(targetUser.id);
        }
      });
    }

    const onSelectUserButtonPressed = () => {
      setIsOpenAssignList(!isOpenAssignList);
    };

    if (tasks.length === 0) {
      return (
        <label className="task-content__label !mb-0">
          {t("labels.editTask.assignUser")}
        </label>
      )
    }

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSelectUserButtonPressed();
            onSaveAssignUsers();
          }
          return (
            <>
              <Menu.Button
                className="flex items-center group/assign-button cursor-pointer"
                as="div"
                onClick={() => {
                  onSelectUserButtonPressed();
                }}
              >
                <label className="task-content__label !mb-0 cursor-pointer group-hover/assign-button:text-primary-color">
                  {t("labels.editTask.assignUser")}
                </label>
                <GoGear
                  className="ml-2 cursor-pointer text-black group-hover/assign-button:text-primary-color"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items style={{ maxHeight: scrollHeight, overflow: "scroll" }} className="z-[1] absolute right-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {/* Assigned users */}
                  {projectUsers.length > 0 && (
                    <>
                      <div className="p-2 text-xs">
                        {t("labels.editTask.assignUser")}
                      </div>
                      {projectUsers.map((user) => {
                        const isAssigned = hasUserCheck({
                          list: assignedUsers,
                          targetUser: user,
                        });
                        const isIndeterminate = hasUserCheck({
                          list: indeterminateUsers,
                          targetUser: user,
                        });
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-user flex items-center hover:bg-primary-color transition ease-out duration-100"
                            onClick={() => onSave(user)}
                          >
                            {isAssigned
                              ? <FaCheck
                                className={"text-black group-hover/select-user:text-white mr-2"}
                              />
                              : <FaMinus
                                className={`${isIndeterminate
                                  ? "text-black group-hover/select-user:text-white"
                                  : "text-transparent"
                                  } mr-2`}
                              />
                            }
                            {user.profileImageUrl ? (
                              <img
                                src={user.profileImageUrl}
                                className="w-5 rounded-full object-contain mr-2"
                              />
                            ) : (
                              <div className="w-5 h-5 bg-blue-900 text-white text-center leading-5 rounded-full mr-2">
                                <p>{user.username.charAt(0)}</p>
                              </div>
                            )}
                            <span className="group-hover/select-user:text-white">
                              {user.username}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    projectUsers,
    assignedUsers,
    indeterminateUsers,
  ]);

  return List;
};

export default SelectMultipleTaskAssignUsers;
