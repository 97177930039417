import { ButtonIconSelect } from "../../components/ButtonIconSelect";
// TODO barnabasmolnar/editor-redesign
// TextAlignTopIcon, TextAlignBottomIcon,TextAlignMiddleIcon,
// ArrowHead icons
import {
  AlignTextOffsetBottomIcon,
  AlignTextOffsetCenterIcon,
  AlignTextOffsetLeftIcon,
  AlignTextOffsetRightIcon,
  AlignTextOffsetTopIcon,
  ArrowheadArrowIcon,
  ArrowheadExCircleIcon,
  ArrowheadExDotIcon,
  ArrowheadExDotSmallIcon,
  ArrowheadNoneIcon,
  HorizontalIcon,
  StrokeWidthBaseIcon,
  StrokeWidthBoldIcon,
  StrokeWidthExtraBoldIcon,
  VerticalIcon,
} from "../../components/icons";
import {
  MdOutlineTextRotateVertical,
  MdOutlineTextRotationNone,
} from "react-icons/md";
import { RxBorderAll, RxBorderNone } from "react-icons/rx";
import { mutateElement, newElementWith } from "../../element/mutateElement";
import { getLanguage, t } from "src/excalidraw/i18n";
import { register } from "src/excalidraw/actions/register";
import { changeProperty, getFormValue } from "src/excalidraw/actions/actionProperties";
import { isTextElement, redrawTextBoundingBox } from "src/excalidraw/element";
import { ExcalidrawElement, ExcalidrawTextElement, NonDeletedExcalidrawElement, TextAlign, TextDirection, VerticalAlign } from "src/excalidraw/element/types";
import { POINTER_DIRECTION, TEXT_DIRECTION } from "src/excalidraw/constants";
import { getBoundTextElement, getBoundTextElementId } from "src/excalidraw/element/textElement";
import { isBindableElementEx, isLinkElement, isNodeElement, isTaskElement } from "../element/typeChecks";
import { Point } from "../../types";
import { ArrowheadEx, ExcalidrawLinkElement, ExcalidrawNodeElement, ExcalidrawTaskElement } from "../element/types";
import { LinkElementEditor } from "../element/linkElementEditor";
// import NumericInput from "react-numeric-input";
import { IconPicker } from "../../components/IconPicker";
import { canHaveArrowheadsEx } from "../../scene/comparisons";
import { useStore } from "src/conpath/hooks/useStore";
import { ShapeCache } from "../../scene/ShapeCache";
import { arrayToMap } from "../../utils";
import SelectLayer from "../components/SelectLayer";

export const actionChangeStrokeWidthEx = register({
  name: "changeStrokeWidthEx",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) => {
        if (isTaskElement(el)) {
          return newElementWith(el, {
            strokeWidth: value, // CHANGED:UPDATE 2023/2/9 #601
          });
        }
        return el;
      }),
      appState: { ...appState, currentItemStrokeWidthTask: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      <label>{t("labels.strokeWidth")}</label>
      <ButtonIconSelect
        group="strokeWidth"
        options={[
          {
            value: 4,
            text: t("labels.thin"),
            icon: StrokeWidthBaseIcon,
          },
          {
            value: 8,
            text: t("labels.bold"),
            icon: StrokeWidthBoldIcon,
          },
          {
            value: 16,
            text: t("labels.extraBold"),
            icon: StrokeWidthExtraBoldIcon,
          },
        ]}
        value={getFormValue<number | null>(
          elements,
          appState,
          (element) => (isTaskElement(element) ? element.strokeWidth : null),
          appState.currentItemStrokeWidthTask,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeTextDirection = register({
  name: "changeTextDirection",
  trackEvent: { category: "element" },
  perform: (elements, appState, value, app) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (
            isTextElement(oldElement) &&
            oldElement.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(oldElement.containerId))
          ) {
            const newElement: ExcalidrawTextElement = newElementWith(
              oldElement,
              { textDirection: value },
            );

            redrawTextBoundingBox(
              newElement,
              app.scene.getContainerElement(oldElement),
              app.scene.getNonDeletedElementsMap(),
            );
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextDirection: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => (
    <fieldset>
      <label>{t("labels.textDirection")}</label>
      <ButtonIconSelect<TextDirection | false>
        group="text-direction"
        options={[
          {
            value: TEXT_DIRECTION.HORIZONTAL,
            text: t("labels.textDirection_horizontal"),
            icon: <MdOutlineTextRotationNone />,
            testId: "horizontal",
          },
          {
            value: TEXT_DIRECTION.VERTICAL,
            text: t("labels.textDirection_vertical"),
            icon: <MdOutlineTextRotateVertical />,
            testId: "vertical",
          },
        ]}
        value={getFormValue(elements, appState, (element) => {
          if (
            isTextElement(element) &&
            element.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(element.containerId))
          ) {
            return element.textDirection;
          }
          const boundTextElement = getBoundTextElement(
            element,
            app.scene.getNonDeletedElementsMap(),
          );
          if (boundTextElement && isTaskElement(element)) {
            return boundTextElement.textDirection;
          }
          return null;
        })}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

// export const actionChangeTextOffset = register({
//   name: "changeTextOffset",
//   trackEvent: { category: "element" },
//   perform: (elements, appState, value, app) => {
//     return {
//       elements: changeProperty(
//         elements,
//         appState,
//         (oldElement) => {
//           if (isTextElement(oldElement)) {
//             const newElement: ExcalidrawTextElement = newElementWith(
//               oldElement,
//               { [value.name]: value.offset },
//             );

//             return newElement;
//           }

//           return oldElement;
//         },
//         true,
//       ),
//       appState: {
//         ...appState,
//       },
//       commitToHistory: true,
//     };
//   },
//   PanelComponent: ({ elements, appState, updateData, app }) => (
//     <fieldset>
//       <label>{t("labels.textOffset")}</label>
//       <div className="flex justify-between">
//         <div>
//           {t("labels.textOffsetX")}
//           <NumericInput
//             size={8}
//             step={4}
//             snap
//             value={getFormValue(elements, appState, (element) => {
//               if (isTextElement(element)) {
//                 return element.offsetX ?? 0;
//               }
//               const boundTextElement = getBoundTextElement(
//                 element,
//                 app.scene.getNonDeletedElementsMap(),
//               );
//               if (boundTextElement) {
//                 return boundTextElement.offsetX ?? 0;
//               }
//               return null;
//             }) || 0}
//             onChange={(value) => updateData({
//               name: "offsetX",
//               offset: value,
//             })}
//           />
//         </div>
//         <div>
//           {t("labels.textOffsetY")}
//           <NumericInput
//             size={8}
//             step={4}
//             snap
//             value={getFormValue(elements, appState, (element) => {
//               if (isTextElement(element)) {
//                 return element.offsetX ?? 0;
//               }
//               const boundTextElement = getBoundTextElement(
//                 element,
//                 app.scene.getNonDeletedElementsMap(),
//               );
//               if (boundTextElement) {
//                 return boundTextElement.offsetY ?? 0;
//               }
//               return null;
//             }) || 0}
//             onChange={(value) => updateData({
//               name: "offsetY",
//               offset: value,
//             })}
//           />
//         </div>
//       </div>
//     </fieldset>
//   ),
// });

export const actionChangeTextHorizontalAlign = register({
  name: "changeTextHorizontalAlign",
  trackEvent: false,
  perform: (elements, appState, value, app) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (
            isTextElement(oldElement) &&
            oldElement.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(oldElement.containerId))
          ) {
            const newElement: ExcalidrawTextElement = newElementWith(
              oldElement,
              { horizontalAlign: value, offsetX: 0 },
            );
            redrawTextBoundingBox(
              newElement,
              app.scene.getContainerElement(oldElement),
              app.scene.getNonDeletedElementsMap(),
            );
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextHorizontalAlign: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    return (
      <ButtonIconSelect<TextAlign | false>
        group="text-align"
        options={[
          {
            value: "left",
            text: t("labels.left"),
            icon: AlignTextOffsetLeftIcon,
            testId: "align-left",
          },
          {
            value: "center",
            text: t("labels.center"),
            icon: AlignTextOffsetCenterIcon,
            testId: "align-horizontal-center",
          },
          {
            value: "right",
            text: t("labels.right"),
            icon: AlignTextOffsetRightIcon,
            testId: "align-right",
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => {
            if (
              isTextElement(element) &&
              element.containerId &&
              isTaskElement(app.scene.getNonDeletedElementsMap().get(element.containerId))
            ) {
              return element.horizontalAlign;
            }
            const boundTextElement = getBoundTextElement(
              element,
              app.scene.getNonDeletedElementsMap(),
            );
            if (boundTextElement && isTaskElement(element)) {
              return boundTextElement.horizontalAlign;
            }
            return null;
          },
          appState.currentItemTextHorizontalAlign,
        )}
        onChange={(value) => updateData(value)}
      />
    );
  },
});

export const actionChangeTextVerticalAlign = register({
  name: "changeTextVerticalAlign",
  trackEvent: false,
  perform: (elements, appState, value, app) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (
            isTextElement(oldElement) &&
            oldElement.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(oldElement.containerId))
          ) {
            const newElement: ExcalidrawTextElement = newElementWith(
              oldElement,
              { verticalAlign: value, offsetY: 0 },
            );
            redrawTextBoundingBox(
              newElement,
              app.scene.getContainerElement(oldElement),
              app.scene.getNonDeletedElementsMap(),
            );
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextVerticalAlign: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    return (
      <ButtonIconSelect<VerticalAlign | false>
        group="posY"
        options={[
          {
            value: "top",
            text: t("labels.alignTop"),
            icon: AlignTextOffsetTopIcon,
            testId: "pos-top",
          },
          {
            value: "bottom",
            text: t("labels.alignBottom"),
            icon: AlignTextOffsetBottomIcon,
            testId: "pos-bottom",
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => {
            if (
              isTextElement(element) &&
              element.containerId &&
              isTaskElement(app.scene.getNonDeletedElementsMap().get(element.containerId))
            ) {
              return element.verticalAlign;
            }
            const boundTextElement = getBoundTextElement(
              element,
              app.scene.getNonDeletedElementsMap(),
            );
            if (boundTextElement && isTaskElement(element)) {
              return boundTextElement.verticalAlign;
            }
            return null;
          },
          appState.currentItemTextVerticalAlign,
        )}
        onChange={(value) => updateData(value)}
      />
    );
  },
});

export const actionChangeBorderNone = register({
  name: "changeTextBorderNone",
  trackEvent: { category: "element" },
  perform: (elements, appState, value, app) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (
            isTextElement(oldElement) &&
            oldElement.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(oldElement.containerId))
          ) {
            const newElement: ExcalidrawTextElement = newElementWith(
              oldElement,
              { textBorderNone: value },
            );

            redrawTextBoundingBox(
              newElement,
              app.scene.getContainerElement(oldElement),
              app.scene.getNonDeletedElementsMap(),
            );
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextBorderNone: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => (
    <fieldset>
      <label>{t("labels.textBorder")}</label>
      <ButtonIconSelect<boolean>
        group="text-border"
        options={[
          {
            value: true,
            text: t("labels.textBorder_none"),
            icon: <RxBorderNone />,
            testId: "none",
          },
          {
            value: false,
            text: t("labels.textBorder_solid"),
            icon: <RxBorderAll />,
            testId: "solid",
          },
        ]}
        value={getFormValue(elements, appState, (element) => {
          if (
            isTextElement(element) &&
            element.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(element.containerId))
          ) {
            return element.textBorderNone ?? false;
          }
          const boundTextElement = getBoundTextElement(
            element,
            app.scene.getNonDeletedElementsMap(),
          );
          if (boundTextElement && isTaskElement(element)) {
            return boundTextElement.textBorderNone ?? false;
          }
          return null;
        })}
        onChange={(value) => updateData(value)}
      />
    </fieldset >
  ),
});

export const actionChangeTextBorderOpacity = register({
  name: "changeTextBorderOpacity",
  trackEvent: false,
  perform: (elements, appState, value, app) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (
            isTextElement(oldElement) &&
            oldElement.containerId &&
            isTaskElement(app.scene.getNonDeletedElementsMap().get(oldElement.containerId))
          ) {
            return newElementWith(oldElement, {
              textBorderOpacity: value,
            });
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextBorderOpacity: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const value = getFormValue(
      elements,
      appState,
      (element) => {
        if (
          isTextElement(element) &&
          element.containerId &&
          isTaskElement(app.scene.getNonDeletedElementsMap().get(element.containerId))
        ) {
          return element.textBorderOpacity ?? 100;
        }
        const boundTextElement = getBoundTextElement(
          element,
          app.scene.getNonDeletedElementsMap(),
        );
        if (boundTextElement && isTaskElement(element)) {
          return boundTextElement.textBorderOpacity ?? 100;
        }
        return null;
      },
      appState.currentItemOpacity,
    ) ?? undefined;

    return (
      <fieldset>
        <label className="control-label">
          {t("labels.borderTextOpacity")}
          <input
            style={{
              background: `linear-gradient(to right, var(--color-primary) ${value}%, var(--color-passive) ${value}%)`,
            }}
            type="range"
            min="0"
            max="100"
            step="10"
            onChange={(event) => updateData(+event.target.value)}
            value={value}
          />
        </label>
      </fieldset>
    )
  },
});

// CHANGED:ADD 2024-03-11 #1749
export const actionChangeLinkPointerDirection = register({
  name: "changeLinkPointerDirection",
  trackEvent: false,
  perform: (elements, appState, value) => {
    const changePoints = (points: Point[], direction: ExcalidrawLinkElement["pointerDirection"]): Point[] => {
      const startPoint = points[0];
      const endPoint = points[points.length - 1];
      const midPoints = LinkElementEditor.generateMidPoints([startPoint, endPoint], direction);
      return [startPoint, ...midPoints, endPoint];
    }
    return {
      elements: changeProperty(
        elements,
        appState,
        (el) => isLinkElement(el) && el.pointerDirection !== value
          ? mutateElement(el, {
            pointerDirection: value,
            points: changePoints([...el.points], value),
          })
          : el,
      ),
      appState: { ...appState, currentItemPointerDirection: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => (
    <fieldset>
      <label>{t("labels.pointerDirection")}</label>
      <ButtonIconSelect<TextDirection | false>
        group="pointer-direction"
        options={[
          {
            value: POINTER_DIRECTION.HORIZONTAL,
            text: t("labels.pointerDirection_horizontal"),
            icon: HorizontalIcon,
            testId: "horizontal",
          },
          {
            value: POINTER_DIRECTION.VERTICAL,
            text: t("labels.pointerDirection_vertical"),
            icon: VerticalIcon,
            testId: "vertical",
          },
        ]}
        value={getFormValue(elements, appState, (element) => {
          if (isLinkElement(element)) {
            return element.pointerDirection;
          }
          return null;
        })}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeArrowhead = register({
  name: "changeArrowheadEx",
  trackEvent: false,
  perform: (
    elements,
    appState,
    value: { position: "start" | "end"; type: ArrowheadEx },
  ) => {
    return {
      elements: changeProperty(elements, appState, (el) => {
        if (isTaskElement(el)) {
          const { position, type } = value;

          if (position === "start") {
            const element: ExcalidrawTaskElement = newElementWith(el, {
              startArrowhead: type,
            });
            return element;
          } else if (position === "end") {
            const element: ExcalidrawTaskElement = newElementWith(el, {
              endArrowhead: type,
            });
            return element;
          }
        }

        return el;
      }),
      appState: {
        ...appState,
        [value.position === "start"
          ? "currentItemStartArrowheadEx"
          : "currentItemEndArrowheadEx"]: value.type,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const isRTL = getLanguage().rtl;

    return (
      <fieldset>
        <label>{t("labels.arrowheads")}</label>
        <div className="buttonList--alone">
          <IconPicker
            label="arrowhead_start"
            group="arrowheads"
            options={[
              {
                value: null,
                text: t("labels.arrowhead_none"),
                icon: ArrowheadNoneIcon,
                keyBinding: "q",
              },
              {
                value: "circle",
                text: t("labels.arrowheadex_circle"),
                keyBinding: "e",
                icon: <ArrowheadExCircleIcon flip={!isRTL} />,
              },
              {
                value: "dot_small",
                text: t("labels.arrowheadex_dot_small"),
                keyBinding: "r",
                icon: <ArrowheadExDotSmallIcon flip={!isRTL} />,
              },
              {
                value: "dot",
                text: t("labels.arrowheadex_dot"),
                keyBinding: "t",
                icon: <ArrowheadExDotIcon flip={!isRTL} />,
              },
            ]}
            value={getFormValue<ArrowheadEx | null>(
              elements,
              appState,
              (element) =>
                isTaskElement(element) && canHaveArrowheadsEx(element.type)
                  ? element.startArrowhead
                  : appState.currentItemStartArrowheadEx,
              appState.currentItemStartArrowheadEx,
            )}
            onChange={(value) => updateData({ position: "start", type: value })}
          />
          <IconPicker
            label="arrowhead_end"
            group="arrowheads"
            options={[
              {
                value: null,
                text: t("labels.arrowhead_none"),
                icon: ArrowheadNoneIcon,
                keyBinding: "q",
              },
              {
                value: "arrow",
                text: t("labels.arrowheadex_arrow"),
                keyBinding: "w",
                icon: <ArrowheadArrowIcon flip={isRTL} />,
              },
              {
                value: "circle",
                text: t("labels.arrowheadex_circle"),
                keyBinding: "e",
                icon: <ArrowheadExCircleIcon flip={isRTL} />,
              },
              {
                value: "dot_small",
                text: t("labels.arrowheadex_dot_small"),
                keyBinding: "r",
                icon: <ArrowheadExDotSmallIcon flip={isRTL} />,
              },
              {
                value: "dot",
                text: t("labels.arrowheadex_dot"),
                keyBinding: "t",
                icon: <ArrowheadExDotIcon flip={isRTL} />,
              },
            ]}
            value={getFormValue<ArrowheadEx | null>(
              elements,
              appState,
              (element) =>
                isTaskElement(element) && canHaveArrowheadsEx(element.type)
                  ? element.endArrowhead
                  : appState.currentItemEndArrowheadEx,
              appState.currentItemEndArrowheadEx,
            )}
            onChange={(value) => updateData({ position: "end", type: value })}
          />
        </div>
      </fieldset>
    );
  },
});

export const actionChangeTextOffset = register({
  name: "changeLayer",
  trackEvent: { category: "element" },
  perform: (elements, appState, value, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });
    const elementsMap = app.scene.getNonDeletedElementsMap();
    const updatedElementIds: Set<ExcalidrawElement["id"]> = new Set();
    const updatedElements: NonDeletedExcalidrawElement[] = [];
    const queue: ExcalidrawNodeElement[] = [];
    const discovered: Set<ExcalidrawElement["id"]> = new Set();

    selectedElements.forEach((el) => {
      updatedElementIds.add(el.id);
      discovered.clear();

      if (isNodeElement(el)) {
        discovered.add(el.id);
        queue.push(el);

        while (queue.length > 0) {
          const v = queue.shift() as ExcalidrawNodeElement;

          const dependencies = [
            ...(v.nextDependencies || []),
            ...(v.prevDependencies || []),
          ];

          dependencies?.forEach((e) => {
            if (!discovered.has(e)) {
              discovered.add(e);

              const u = elementsMap.get(e);
              if (u && isBindableElementEx(u, true)) {
                updatedElementIds.add(u.id);

                const textElementId = getBoundTextElementId(u);
                if (textElementId) {
                  updatedElementIds.add(textElementId);
                }

                const linkElement = Array.from(elementsMap.values()).find(
                  (element) =>
                    isLinkElement(element) &&
                    ((element.startBinding?.elementId === v.id &&
                      element.endBinding?.elementId === u.id) ||
                      (element.startBinding?.elementId === u.id &&
                        element.endBinding?.elementId === v.id)),
                );

                if (linkElement) {
                  updatedElementIds.add(linkElement.id);

                  const textElementId = getBoundTextElementId(linkElement);
                  if (textElementId) {
                    updatedElementIds.add(textElementId);
                  }
                }
                queue.push(u);
              }
            }
          });
        }
      } else if (isLinkElement(el)) {
        const element = elementsMap.get(el.startBinding?.elementId || "");

        if (isNodeElement(element)) {
          updatedElementIds.add(element.id);

          const textElementId = getBoundTextElementId(element);
          if (textElementId) {
            updatedElementIds.add(textElementId);
          }

          discovered.add(element.id);
          queue.push(element);

          while (queue.length > 0) {
            const v = queue.shift() as ExcalidrawNodeElement;

            const dependencies = [
              ...(v.nextDependencies || []),
              ...(v.prevDependencies || []),
            ];

            dependencies?.forEach((e) => {
              if (!discovered.has(e)) {
                discovered.add(e);

                const u = elementsMap.get(e);
                if (u && isBindableElementEx(u, true)) {
                  updatedElementIds.add(u.id);

                  const textElementId = getBoundTextElementId(u);
                  if (textElementId) {
                    updatedElementIds.add(textElementId);
                  }

                  const linkElement = Array.from(elementsMap.values()).find(
                    (element) =>
                      isLinkElement(element) &&
                      ((element.startBinding?.elementId === v.id &&
                        element.endBinding?.elementId === u.id) ||
                        (element.startBinding?.elementId === u.id &&
                          element.endBinding?.elementId === v.id)),
                  );

                  if (linkElement) {
                    updatedElementIds.add(linkElement.id);

                    const textElementId = getBoundTextElementId(linkElement);
                    if (textElementId) {
                      updatedElementIds.add(textElementId);
                    }
                  }
                  queue.push(u);
                }
              }
            });
          }
        }
      }

      updatedElementIds.forEach((updatedElementId) => {
        const element = elementsMap.get(updatedElementId);
        if (element) {
          ShapeCache.delete(element);
          updatedElements.push(newElementWith(element, { layer: value }));
        }
      });
    });

    const updatedElementsMap = arrayToMap(updatedElements);
    const nextElements = elements.map(
      (element) => updatedElementsMap.get(element.id) || element,
    );

    return {
      elements: nextElements,
      appState: {
        ...appState,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData, app }) => {
    const { organizationStore } = useStore();
    const { selectedOrganization } = organizationStore;
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
    });

    const project =
      selectedOrganization?.projects.find(
        (project) => project.id === appState.projectId,
      ) || null;

    return (
      <fieldset>
        <SelectLayer
          elements={selectedElements}
          project={project}
          selectedValue={
            getFormValue(elements, appState, (element) => element.layer) ??
            Number.MAX_VALUE
          }
          onChange={updateData}
        />
      </fieldset>
    );
  },
});

