import _ from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";

import "./SelectAssignUsers.scss";

import TaskModel from "src/conpath/models/TaskModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import { OrganizationRole } from "src/conpath/constants/Role";
import { Menu, Transition } from "@headlessui/react";
import { GoGear } from "react-icons/go";
import { FaCheck } from "react-icons/fa";
import LoginUserModel from "../../models/LoginUserModel";

interface SelectingUser {
  id: string;
  email: string;
  username: string;
  profileImageUrl: string | null;
  isSelected: boolean;
}
const hasUserCheck = ({
  list,
  targetUser,
}: {
  list: SelectingUser[];
  targetUser: SelectingUser;
}): boolean => {
  return list.some((user) => user.id === targetUser.id);
};

const toggeleUsers = ({
  list,
  targetUser,
}: {
  list: SelectingUser[];
  targetUser: SelectingUser;
}): SelectingUser[] => {
  if (hasUserCheck({ list, targetUser })) {
    return list.filter((user) => user.id !== targetUser.id);
  }
  return [...list, targetUser];
};

const SelectAssignUsers = observer(
  (props: {
    loginUser: LoginUserModel,
    taskModel: TaskModel;
    organization: OrganizationModel | null;
    onChangeAssignUsers: () => void;
  }) => {
    const { loginUser, taskModel, organization, onChangeAssignUsers } = props;

    const [assignedUsers, setAssignedUsers] = useState<SelectingUser[]>([]);
    const [projectUsers, setProjectUsers] = useState<SelectingUser[]>([]);

    // 全ユーザー
    useEffect(() => {
      const project = organization?.projects.find((project) => project.id === taskModel.projectId);
      const users = organization?.users.filter((user) =>
        (project?.roles && project.roles[user.id]) ||
        (project?.teams && Object.keys(project.teams).some((key) =>
          organization.teams.some((team) => key === team.id && team.userIds.includes(user.id))
        ))
      ).map((user) => {
        return {
          id: user.id,
          email: user.email,
          username: user.username,
          profileImageUrl: user.profileImageUrl,
          isSelected: false,
        } as SelectingUser;
      }) || [];

      setProjectUsers(users);
    }, [organization]);

    // すでにアサインされたユーザー
    useEffect(() => {
      const _assignedUserIds = taskModel.assignUserIds;
      const isAssigned = (user: SelectingUser): boolean => {
        return _assignedUserIds.some((u) => u === user.id);
      };
      setAssignedUsers(projectUsers.filter((user) => isAssigned(user)));
    }, [projectUsers, taskModel.assignUserIds]);

    // if(!project) return <></>;
    return (
      <>
        <div className="flex relative">
          <SelectionUserList
            projectUsers={projectUsers}
            assignedUsers={assignedUsers}
            loginUser={loginUser}
            taskModel={taskModel}
            onChangeAssignUsers={onChangeAssignUsers}
          />
        </div>
        <div className="assign-user__list">
          {assignedUsers.map((user) => (
            <div key={user.id} className="flex items-center pt-1 pb-1">
              {user.profileImageUrl ? (
                <img
                  src={user.profileImageUrl}
                  className="w-5 rounded-full object-contain"
                />
              ) : (
                <div className="w-5 h-5 bg-blue-900 text-white text-center leading-5 rounded-full">
                  <p>{user.username.charAt(0)}</p>
                </div>
              )}
              <div className="v-stack ml-2">
                <p className="username-label">{user.username}</p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  },
);

const SelectionUserList = (props: {
  loginUser: LoginUserModel,
  taskModel: TaskModel;
  projectUsers: SelectingUser[];
  assignedUsers: SelectingUser[];
  onChangeAssignUsers: () => void;
}) => {
  const { projectUsers, assignedUsers, taskModel, loginUser, onChangeAssignUsers } = props;
  const [listAssignedUsers, setListAssignedUsers] =
    useState<SelectingUser[]>(assignedUsers);
  const [isOpenAssignList, setIsOpenAssignList] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<SelectingUser[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setListAssignedUsers(assignedUsers);
  }, [assignedUsers]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const prev = isOpenAssignList;
        if (prev) {
          setIsOpenAssignList(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, isOpenAssignList]);

  const List = React.useMemo(() => {
    const onSave = async () => {
      const checkUnchanged =
        listAssignedUsers.length === assignedUsers.length &&
        listAssignedUsers.every((user) => {
          return hasUserCheck({ list: assignedUsers, targetUser: user });
        });
      if (!checkUnchanged) {
        const users = listAssignedUsers.map((user) => user.id);
        taskModel.setAssginUsers(users);
        await taskModel.saveAssignUsers(loginUser);
        onChangeAssignUsers();
      }
    };

    const onSearch = (searchText: string) => {
      const list = new Set(listAssignedUsers.map((user) => user.id));

      const _filteredUsers = projectUsers
        .filter((v) => !_.isEmpty(searchText)
          ? v.username.toLowerCase().includes(searchText.toLowerCase())
          : true)
        .sort((a, b) => {
          if (list.has(a.id) && !list.has(b.id)) {
            return -1;
          } else if (!list.has(a.id) && list.has(b.id)) {
            return 1;
          }
          return 0;
        })

      setFilteredUsers(_filteredUsers);
    };

    const onSelectUserButtonPressed = () => {
      const prev = isOpenAssignList;
      if (prev) {
        onSave();
      }
      setIsOpenAssignList(!isOpenAssignList);
      onSearch("");
    };

    return (
      <Menu ref={ref} as="div" className="relative inline-block text-left">
        {({ open }) => {
          if (!open && isOpenAssignList) {
            onSave();
          }
          return (
            <>
              <Menu.Button
                className="flex items-center group/assign-button cursor-pointer no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                as="div"
                onClick={() => {
                  onSelectUserButtonPressed();
                }}
              >
                <label className="task-content__label !mb-0 cursor-pointer group-hover/assign-button:text-primary-color">
                  担当者
                </label>
                <GoGear
                  className="ml-2 cursor-pointer text-black group-hover/assign-button:text-primary-color"
                  aria-hidden="true"
                />
              </Menu.Button>
              <Transition
                show={isOpenAssignList}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-[1] absolute left-0 mt-2 w-60 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                  {projectUsers.length > 0 && (
                    <>
                      {/* Assigned users */}
                      <div className="m-2">
                        <input
                          className="w-full border-[1px] border-solid border-[var(--button-gray-3)] p-2 rounded-[4px]"
                          type="text"
                          onChange={(e) => onSearch(e.target.value)}
                          placeholder={"検索..."}
                        />
                      </div>
                      {filteredUsers.map((user) => {
                        const isCheck = hasUserCheck({
                          list: listAssignedUsers,
                          targetUser: user,
                        });
                        return (
                          <div
                            className="cursor-pointer p-2 group/select-user flex items-center hover:bg-primary-color transition ease-out duration-100 no-select" /* テキスト選択を無効にするために no-select クラスを追加 */
                            onClick={() => {
                              setListAssignedUsers(
                                toggeleUsers({
                                  list: listAssignedUsers,
                                  targetUser: user,
                                }),
                              );
                            }}
                          >
                            <FaCheck
                              className={`${isCheck
                                ? "text-black group-hover/select-user:text-white"
                                : "text-transparent"
                                } mr-2`}
                            />
                            {user.profileImageUrl ? (
                              <img
                                src={user.profileImageUrl}
                                className="w-5 rounded-full object-contain mr-2"
                              />
                            ) : (
                              <div className="w-5 h-5 bg-blue-900 text-white text-center leading-5 rounded-full mr-2">
                                <p>{user.username.charAt(0)}</p>
                              </div>
                            )}
                            <span className="group-hover/select-user:text-white">
                              {user.username}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }, [
    isOpenAssignList,
    projectUsers,
    filteredUsers,
    assignedUsers,
    listAssignedUsers,
  ]);

  return List;
};

export default SelectAssignUsers;
