import { isEqual } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
// import { nanoid } from "nanoid";
import { t } from "src/excalidraw/i18n";
import "./EditMultipleTaskElement.scss";
import "./ConfirmDialogEx.scss";
import ConfirmDialogEx from "./ConfirmDialogEx";
import { ExcalidrawTaskElement } from "../element/types";
import { AppState } from "src/excalidraw/types";
import { useExcalidrawSetAppState } from "src/excalidraw/components/App";
import { observer } from "mobx-react-lite";
import { useStore } from "src/conpath/hooks/useStore";
import TaskModel from "src/conpath/models/TaskModel";
import Checkbox from "src/conpath/components/CheckBox";
import Task from "src/conpath/interfaces/Task";
import { Flip, ToastContainer } from 'react-toastify';
import SelectMultipleTaskAssignUsers from "./SelectMultipleTaskAssignUsers";
import SelectMultipleTaskAssignResources from "./SelectMultipleTaskAssignResources";
import { ElementsMap } from "../../element/types";
import { getBoundTextElement } from "../../element/textElement";
import { Tooltip } from "../../components/Tooltip";
import { formatDate } from "src/utils/dateUtils";
import ProjectModel from "src/conpath/models/ProjectModel";

export const UpdateDataType = {
  UPDATES: "UPDATES",
} as const;
export type UpdateDataType = typeof UpdateDataType[keyof typeof UpdateDataType];

const EditMultipleTaskElement = React.forwardRef(
  (
    {
      elements,
      elementsMap,
      appState,
    }: {
      elements: ExcalidrawTaskElement[];
      elementsMap: ElementsMap;
      appState: AppState;
    },
    ref,
  ) => {
    const { organizationStore, userStore } = useStore();
    const { selectedOrganization } = organizationStore;
    const { loginUser } = userStore;

    const setAppState = useExcalidrawSetAppState();
    const [error, setError] = useState(false);
    const inputRef = React.useRef(null);
    const [taskModels, setTaskModels] = useState<TaskModel[]>([]);
    const [beforeTasks, setBeforeTasks] = useState<Task[]>([]);
    const [project, setProject] = useState<ProjectModel | null>(null);

    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const handleChange = React.useCallback((id: string) => {
      const values = [...checkedValues];
      values.filter(v => v === id).length > 0
        ? values.splice(checkedValues.findIndex(v => v === id), 1)
        : values.push(id);

      setCheckedValues(values);
    }, [checkedValues]);

    useEffect(() => {
      const getTaskModel = async () => {
        if (selectedOrganization) {
          const _tasks: TaskModel[] = [];
          for (const element of elements) {
            const task = await selectedOrganization.getSelectedTaskOnCanvas(
              element,
              appState.projectId,
              getBoundTextElement(element, elementsMap)?.originalText
            );
            task.setOrganizationId(selectedOrganization.id);
            _tasks.push(task);
          };

          setTaskModels(_tasks);
          setBeforeTasks(_tasks.map((task) =>
            JSON.parse(JSON.stringify(task.getFields())))
          );
        }
      };

      getTaskModel();

      if (!selectedOrganization) return;

      const selectedProject = selectedOrganization.projects.find(
        (project) => project.id === appState.projectId,
      );
      if (!selectedProject) return;

      setProject(selectedProject);
    }, [selectedOrganization, appState.projectId]);

    React.useImperativeHandle(ref, () => inputRef.current);

    const onClose = useCallback(async () => {
      setAppState({ openDialog: null });
    }, [taskModels]);

    const onConfirm = useCallback(async () => {
    }, [taskModels]);

    const onSaveAssignUsers = async () => {
      if (!loginUser) return;
      // if (error) return;

      for (const taskModel of taskModels) {
        const beforeTask = beforeTasks.find((before) => before.id === taskModel.id);
        if (!isEqual(beforeTask?.assignUserIds || [], taskModel.assignUserIds)) {
          const result = await taskModel.saveAssignUsers(loginUser);
          if (result.error) {
            setAppState({ errorMessage: result.error });
            return;
          }
        }
      };
    };

    const onSaveAssignResources = async () => {
      if (!loginUser) return;
      // if (error) return;

      for (const taskModel of taskModels) {
        const beforeTask = beforeTasks.find((before) => before.id === taskModel.id);
        if (!isEqual(beforeTask?.assignResourceIds || [], taskModel.assignResourceIds)) {
          const result = await taskModel.saveAssignResources();
          if (result.error) {
            setAppState({ errorMessage: result.error });
            return;
          }
        }
      };
    };

    if (taskModels.length === 0) return <></>;
    return (
      <>
        <ConfirmDialogEx
          onConfirm={onConfirm}
          onCancel={onClose}
          title={t("editMultipleTaskDialog.title")}
          className={`confirm-dialog EditMutipleTaskElement`}
          disabled={error}
          isHideDialogButtons={true}
        >
          <ToastContainer
            position="top-center"
            transition={Flip}
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="section tasks-list-section">
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th className="th checkbox">
                      <Checkbox
                        props={{
                          onClick: () => {
                            if (taskModels.every((task) => checkedValues.includes(task.id))) {
                              setCheckedValues([]);
                            } else {
                              setCheckedValues(taskModels.map((task) => task.id));
                            }
                          },
                          checked: taskModels.every((task) => checkedValues.includes(task.id)),
                          // value: task.id,
                          className: "edit-multiple-task__checkBox",
                          sx: {
                            color: "#DDDDDD",
                            backgroundImage: "linear-gradient(to right, white, white)",
                            borderRadius: 0,
                            height: 18,
                            width: 18,
                          },
                        }}
                      />
                    </th>
                    <th className="th task-title"></th>
                    <th className="th assign-users">
                      <SelectMultipleTaskAssignUsers
                        tasks={taskModels.filter((task) => checkedValues.includes(task.id))}
                        organization={selectedOrganization}
                        project={project}
                        onSaveAssignUsers={onSaveAssignUsers}
                      />
                    </th>
                    <th className="th assign-resources">
                      <SelectMultipleTaskAssignResources
                        tasks={taskModels.filter((task) => checkedValues.includes(task.id))}
                        organization={selectedOrganization}
                        project={project}
                        onSaveAssignResources={onSaveAssignResources}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taskModels.map((task) => {
                    return (
                      <tr
                        key={task.id}
                      >
                        <td className="td checkbox">
                          <Checkbox
                            props={{
                              onClick: () => handleChange(task.id),
                              checked: checkedValues.includes(task.id),
                              value: task.id,
                              className: "edit-multiple-task__checkBox",
                              sx: {
                                color: "#DDDDDD",
                                backgroundImage: "linear-gradient(to right, white, white)",
                                borderRadius: 0,
                                height: 18,
                                width: 18,
                              },
                            }}
                          />
                        </td>
                        <td className="td task-title">
                          <div className="edit-multiple-task__title">{task.text}</div>
                          <div className="edit-multiple-task__date">
                            <span>
                              {formatDate(task.startDate)}
                            </span>
                            {" ~ "}
                            <span>
                              {formatDate(task.endDate)}
                            </span>
                          </div>
                        </td>
                        <td className="td assign-users">
                          <div className="icon-wrapper">
                            {
                              task.assignUserIds
                                .map((userId) => {
                                  const user = selectedOrganization?.findUserById(userId);

                                  return (
                                    <Tooltip label={user?.username || ""}>
                                      {user && user.profileImageUrl
                                        ? <img src={user.profileImageUrl} />
                                        : <div>
                                          {user ? user.username.charAt(0) : ""}
                                        </div>
                                      }
                                    </Tooltip>
                                  )
                                })
                            }
                          </div>
                        </td>
                        <td className="td assign-resources">
                          <div className="icon-wrapper">
                            {
                              task.assignResourceIds
                                .map((resourceId) => {
                                  const resource = selectedOrganization?.resources.find((resource) => resource.id === resourceId);

                                  return (
                                    <Tooltip label={resource?.name || ""}>
                                      {resource && resource.iconImageUrl
                                        ? <img src={resource.iconImageUrl} />
                                        : <div>
                                          {resource ? resource.name.charAt(0) : ""}
                                        </div>
                                      }
                                    </Tooltip>
                                  )
                                })
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </ConfirmDialogEx>
      </>
    );
  },
);

export default observer(EditMultipleTaskElement);
