export const FirestoreCollections = {
  organizations: {
    this: "organizations",
    projects: {
      this: "projects",
    },
    tasks: {
      this: "tasks",
      checklists: {
        this: "checklists",
      }
    },
    milestones: {
      this: "milestones"
    },
    comments: {
      this: "comments"
    },
    teams: "teams",
    users: "users",
    resources: "resources",
    libraries: "libraries",
    activities: "activities",
  },

  users: {
    this: "users",
  },
} as const;

export type FirestoreCollections = typeof FirestoreCollections[keyof typeof FirestoreCollections];
