import React, { HTMLAttributes } from "react";

import oc from "open-color";
import clsx from "clsx";
import Colors from "../constants/Colors";
// import { Theme } from "../element/types";
// import { THEME } from "../constants";

type Opts = {
  width?: number;
  height?: number;
  mirror?: true;
} & React.SVGProps<SVGSVGElement>;

export const createIcon = (
  d: string | React.ReactNode,
  opts: number | Opts = 512,
) => {
  const {
    width = 512,
    height = width,
    mirror,
    style,
    ...rest
  } = typeof opts === "number" ? ({ width: opts } as Opts) : opts;
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox={`0 0 ${width} ${height}`}
      className={clsx({ "rtl-mirror": mirror })}
      style={style}
      {...rest}
    >
      {typeof d === "string" ? <path fill="currentColor" d={d} /> : d}
    </svg>
  );
};

export const UserSettingIcon = createIcon(
  <>
    <g transform="translate(-5151.365 -122.806)">
      <path d="M5160.223,122.806a6.331,6.331,0,1,1-6.331,6.314A6.341,6.341,0,0,1,5160.223,122.806Zm3.8,6.3a3.8,3.8,0,1,0-3.8,3.824A3.8,3.8,0,0,0,5164.02,129.111Z" />
      <path d="M5160.2,138.1a26.924,26.924,0,0,1,3.595.026,6.2,6.2,0,0,1,5.285,5.97c.027.885.018,1.772.007,2.658a1.267,1.267,0,1,1-2.526.012c-.013-.822.009-1.645-.007-2.467a3.775,3.775,0,0,0-3.742-3.761q-2.594-.036-5.188,0a3.772,3.772,0,0,0-3.727,3.71c-.019.822,0,1.645-.008,2.468a1.285,1.285,0,0,1-1.284,1.412,1.253,1.253,0,0,1-1.237-1.389,22.359,22.359,0,0,1,.266-4.08,6.128,6.128,0,0,1,5.846-4.651c.906-.025,1.813,0,2.72,0Z" />
    </g>
  </>,
  { width: 17.73, height: 25.327 },
);

type Props = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  size?: string;
};

export const MagnifierIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_23277" data-name="Rectangle 23277" width="23" height="23" fill="none" stroke="#000" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g id="Group_12246" data-name="Group 12246" opacity="0.4">
        <rect id="Rectangle_23276" data-name="Rectangle 23276" width="23" height="23" fill="none"/>
        <g id="Group_12245" data-name="Group 12245">
          <g id="Group_12244" data-name="Group 12244" clipPath="url(#clipPath)">
            <path id="Path_1661" data-name="Path 1661" d="M13.939,21.172h0A6.676,6.676,0,1,1,20.616,14.5,6.676,6.676,0,0,1,13.939,21.172Z" transform="translate(-3.466 -3.732)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_138" data-name="Line 138" x2="3.733" y2="3.733" transform="translate(15.471 15.18)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
));

export const UserIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke={Colors.gray} fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
    </svg>
  </div>
));

export const AddUserIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_23329" data-name="Rectangle 23329" width="22" height="22" fill="none" stroke="currentColor" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g id="Group_12467" data-name="Group 12467" transform="translate(0.5 0.5)">
        <rect id="Rectangle_23328" data-name="Rectangle 23328" width="22" height="22" fill="currentColor" stroke="currentColor" strokeWidth="1" opacity="0"/>
        <g id="Group_12466" data-name="Group 12466">
          <g id="Group_12465" data-name="Group 12465" clipPath="url(#clipPath)">
            <path id="Path_1676" data-name="Path 1676" d="M15.833,13.66a3.165,3.165,0,1,0-3.165-3.165A3.166,3.166,0,0,0,15.833,13.66Z" transform="translate(-6.334 -3.664)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_1677" data-name="Path 1677" d="M14.595,24.615a6.057,6.057,0,0,0-1.818-.225,6.059,6.059,0,0,0-6.223,5.88c0,.087.01.174.015.26h7.7" transform="translate(-3.277 -12.194)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_1678" data-name="Path 1678" d="M32.087,30.451a1.245,1.245,0,1,1-1.245-1.245,1.245,1.245,0,0,1,1.245,1.245" transform="translate(-14.799 -14.603)" fill="currentColor"/>
            <path id="Path_1679" data-name="Path 1679" d="M29.42,24.843l.375.85.771.445.923-.1.6,1.038-.549.749v.89l.549.749-.6,1.037-.923-.1-.771.445-.375.849h-1.2l-.375-.849-.771-.445-.923.1c-.285-.493-.314-.544-.6-1.037l.548-.749v-.89l-.548-.749c.285-.493.314-.545.6-1.038l.923.1.771-.445.375-.85Z" transform="translate(-12.777 -12.422)" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
));

export const EditIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg width="24" height="24" viewBox="0 0 512 512" strokeWidth="2" stroke={Colors.gray} fill={Colors.gray} strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/>
    </svg>
  </div>
));

export const MoreIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dots-vertical" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1" stroke="#000" fill="#000" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="19" r="1" />
      <circle cx="12" cy="5" r="1" />
    </svg>
  </div>
));

export const CalenderIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg id="Group_12431" data-name="Group 12431" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_23185" data-name="Rectangle 23185" width="22" height="22" fill="none" stroke="#707070" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g id="Group_11903" data-name="Group 11903" clipPath="url(#clipPath)">
        <rect id="Rectangle_23184" data-name="Rectangle 23184" width="14.086" height="11.611" rx="1" transform="translate(3.957 5.991)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_113" data-name="Line 113" x2="14.086" transform="translate(3.957 9.009)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_114" data-name="Line 114" y1="2.245" transform="translate(7.238 3.746)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_115" data-name="Line 115" y1="2.245" transform="translate(14.762 3.746)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <path id="Path_1604" data-name="Path 1604" d="M15.1,22.7a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-6.853 -11)"/>
        <path id="Path_1605" data-name="Path 1605" d="M22,22.7a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-10.304 -11)"/>
        <path id="Path_1606" data-name="Path 1606" d="M28.9,22.7a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-13.753 -11)"/>
        <path id="Path_1607" data-name="Path 1607" d="M15.1,28.472a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-6.853 -13.888)"/>
        <path id="Path_1608" data-name="Path 1608" d="M22,28.472a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-10.304 -13.888)"/>
        <path id="Path_1609" data-name="Path 1609" d="M28.9,28.472a.7.7,0,1,1-.7-.7.7.7,0,0,1,.7.7" transform="translate(-13.753 -13.888)"/>
      </g>
    </svg>
  </div>
));


export const TrashIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg"  width="27" height="27" viewBox="0 0 27 27">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_23183" data-name="Rectangle 23183" width="21" height="21" fill="none" stroke="#000" strokeWidth="1"/>
        </clipPath>
      </defs>
      <g id="Group_12965" data-name="Group 12965" transform="translate(-644 -405)">
        <rect id="Rectangle_23431" data-name="Rectangle 23431" width="27" height="27" rx="5" transform="translate(644 405)" fill="#fff"/>
        <g id="Group_12009" data-name="Group 12009" transform="translate(647 408)">
          <g id="Group_11901" data-name="Group 11901" clipPath="url(#clipPath)">
            <path id="Path_1602" data-name="Path 1602" d="M21.269,13.5l-.622,9.38a2.034,2.034,0,0,1-2.03,1.917H13.241a2.033,2.033,0,0,1-2.03-1.917l-.622-9.38" transform="translate(-5.535 -7.059)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_109" data-name="Line 109" x2="13.579" transform="translate(3.604 5.347)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <path id="Path_1603" data-name="Path 1603" d="M16.508,8.916l.817-1.654a.781.781,0,0,1,.7-.435h2a.781.781,0,0,1,.7.435l.817,1.654" transform="translate(-8.629 -3.569)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_110" data-name="Line 110" y2="7.101" transform="translate(8.172 7.903)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_111" data-name="Line 111" y2="7.101" transform="translate(10.394 7.903)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
            <line id="Line_112" data-name="Line 112" y2="7.101" transform="translate(12.616 7.903)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          </g>
        </g>
      </g>
    </svg>
  </div>
));

export const CloseIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g id="Group_12647" data-name="Group 12647" transform="translate(14775 17953)">
        <rect id="Rectangle_24" data-name="Rectangle 24" width="40" height="40" rx="4" transform="translate(-14775 -17953)" fill="none"/>
        <g id="Group_12146" data-name="Group 12146" transform="translate(-14770 -17948)">
          <rect id="Rectangle_23253" data-name="Rectangle 23253" width="30" height="30" fill="none"/>
          <line id="Line_128" data-name="Line 128" x2="17.395" y2="17.395" transform="translate(6.303 6.303)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
          <line id="Line_129" data-name="Line 129" x1="17.395" y2="17.395" transform="translate(6.303 6.303)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        </g>
      </g>
    </svg>
  </div>
));

export const CompleteIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.999" height="22" viewBox="0 0 21.999 22">
      <g id="Group_12901" data-name="Group 12901" transform="translate(5078.95 20016.418)">
        <path id="Path_1689" data-name="Path 1689" d="M15.6,26.163h0a10.5,10.5,0,1,1,10.5-10.5A10.5,10.5,0,0,1,15.6,26.163Z" transform="translate(-5083.552 -20021.082)" fill="none" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" stroke-dasharray="1 2.3"/>
        <path id="Path_1690" data-name="Path 1690" d="M13.83,20.9l3.245,3.447,6.908-6.893" transform="translate(-5086.856 -20025.734)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </svg>
  </div>
));

export const IncompleteIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.999" height="22" viewBox="0 0 21.999 22">
      <g id="Group_12901" data-name="Group 12901" transform="translate(5078.95 20016.418)">
        <path id="Path_1689" data-name="Path 1689" d="M15.6,26.163h0a10.5,10.5,0,1,1,10.5-10.5A10.5,10.5,0,0,1,15.6,26.163Z" transform="translate(-5083.552 -20021.082)" fill="none" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" strokeWidth="1" stroke-dasharray="1 2.3"/>
      </g>
      <line id="Line_1689" data-name="Line 1689" x1="6" x2="16" y1="6" y2="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      <line id="Line_1689" data-name="Line 1689" x1="6" x2="16" y1="16" y2="6" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
    </svg>
  </div>
));

export const PlusIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g id="Group_12683" data-name="Group 12683" transform="translate(-1)">
        <line id="Line_156" data-name="Line 156" x2="14" transform="translate(1 7)" fill="none" stroke="currentColor" strokeWidth="2"/>
        <line id="Line_157" data-name="Line 157" x2="14" transform="translate(8) rotate(90)" fill="none" stroke="currentColor" strokeWidth="2"/>
      </g>
    </svg>
  </div>
));

export const DelayIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <rect width="22" height="22" fill="#df4646" opacity="0"/>
      <g transform="translate(0.914 0.914)">
        <g>
          <path d="M18,28.086h0A10.086,10.086,0,1,1,28.086,18,10.086,10.086,0,0,1,18,28.086Z" transform="translate(-7.914 -7.914)" fill="#df4646"/>
          <path d="M0,10.1A1.141,1.141,0,0,1,1.136,8.961,1.144,1.144,0,0,1,2.271,10.1a1.115,1.115,0,0,1-1.136,1.1A1.112,1.112,0,0,1,0,10.1Zm.386-2.64L0,.876V.821C0,.271.558,0,1.136,0c.558,0,1.136.271,1.136.821V.876l-.389,6.58a.7.7,0,0,1-.747.664A.707.707,0,0,1,.386,7.456Z" transform="translate(8.952 4.486)" fill="#fff"/>
        </g>
      </g>
    </svg>
  </div>
));

export const HeaderIcon = React.memo((props: Props) => (
  <div {...props}>
    <svg x="0px" y="0px" width="22px" height="22px" viewBox="0 0 22 22" >
      <g>
        <path d="M21.3,6.7H0.7V0.7h20.7V6.7z M1.9,5.5h18.2V1.9H1.9V5.5z"/>
      </g>
      <g>
        <g>
          <rect x="20.1" y="20.1" width="1.2" height="1.2"/>
        </g>
        <g>
          <path d="M18.9,21.3h-1.2v-1.2h1.2V21.3z M16.5,21.3h-1.2v-1.2h1.2V21.3z M14,21.3h-1.2v-1.2H14V21.3z M11.6,21.3h-1.2v-1.2h1.2
            V21.3z M9.2,21.3H8v-1.2h1.2V21.3z M6.7,21.3H5.5v-1.2h1.2V21.3z M4.3,21.3H3.1v-1.2h1.2V21.3z"/>
        </g>
        <g>
          <rect x="0.7" y="20.1" width="1.2" height="1.2"/>
        </g>
        <g>
          <path d="M1.9,18.9H0.7v-1.2h1.2V18.9z M1.9,16.5H0.7v-1.2h1.2V16.5z M1.9,14H0.7v-1.2h1.2V14z M1.9,11.6H0.7v-1.2h1.2V11.6z
            M1.9,9.2H0.7V8h1.2V9.2z"/>
        </g>
        <g>
          <path d="M21.3,18.9h-1.2v-1.2h1.2V18.9z M21.3,16.5h-1.2v-1.2h1.2V16.5z M21.3,14h-1.2v-1.2h1.2V14z M21.3,11.6h-1.2v-1.2h1.2
            V11.6z M21.3,9.2h-1.2V8h1.2V9.2z"/>
        </g>
      </g>
      <g>
        <rect x="3.7" y="3.1" width="2.4" height="1.2"/>
      </g>
      <g>
        <rect x="8.6" y="3.1" width="9.7" height="1.2"/>
      </g>
    </svg>
  </div>
));

