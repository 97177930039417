import { ExcalidrawJobElement } from "../../element/types";
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";
import {
  newCalendarBackgroundElement,
  newJobElement,
} from "src/excalidraw/extensions/element/newElement";
import { CANVAS_HEADER_HEIGHT, JOB_ELEMENTS_WIDTH, PRIORITY } from "src/excalidraw/constants";
import { ExcalidrawRectangleElement } from "src/excalidraw/element/types";

// CHANGED:ADD 2023-2-10 #634
export const generateJobBackgroundElements = (
  jobsHeight: number,
): ExcalidrawJobElement[] => {
  const offsetY = CANVAS_HEADER_HEIGHT;

  return [
    newJobElement({
      x: 0,
      y: offsetY,
      strokeColor: "transparent", // CHANGED:ADD 2023-03-01 #726
      backgroundColor: ColorsEx.backgroundColor.white,
      fillStyle: "solid",
      width: JOB_ELEMENTS_WIDTH,
      height: jobsHeight,
      locked: true,
      priority: PRIORITY.rectangle,
    }),
  ];
};

export const generateJobPanelElements = (
  jobElements: ExcalidrawJobElement[],
  calendarWidth: number,
): ExcalidrawRectangleElement[] => {
  const offsetX = JOB_ELEMENTS_WIDTH - 1;
  const jobPanelElements: ExcalidrawRectangleElement[] = [];

  // CHANGED:UPDATE 2023-2-10 #627
  jobElements
    ?.slice()
    .sort((a, b) => a.y - b.y)
    .forEach((element) => {
      jobPanelElements.push(
        newCalendarBackgroundElement({
          x: offsetX,
          y: element.y,
          strokeColor: ColorsEx.lineColor.border, // CHANGED:UPDATE 2023-03-01 #726
          backgroundColor: element.backgroundColor === ColorsEx.backgroundColor.white
            ? "transparent"
            : element.backgroundColor,
          fillStyle: element.backgroundColor === ColorsEx.backgroundColor.white // 塗りつぶし設定は固定
            ? "solid"
            : "hachure",
          strokeWidth: 1,
          width: calendarWidth,
          height: element.height,
          locked: true,
          priority: PRIORITY.rectangle, // CHANGED:ADD 2023-01-23 #391
        }),
      );
    });

  return jobPanelElements;
};

  // CHANGED:ADD 2023-03-01 #726
export const generateJobLineElements = (
  jobElements: ExcalidrawJobElement[],
  calendarWidth: number,
): ExcalidrawRectangleElement[] => {
  const offsetX = JOB_ELEMENTS_WIDTH;
  const jobLineElements: ExcalidrawRectangleElement[] = [];

  jobElements
    ?.slice()
    .sort((a, b) => a.y - b.y)
    .forEach((element) => {
      jobLineElements.push(
        newCalendarBackgroundElement({
          x: offsetX,
          y: element.y + element.height - 0.25,
          strokeColor: element.strokeColor,
          strokeWidth: 0.5,
          width: calendarWidth,
          height: 0.5,
          locked: true,
          priority: PRIORITY.rectangle, // CHANGED:ADD 2023-01-23 #391
        }),
      );
    });

  return jobLineElements;
};
