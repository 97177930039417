import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
//mobx

//styles
import "./Sidebar.scss";

//constants
import { Paths } from "src/conpath/constants/Routes";
import Colors from "src/conpath/constants/Colors";

//utils
import { formatDate } from "src/utils/dateUtils";

//components
import { CloseIcon, CompleteIcon, IncompleteIcon } from "src/conpath/components/icons";
import TagCloud from "src/conpath/components/sidebar/TagCloud";
import ChecklistView from "src/conpath/components/sidebar/ChecklistView";
import SelectAssignUsers from "src/conpath/components/sidebar/SelectAssignUsers";
import HoverToolTip from "../HoverToolTip";
import { TbExternalLink } from "react-icons/tb";
import CommentList from "./CommentList";
//models
import TaskModel from "src/conpath/models/TaskModel";
import ProjectModel from "src/conpath/models/ProjectModel";
import CommentModel from "src/conpath/models/CommentModel";
import OrganizationModel from "src/conpath/models/OrganizationModel";
import SelectAssignResources from "./SelectAssignResources";
import LoginUserModel from "src/conpath/models/LoginUserModel";
import { RotatingLines } from "react-loader-spinner";


interface Props {
  task: TaskModel;
  loginUser: LoginUserModel | null;
  organization: OrganizationModel | null;
  onCloseSidebar: () => Promise<void>;
  isResources: boolean;
}

const Sidebar = React.memo((props: Props) => {
  const { task, loginUser, organization, onCloseSidebar } = props;
  const [isTitleHover, setIsTitleHover] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  const [project, setProject] = useState<ProjectModel|null>(null);
  const [thread, setThread] = useState<CommentModel|null>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errorText) {
      toast.error(errorText);
      setErrorText("");
    }
  }, [errorText]);

  useEffect(() => {
    const getComments = async () => {
      if (organization) {
        const selectedProject = organization.
        projects.find((project) => project.id === task.projectId);
        // コメントの取得
        await selectedProject?.getCommentsByElementId(task.id, organization.users);
        /**
         * CanvasはExcalidrawCommentElementを取得できるのでrootCommentIdを参照できるが、Roadmap、Resourceはcanvasのエレメントを参照できないので、rootCommentIdを個別で取得する必要がある。
         * getCommentsByElementIdはisDeleted=falseでフィルタリングがかかっていて消去済みのThreadは取得できないので個別でThread取得関数を用意する。
         */
        const thread = await selectedProject?.getThreadByCommentableElementId(task.id, organization.users);
        // threadがない時はタスクにコメントがついていない時なのでnullでそのまま処理
        if (thread) setThread(thread);
        setProject(selectedProject!);
      }
    }
    
    getComments();

    return () => {
      if (project) {
        project.clearComments();
      }
      setProject(null);
      setThread(null);
    }
  }, [task]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onCloseSidebar();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
  
  const titleMouseEnter = () => {
    setIsTitleHover(true);
  } 
  const titleMouseLeave = () => {
    setIsTitleHover(false);
  } 

  const navigateToProject = () => {
    // プロジェクトを新規タブで開く
    window.open(`${Paths.projects}/${task.projectId}/#task=${task.id}`, "_blank");
  };

  return (
    <div
      ref={ref}
      className="sidebar">
        <button
          onClick={onCloseSidebar}
          className="sidebar__close-icon">
          <CloseIcon />
        </button>
        { loginUser && organization && project ?
          (
            <div className="inner-wrapper">
              <div className="sidebar__title-wrapper">
                <div className={task?.isClosed ? "badge" : "badge uncompleted"}>
                  {task?.isClosed ? <CompleteIcon /> : <IncompleteIcon />}
                  <p>
                    {task?.isClosed ? "完了済" : "未完了"}
                  </p>
                </div>
                <a
                  className="task-title hover:text-primary-color"
                  onClick={navigateToProject}
                >
                <span dangerouslySetInnerHTML={{ __html: task?.text.replace(/\n/g, '<br />') }} />
                  <TbExternalLink 
                    onMouseEnter={titleMouseEnter}
                    onMouseLeave={titleMouseLeave}
                  />
                  <HoverToolTip 
                    isShow={isTitleHover}
                    value="プロジェクトを新規タブで開く"
                  />
                </a>
                <div>
                  <p className="sidebar__project-title">
                    {organization.projects.find((p) => p.id === task?.projectId)?.name || ""}
                  </p>
                  <p>
    
                  </p>
                </div>
              </div>
              <div className="v-stack mt-[12px]">
                <SelectAssignUsers
                  loginUser={loginUser}
                  taskModel={task}
                  organization={organization}
                  onChangeAssignUsers={() => props.isResources && organization.forceReloadResourcesData()}
                />
              </div>
              <div className="v-stack mt-[12px]">
                <SelectAssignResources
                  taskModel={task}
                  organization={organization}
                  onChangeAssignResources={() => props.isResources && organization?.forceReloadResourcesData()}
                />
              </div>
              <div className="v-stack mt-[12px]">
                <label className="sidebar__label">
                  タグ
                </label>
                <TagCloud
                  task={task}
                />
              </div>
              <div className="h-stack mt-[12px]">
                <div className="v-stack mr-[12px]">
                  <label className="sidebar__label">
                    作業期間
                  </label>
                  <p
                    className="sidebar__date-picker-input readonly"
                  >
                    {formatDate(task.startDate)} ~ {formatDate(task
                      .endDate)}
                  </p>
                  {/* <DatePicker
                          range
                          inputClass="sidebar__date-picker-input"
                          value={[
                            new DateObject(task.startDate), 
                            new DateObject(task.endDate)
                          ]}
                          /> */}
                </div>
                <div className="v-stack">
                  <label className="sidebar__label">
                    作業日数
                  </label>
                  <p className="sidebar__work-duration readonly">
                    {task.duration}
                  </p>
                  {/* <input 
                          value={task.duration}
                          type="number"
                          className="sidebar__work-duration"
                          onWheel={() => {}}
                          /> */}
                </div>
              </div>
              <div className="v-stack mt-[12px]">
                <label className="sidebar__label">
                  チェックリスト
                </label>
                <ChecklistView
                  task={task}
                />
              </div>
              <div className="v-stack mt-[12px]">
                <label className="sidebar__label">
                  スレッド
                </label>
                <div className="comment-list-wrapper">
                  <CommentList
                    isTaskClosed={task?.isClosed}
                    commentElementId={thread?.id}
                    commentableElementId={task.id}
                    project={project}
                    loginUser={loginUser}
                    selectedOrganization={organization}
                    setErrorText={(error) => {
                      setErrorText(error);
                    }}
                  />
                </div>
              </div>
            </div>
          ) :
          (
            <div className="spinner-wrapper">
              <RotatingLines
                strokeColor={Colors.primary}
                strokeWidth="5"
                animationDuration="0.75"
                width="42"
                visible={true}
              />
            </div>
          )
        }
    </div>
  );
}, (prev, next) => {
  if (prev.task.id === next.task.id) return true;
  return false;
});

export default Sidebar;
