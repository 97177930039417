import {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";

import "./CustomSidebar.scss";
import { EVENT } from "../../constants";
import { KEYS } from "../../keys";
import { trackEvent } from "../../analytics";
import {
  useDevice,
  useExcalidrawActionManager,
  useExcalidrawAppState,
  useExcalidrawElements,
  useExcalidrawSetAppState,
} from "../../components/App";
import { useOnClickOutside } from "src/excalidraw/extensions/hooks/useOutsideClick";
import { SidebarEx } from "./SidebarEx/SidebarEx";
import {
  ExcalidrawTextElement,
  NonDeletedElementsMap,
  NonDeletedSceneElementsMap,
} from "src/excalidraw/element/types";
import { ActionManager } from "src/excalidraw/actions/manager";
import {
  canChangeRoundness,
  canHaveArrowheads,
  getSelectedElements,
  getTargetElements,
  hasBackground,
  hasStrokeWidth,
  hasText,
} from "src/excalidraw/scene";
import { getNonDeletedElements } from "src/excalidraw/element";
import { hasBoundTextElement, isTextElement } from "src/excalidraw/element/typeChecks";
import {
  hasBoundTextElementEx,
  isGraphElement,
  isJobElement,
  isLinkElement,
  isNodeElement,
  isTaskElement,
} from "../element/typeChecks";
import { arrayToMap, isTransparent, toBrandedType } from "src/excalidraw/utils";
import { TaskProperties } from "./TaskProperties";
import { shouldAllowVerticalAlign, suppportsHorizontalAlign } from "src/excalidraw/element/textElement";
import { t } from "src/excalidraw/i18n";
import {
  canHaveArrowheadsEx,
  hasStrokeColor,
  hasStrokeStyle,
  hasStrokeWidthEx,
} from "src/excalidraw/scene/comparisons";
import { AppState } from "src/excalidraw/types";

const CustomSidebarWrapper = forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div ref={ref} className="layer-ui__custom-sidebar">
      {children}
    </div>
  );
});

export const CustomSidebarContent = ({
  appState,
  elementsMap,
  renderAction,
}: {
  appState: AppState;
  elementsMap: NonDeletedElementsMap | NonDeletedSceneElementsMap;
  renderAction: ActionManager["renderAction"];
}) => {
  const targetElements = getTargetElements(elementsMap, appState);

  const nonIncludeBoundTextElements = getSelectedElements(
    getNonDeletedElements(targetElements), appState, {
    includeBoundTextElement: false,
  });

  let isSingleElementBoundContainer = false;
  if (
    targetElements.length === 2 &&
    (hasBoundTextElement(targetElements[0]) ||
      hasBoundTextElement(targetElements[1]) ||
      hasBoundTextElementEx(targetElements[0]) || // CHANGED:ADD 2023-1-28 #540
      hasBoundTextElementEx(targetElements[1])) // CHANGED:ADD 2023-1-28 #540
  ) {
    isSingleElementBoundContainer = true;
  }
  const isEditing = Boolean(appState.editingElement);
  const device = useDevice();
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  //CHHANGED:ADD 2022-12-01 #223
  let hasGraphElement = targetElements.some((element) =>
    isGraphElement(element),
  );

  //CHHANGED:ADD 2023-01-03 #570 jobElement選択時のpanel内ボタンの取捨選択のため
  const hasJobElement = targetElements.some((element) => isJobElement(element));

  //CHANGED:ADD 2022/12/08 #225
  if (
    targetElements.length === 1 &&
    isEditing &&
    isTextElement(targetElements[0]) &&
    !!targetElements[0].containerId
  ) {
    hasGraphElement = isNodeElement(elementsMap.get(targetElements[0].containerId));
  }

  const showFillIcons =
    (hasBackground(appState.activeTool.type) ||
      targetElements.some(
        (element) =>
          hasBackground(element.type) &&
          !isTransparent(element.backgroundColor),
      )) &&
    !hasGraphElement; //CHHANGED:ADD 2022-12-01 #223

  const showChangeBackgroundIcons =
    (hasBackground(appState.activeTool.type) ||
      targetElements.some((element) => hasBackground(element.type))) &&
    !hasGraphElement; //CHHANGED:ADD 2022-12-01 #223

  // CHANGED:UPDATE 2022-10-20 #12
  // const showLinkIcon =
  //   targetElements.length === 1 || isSingleElementBoundContainer;

  let commonSelectedType: string | null = targetElements[0]?.type || null;

  for (const element of targetElements) {
    if (element.type !== commonSelectedType) {
      commonSelectedType = null;
      break;
    }
  }

  const hasTaskElement = targetElements.some((element) =>
    isTaskElement(element),
  );
  // CHANGED:ADD 2023/09/06 #1016
  let isSingleTask = false;
  if (hasTaskElement && targetElements.length === 1) {
    isSingleTask = true;
  }

  return (
    <CustomSidebarWrapper>
      <div className="panelColumn">
        {/* タスク情報 */}
        {/* CHANGED:ADD 2023-1-26 #506 */}
        {hasTaskElement && !isEditing && targetElements.length > 0 && (
          <div className="panelColumn__island">
            <fieldset>
              <TaskProperties
                targetElements={targetElements}
                elementsMap={elementsMap}
              />
            </fieldset>
          </div>
        )}

        {/*CHHANGED:ADD 2022-12-01 #223 */}
        {/*CHHANGED:ADD 2023-02-03 #570 jobElementにレイヤーのボタンは不必要なので、!hasJobElement追加 */}
        {/* 操作 */}
        {!isEditing && targetElements.length > 0 && (
          <div className="panelColumn__island">
            <fieldset>
              <label>{t("labels.actions")}</label>
              <div className="buttonList--alone between">
                {/* CHANGED:ADD 2023-1-26 #506 > CHANGED:UPDATE 2023/09/06 #1016 */}
                {hasTaskElement && renderAction("toggleEditTask")}
                <div className="buttonList--alone__between-wrapper">
                  {hasTaskElement &&
                    (isSingleElementBoundContainer || isSingleTask) &&
                    renderAction("alignLeftTask")}
                  {/* CHANGED:ADD 2023-02-03 #570 jobElementにduplicateのボタンは不必要なので、!hasJobElement追加 */}
                  {!device.isMobile &&
                    !hasJobElement &&
                    renderAction("duplicateSelection")}
                  {/*CHHANGED:UPDATE 2023-2-7 #570 */}
                  {!device.isMobile &&
                    !hasJobElement &&
                    renderAction("toggleClose")}
                  {!device.isMobile && renderAction("deleteSelectedElements")}
                  {/* CHANGED:ADD 2024-02-07 #1591*/}
                  {!device.isMobile &&
                    !hasJobElement &&
                    renderAction("hyperlink")}
                  {/*CHHANGED:UPDATE 2023-2-7 #570 */}
                  {/* {renderAction("group")} */}
                  {/* {renderAction("ungroup")} */}
                  {!hasGraphElement && !hasJobElement && renderAction("group")}
                  {!hasGraphElement && !hasJobElement && renderAction("ungroup")}
                  {/* CHANGED:UPDATE 2022-10-20 #12 */}
                  {/* {showLinkIcon && renderAction("hyperlink")} */}
                </div>
              </div>
            </fieldset>
          </div>
        )}

        {/* レイヤー */}
        {!hasGraphElement && !hasJobElement && (
          <div className="panelColumn__island">
            <fieldset>
              <label>{t("labels.layers")}</label>
              <div className="buttonList--alone">
                {renderAction("sendToBack")}
                {renderAction("sendBackward")}
                {renderAction("bringToFront")}
                {renderAction("bringForward")}
              </div>
            </fieldset>
          </div>
        )}

        {/* 線の色 */}
        <div className="panelColumn__island">
          <fieldset>
            {((hasStrokeColor(appState.activeTool.type) &&
              appState.activeTool.type !== "image" &&
              commonSelectedType !== "image") ||
              targetElements.some((element) => hasStrokeColor(element.type))) &&
              // CHANGED:REMOVE 2023-1-23 #455
              // (!hasGraphElement ||
              //   (hasGraphElement &&
              //     targetElements.length === 1 &&
              //     isTextElement(targetElements[0]))) &&  //CHANGED:UPDATE 2022-12-13 #306
              renderAction("changeStrokeColor")}
          </fieldset>
        </div>
        {/* 背景色 */}
        {showChangeBackgroundIcons && (
          <div className="panelColumn__island">
            <fieldset>{renderAction("changeBackgroundColor")}</fieldset>
          </div>
        )}

        {/* 透明度 */}
        {/* CHANGED:REMOVE 2024-02-16 #1666 */}
        {/* {!hasGraphElement && !hasJobElement && (
          <div className="panelColumn__island">
            {renderAction("changeOpacity")}
          </div>
        )} */}

        {/* アローヘッドデザイン */}
        {(canHaveArrowheads(appState.activeTool.type) ||
          (!hasGraphElement /*CHHANGED:ADD 2022-12-01 #223 */ &&
            targetElements.some((element) =>
              canHaveArrowheads(element.type),
            ))) && (
          <div className="panelColumn__island">
            {renderAction("changeArrowhead")}
          </div>
        )}

        {/* タスク先端デザイン */}
        {(canHaveArrowheadsEx(appState.activeTool.type) ||
          (nonIncludeBoundTextElements.some((element) =>
            canHaveArrowheadsEx(element.type),
          ))) && (
            <div className="panelColumn__island">
              {renderAction("changeArrowheadEx")}
            </div>
          )}

        <div className="panelColumn__island">
          {/* 背景色 塗りつぶし */}
          {showFillIcons && renderAction("changeFillStyle")}

          {/* {線の太さ} */}
          {(hasStrokeWidth(appState.activeTool.type) ||
            (targetElements.some((element) => hasStrokeWidth(element.type)) &&
              !targetElements.some((element) =>
                hasStrokeWidthEx(element.type),
              ))) &&
            //CHHANGED:REMOVE 2023-2-9 #601
            //!hasGraphElement && //CHHANGED:ADD 2022-12-01 #223
            renderAction("changeStrokeWidth")}

          {/* CHANGED:ADD 2023/2/9 #601 */}
          {(hasStrokeWidthEx(appState.activeTool.type) ||
            (nonIncludeBoundTextElements.some((element) =>
              hasStrokeWidthEx(element.type),
            ))) &&
            renderAction("changeStrokeWidthEx")}

          {/* ??? */}
          {(appState.activeTool.type === "freedraw" ||
            targetElements.some((element) => element.type === "freedraw")) &&
            !hasGraphElement && //CHHANGED:ADD 2022-12-01 #223
            renderAction("changeStrokeShape")}

          {/* 線の種類 */}
          {(hasStrokeStyle(appState.activeTool.type) ||
            targetElements.some((element) => hasStrokeStyle(element.type))) &&
            !hasGraphElement && ( //CHHANGED:ADD 2022-12-01 #223
              <>
                {renderAction("changeStrokeStyle")}
                {/* CHANGED:UPDATE 2022-10-20 #10 */}
                {/* {renderAction("changeSloppiness")} */}
              </>
            )}

          {/* 線の角 */}
          {(canChangeRoundness(appState.activeTool.type) ||
            (!hasGraphElement /*CHHANGED:ADD 2022-12-01 #223*/ &&
              targetElements.some((element) =>
                canChangeRoundness(element.type),
              ))) && <>{renderAction("changeRoundness")}</>}

          {/* フォントサイズ */}
          {(hasText(appState.activeTool.type) ||
            targetElements.some(
              (element) => isTextElement(element) && !element.isCompressed,
            )) && ( // CHAGED:UPDATE 2023-03-15 #740
            <>
              {renderAction("changeFontSize")}

              {/* CHANGED:UPDATE 2022-10-20 #33 */}
              {/* {renderAction("changeFontFamily")} */}

              {/* CHANGED:ADD 2024-03-08 #1746 */}
              {/* テキストの位置 */}
              {hasTaskElement &&
                // <>
                //   {renderAction("changeTextOffset")}
                // </>
                <fieldset>
                  <label>{t("labels.textPos")}</label>
                  {renderAction("changeTextHorizontalAlign")}
                  {renderAction("changeTextVerticalAlign")}
                </fieldset>
              }

              {/* テキストの配置 */}
              {suppportsHorizontalAlign(targetElements, elementsMap) && (
                <fieldset>
                  {renderAction("changeTextAlign")}
                  {shouldAllowVerticalAlign(targetElements, elementsMap) &&
                    renderAction("changeVerticalAlign")}
                </fieldset>
              )}

              {hasTaskElement &&
                <>
                  {/* CHANGED:ADD 2024/02/01 #1510 */}
                  {/* テキストの方向 */}
                  {renderAction("changeTextDirection")}

                  {/* CHANGED:ADD 2024/03/11 #1790 */}
                  {/* テキストの枠線 */}
                  {renderAction("changeTextBorderNone")}

                  {/* CHANGED:ADD 2024/03/10 #1779 */}
                  {/* テキストの背景の透明度 */}
                  {renderAction("changeTextBorderOpacity")}
                </>
              }
            </>
          )}

          {/* CHANGED:ADD 2024-03-11 #1749 */}
          {/* 移動方向切り替え */}
          {isSingleElementBoundContainer &&
            targetElements.some((el) => isLinkElement(el)) && (
              renderAction("changeLinkPointerDirection")
            )}

          {/*CHHANGED:UPDATE 2023-2-7 #570 */}
          {/* {renderAction("changeOpacity")} */}

          {/*CHHANGED:UPDATE 2023-2-7 #570 */}
          {/* 配置 複数選択時に */}
          {!hasGraphElement &&
            !hasJobElement &&
            targetElements.length > 1 &&
            !isSingleElementBoundContainer && (
              <fieldset>
                <label>{t("labels.align")}</label>
                <div className="buttonList">
                  {
                    // swap this order for RTL so the button positions always match their action
                    // (i.e. the leftmost button aligns left)
                  }
                  {isRTL ? (
                    <>
                      {renderAction("alignRight")}
                      {renderAction("alignHorizontallyCentered")}
                      {renderAction("alignLeft")}
                    </>
                  ) : (
                    <>
                      {renderAction("alignLeft")}
                      {renderAction("alignHorizontallyCentered")}
                      {renderAction("alignRight")}
                    </>
                  )}
                  {targetElements.length > 2 &&
                    renderAction("distributeHorizontally")}
                  {/* breaks the row ˇˇ */}
                </div>
                <div className="buttonList">
                  {renderAction("alignTop")}
                  {renderAction("alignVerticallyCentered")}
                  {renderAction("alignBottom")}
                  {targetElements.length > 2 &&
                    renderAction("distributeVertically")}
                </div>
              </fieldset>
            )}
        </div>
      </div>
    </CustomSidebarWrapper>
  );
};

export const CustomSidebar: React.FC<{}> = () => {
  const appState = useExcalidrawAppState();
  const setAppState = useExcalidrawSetAppState();
  const elements = useExcalidrawElements();
  const device = useDevice();
  const actionManager = useExcalidrawActionManager();

  const ref = useRef<HTMLDivElement | null>(null);

  const closeCustomSidebar = useCallback(() => {
    const isDialogOpen = !!document.querySelector(".Dialog");

    // Prevent closing if any dialog is open
    if (isDialogOpen) {
      return;
    }
    setAppState({
      openSidebar: null,
      openPopup: appState.openPopup === "strokeColorPicker" || appState.openPopup === "backgroundColorPicker"
        ? null
        : appState.openPopup,
    });
  }, [appState, setAppState]);

  useOnClickOutside(
    ref,
    useCallback(
      (event) => {
        // If click on the library icon, do nothing so that CustomSidebarButton
        // can toggle library menu
        if ((event.target as Element).closest(".ToolIcon__custom-sidebar")) {
          return;
        }
        if (!appState.isSidebarDocked || !device.canDeviceFitSidebar) {
          closeCustomSidebar();
        }
      },
      [closeCustomSidebar, appState.isSidebarDocked, device.canDeviceFitSidebar],
    ),
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === KEYS.ESCAPE &&
        (!appState.isSidebarDocked || !device.canDeviceFitSidebar)
      ) {
        closeCustomSidebar();
      }
    };
    document.addEventListener(EVENT.KEYDOWN, handleKeyDown);
    return () => {
      document.removeEventListener(EVENT.KEYDOWN, handleKeyDown);
    };
  }, [closeCustomSidebar, appState.isSidebarDocked, device.canDeviceFitSidebar]);

  return (
    <SidebarEx
      __isInternal
      // necessary to remount when switching between internal
      // and custom (host app) sidebar, so that the `props.onClose`
      // is colled correctly
      key="custom-sidebar"
      className="layer-ui__custom-sidebar-sidebar"
      initialDockedState={appState.isSidebarDocked}
      onDock={(docked) => {
        trackEvent(
          "custom-sidebar",
          `toggleCustomSidebarDock (${docked ? "dock" : "undock"})`,
          `sidebar (${device.isMobile ? "mobile" : "desktop"})`,
        );
      }}
      ref={ref}
    >
      <SidebarEx.Header className="layer-ui__custom-sidebar-header"></SidebarEx.Header>
      <CustomSidebarContent
        appState={appState}
        elementsMap={toBrandedType<NonDeletedSceneElementsMap>(
          arrayToMap(elements),
        )}
        renderAction={actionManager.renderAction}
      />
    </SidebarEx>
  );
};
