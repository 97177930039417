import { action, computed, makeObservable, observable, runInAction } from "mobx";
import _ from "lodash";

//types and interface
import Task, { GanttChartTask, TaskResponse } from "src/conpath/interfaces/Task";  //CHANGED:UPDATe 2023-12-14 #1386
import Checklist, { ChecklistDocumentFields, ChecklistResponse } from "../interfaces/Checklist";
import { Tags } from "../interfaces/Tag";

//constants
import { DocumentUpdateState } from "src/conpath/constants/General";

//firebase
import { db } from "src/configs/firebase";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  runTransaction,
  writeBatch,
} from "firebase/firestore";
import { FirestoreCollections } from "../constants/FirestoreCollections";

//models
import ChecklistModel from "./ChecklistModel";
import { dateToFirebaseTime, firebaseTimeToDate } from "src/utils/timeUtils";
import InternalError from "../interfaces/InternalError";
import LoginUserModel from "./LoginUserModel";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

export default class TaskModel implements Task {

  @observable
  id!: string;
  @observable
  text!: string;
  @observable
  projectId!: string;
  @observable
  duration!: number;
  @observable
  startDate!: Date;
  @observable
  endDate!: Date;
  @observable
  prevDependencies!: readonly string[] | null;
  @observable
  nextDependencies!: readonly string[] | null;
  @observable
  freeFloats!:
    | readonly Readonly<{
      id: string;
      type: string;
      duration: number;
    }>[]
    | null;
  @observable
  memo!: string;
  @observable
  isClosed!: boolean;
  @observable
  isDeleted!: boolean;
  @observable
  created!: number;
  @observable
  createdBy!: string;
  @observable
  updated!: number;
  @observable
  updatedBy!: string;
  @observable
  taskCompletionRate!: number;
  @observable
  assignUserIds!: string[];
  @observable
  assignResourceIds!: string[];
  @observable
  tags!: Tags;

  @observable.deep
  checklists: ChecklistModel[] = [];

  @observable
  isChecklistLoading: boolean = false;

  organizationId: string = "";

  constructor(task: Task) {
    
    makeObservable(this);
    this.setFields(task);
  };

  //public

  public setOrganizationId(organizationId: string) {
    this.organizationId = organizationId;
  }

  public setState(updatingField: Partial<Task>) {
    this.setFields({ ...this.getFields(), ...updatingField });
  }

  @computed 
  get getDisplayEndDate() {
    const date = new Date(this.endDate);
    date.setDate(date.getDate() - 1);
    return date;
  }

  @computed
  get allChecked() {
    return this.checklists.length > 0 && this.checklists.every((item) => item.isChecked);
  }

  public getFields(): Task {
    return {
      id: this.id,
      text: this.text,
      projectId: this.projectId,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
      duration: this.duration,
      prevDependencies: this.prevDependencies,
      nextDependencies: this.nextDependencies,
      freeFloats: this.freeFloats,
      memo: this.memo,
      isClosed: this.isClosed,
      isDeleted: this.isDeleted,
      created: this.created,
      createdBy: this.createdBy,
      updated: this.updated,
      updatedBy: this.updatedBy,
      taskCompletionRate: this.taskCompletionRate,
      assignUserIds: this.assignUserIds,
      assignResourceIds: this.assignResourceIds,
      tags: this.tags,
    };
  }

  /**
   * taskCompletionRateはchecklist更新時にアップデートする
   * @returns Task
   */
  public getUpdateTaskFields(): Task {
    return {
      id: this.id,
      text: this.text,
      projectId: this.projectId,
      startDate: new Date(this.startDate),
      endDate: new Date(this.endDate),
      duration: this.duration,
      prevDependencies: this.prevDependencies,
      nextDependencies: this.nextDependencies,
      freeFloats: this.freeFloats,
      memo: this.memo,
      isClosed: this.isClosed,
      isDeleted: this.isDeleted,
      created: this.created,
      createdBy: this.createdBy,
      updated: this.updated,
      updatedBy: this.updatedBy,
    };
  }

  public getGanttChartTaskFields(): GanttChartTask {
    return {
      ...this.getFields(),
      isTask: true,
      ratio: this.taskCompletionRate
    };
  }

  @action
  public async save(): Promise<InternalError> {

    try {
      const taskDocumentRef = this.getTaskDocumentRef();

      if (!taskDocumentRef) {
        throw new Error("No task collection ref found.");
      }

      // Updating task document
      await runTransaction(db, async (transaction) => {
        transaction.set(taskDocumentRef, this.getUpdateTaskFields(), { merge: true });
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "タスクの更新に失敗しました。" };
    }
  }

  /**
   * タスクのチェックリスト取得関数
   * @returns 
   */
  @action
  public async getChecklists() {

    try {
      const checklistsCollectionRef = this.getChecklistCollectionRef();
      if (!checklistsCollectionRef) return;

      const checklistSnaps = await getDocs(
        query(checklistsCollectionRef, orderBy(ChecklistDocumentFields.createdAt, "asc"))
      );
      if (checklistSnaps.empty) return;

      const checklists: ChecklistModel[] = [];
      checklistSnaps.forEach((doc) => {
        const data = doc.data() as ChecklistResponse;

        const check = new ChecklistModel({
          ...data,
          createdAt: data.createdAt ? firebaseTimeToDate(data.createdAt) : null,
          checkedAt: data.checkedAt ? firebaseTimeToDate(data.checkedAt) : null,
        } as Checklist);
        check.setExistInFirestore();
        checklists.push(check);
      });

      runInAction(() => {
        this.checklists = checklists;
        this.isChecklistLoading = false;
      });

    } catch (err) {
      const error = err as { message?: string };
      console.log("[Error] Failed to get checklist: ", error.message);
      // Sentry here
    } 
  }

  @computed
  get getCheckedChecklist() {
    return this.checklists.filter((check) => check.isChecked);
  }

  @action
  public populateChecklist(loginUser: LoginUserModel, checkTexts: string[]) {
    const createdAt = dayjs().subtract(checkTexts.length, "seconds");
    const checks = checkTexts.map((check, index) => {
      const _checkModel = new ChecklistModel({
        id: nanoid(),
        title: check,
        url: "",
        isChecked: false,
        checkedBy: null,
        checkedAt: null,
        createdBy: loginUser.id,
        createdAt: createdAt.add(index, "seconds").toDate(),
      });
      _checkModel.changeDocumentState(DocumentUpdateState.SAVE);
      return _checkModel;
    });
    runInAction(() => {
      this.checklists = this.checklists.concat(checks);
    });
  }

  @action
  public createChecklist(checklist: Checklist) {
    const check = new ChecklistModel(checklist);
    check.changeDocumentState(DocumentUpdateState.SAVE);
    runInAction(() => {
      this.checklists = this.checklists.concat(check);
    });
  }

  @action
  public removeChecklist(checklist: Checklist) {
    const check = this.checklists.find((check) => check.id === checklist.id);
    if (!check) return;

    if (check.existsInFirestore) {
      // firestoreにあるチェックリスト
      check.changeDocumentState(DocumentUpdateState.DELETE);
    } else {
      // まだ保存していないチェックリスト
      this.checklists = this.checklists.filter((c) => c.id !== checklist.id);
    }
  }

  @action
  public async saveChecklist(): Promise<InternalError> {

    try {
      const checklistsCollectionRef = this.getChecklistCollectionRef();
      const taskDocumentRef = this.getTaskDocumentRef();

      if (!checklistsCollectionRef || !taskDocumentRef) {
        throw new Error("No checklist collection ref found.");
      }

      const checklists = this.checklists
                          .filter((check) => check.documentState !== DocumentUpdateState.NONE)
                          .map((check) => {
                            return check;
                          });

      if (!checklists.length) return {};

      const undeletedChecklists = this.checklists
      .filter((check) => check.documentState !== DocumentUpdateState.DELETE);

      const taskCompletionRate = Number((100 * undeletedChecklists.filter((task) => task.isChecked).length / undeletedChecklists.length).toFixed(1));

      const batch = writeBatch(db);
      // Updating checklists documents
      checklists.forEach((check) => {
        if (check.documentState == DocumentUpdateState.SAVE) {
          // update or register
          const c = check.getFields();
          if (c.title) { // チェックリストのタイトルなしは除外
            batch.set(doc(checklistsCollectionRef, c.id), {
              ...c,
              createdAt: c.createdAt ? dateToFirebaseTime(c.createdAt) : dateToFirebaseTime(),
              checkedAt: c.checkedAt ? dateToFirebaseTime(c.checkedAt) : null,
            });
          }
        } else {
          // delete
          batch.delete(doc(checklistsCollectionRef, check.id));
        }
      });

      // Updating task document
      const taskUpdatingField: Partial<TaskResponse> = {
        taskCompletionRate: taskCompletionRate
      };
      batch.update(taskDocumentRef, taskUpdatingField);

      await batch.commit();

      runInAction(() => {
        this.taskCompletionRate = taskCompletionRate;
      });
      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "チェックリストの追加・編集に失敗しました。" };
    }
  }

  public updateTaskCompletionRate() {
    if (!this.checklists.length) return;

    runInAction(() => {
      this.taskCompletionRate = 100 * this.checklists.filter((c) => c.isChecked).length / this.checklists.length;
    });
  }

  @action
  public addUser(user: string) {
    const users = this.assignUserIds;
    if (users.indexOf(user) === -1) users.push(user);

    runInAction(() => {
      this.assignUserIds = users;
    });
  }

  @action
  public removeUser(user: string) {
    const users = this.assignUserIds.filter((assignUser) => assignUser !== user);

    runInAction(() => {
      this.assignUserIds = users;
    });
  }

  @action
  public async setAssginUsers(assginUsers: string[]) {
    runInAction(() => {
      this.assignUserIds = assginUsers;
    });
  }

  @action
  public async saveAssignUsers(user: LoginUserModel): Promise<InternalError> {

    try {
      const taskDocumentRef = this.getTaskDocumentRef();

      if (!taskDocumentRef) {
        throw new Error("No task collection ref found.");
      }

      // Updating task document
      const taskUpdatingField: Partial<Task> = {
        text: this.text,
        startDate: this.startDate,
        endDate: this.endDate,
        assignUserIds: this.assignUserIds,
        updatedBy: user.id,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(taskDocumentRef, taskUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "AssignUserの更新に失敗しました。" };
    }
  }

  @action
  public addResource(resource: string) {
    const resources = this.assignResourceIds;
    if (resources.indexOf(resource) === -1) resources.push(resource);

    runInAction(() => {
      this.assignResourceIds = resources;
    });
  }

  @action
  public removeResource(resource: string) {
    const resources = this.assignResourceIds.filter((assignResource) => assignResource !== resource);

    runInAction(() => {
      this.assignResourceIds = resources;
    });
  }

  @action
  public async setAssginResources(assginResources: string[]) {
    runInAction(() => {
      this.assignResourceIds = assginResources;
    });
  }

  @action
  public async saveAssignResources(): Promise<InternalError> {

    try {
      const taskDocumentRef = this.getTaskDocumentRef();

      if (!taskDocumentRef) {
        throw new Error("No task collection ref found.");
      }

      // Updating task document
      const taskUpdatingField: Partial<TaskResponse> = {
        assignResourceIds: this.assignResourceIds,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(taskDocumentRef, taskUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "AssignResourceの更新に失敗しました。" };
    }
  }

  @action
  public addTag(tag: string) {
    const tags = {
      ...this.tags,
      [tag]: true,
    }

    runInAction(() => {
      this.tags = tags;
    });
  }

  @action
  public removeTag(tag: string) {
    const tags = this.tags;
    delete tags[tag];

    runInAction(() => {
      this.tags = tags;
    });
  }

  @action
  public async saveTags(): Promise<InternalError> {

    try {
      const taskDocumentRef = this.getTaskDocumentRef();

      if (!taskDocumentRef) {
        throw new Error("No task collection ref found.");
      }
 
      // Updating task document
      const taskUpdatingField: Partial<TaskResponse> = {
        tags: this.tags,
      };

      await runTransaction(db, async (transaction) => {
        transaction.update(taskDocumentRef, taskUpdatingField);
      });

      return {};
    } catch (err) {
      console.log(err);
      // sentry here

      return { error: "タグの追加・編集に失敗しました。" };
    }
  }

  @computed
  get emptyChecklistTitleExists(): boolean {
    return this.checklists.some((check) => _.isEmpty(check.title));
  }

  @computed
  get isUpdatedChecklists(): boolean {
    return this.checklists.some((check) => check.documentState !== DocumentUpdateState.NONE);
  }
  // private

  /**
   * /organizations/{organizationId}/tasks/{taskId}/
   * @returns task document ref
   */
  private getTaskDocumentRef(): DocumentReference<DocumentData> | null {
    if (!this.organizationId) return null;

    return doc(db,
      FirestoreCollections.organizations.this,
      this.organizationId,
      FirestoreCollections.organizations.tasks.this,
      this.id);
  }

  /**
   * /organizations/{organizationId}/tasks/{taskId}/checklists/
   * @returns checklist collection ref
   */
  private getChecklistCollectionRef(): CollectionReference<DocumentData> | null {
    const taskDocumentRef = this.getTaskDocumentRef();
    if (!taskDocumentRef) return null;

    return collection(taskDocumentRef, FirestoreCollections.organizations.tasks.checklists.this);
  }

  @action
  private setFields(task: Task) {
    this.id = task.id;
    this.text = task.text;
    this.projectId = task.projectId;
    this.duration = task.duration;
    this.startDate = task.startDate;
    this.endDate = task.endDate;
    this.prevDependencies = task.prevDependencies;
    this.nextDependencies = task.nextDependencies;
    this.freeFloats = task.freeFloats || [];
    this.memo = task.memo || "";
    this.isClosed = task.isClosed;
    this.isDeleted = task.isDeleted;
    this.created = task.created;
    this.createdBy = task.createdBy;
    this.updated = task.updated;
    this.updatedBy = task.updatedBy;
    this.taskCompletionRate = task.taskCompletionRate || 0;
    this.assignUserIds = task.assignUserIds || []; //CHANGED:ADD 2023-12-14 #1386
    this.assignResourceIds = task.assignResourceIds || [];
    this.tags = task.tags || {};

    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(0, 0, 0, 0);
  }
};

